import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import BuilderChat from "../../GrantWriter/Screens/Builder/Chat";

const ReviseButtonsCode = () => {
    
    const [openBtn, setOpenBtn] = useState(null);

    const [buttons, setButtons] = useState([]);

    const [isTesting, setIsTesting] = useState(false);

    const getReviseButtons = () => {
        axios.get(`${Helpers.apiUrl}admin/buttons/all`, Helpers.authHeaders).then(response => {
            setButtons(response.data.buttons)
        });
    }

    const viewBtn = btnId => {
        let btnToView = buttons.find(btn => btn.id == btnId);
        setOpenBtn(btnToView);
    }

    const approveBtn = () => {
        if(openBtn){
            axios.get(`${Helpers.apiUrl}admin/buttons/approve/${openBtn.id}`, Helpers.authHeaders).then(response => {
                Helpers.toast("success", response.data.message);
                setOpenBtn(null);
                getReviseButtons()
            });
        }
    }
    
    const rejectBtn = () => {
        if(openBtn){
            axios.get(`${Helpers.apiUrl}admin/buttons/reject/${openBtn.id}`, Helpers.authHeaders).then(response => {
                Helpers.toast("success", response.data.message);
                setOpenBtn(null);
                getReviseButtons()
            });
        }
    }

    const removeBtn = btnId => {
        axios.get(`${Helpers.apiUrl}documents/button/delete/${btnId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getReviseButtons();
        });
    }



    useEffect(() => {
        getReviseButtons()
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className={isTesting ? "col-md-6" : "col-md-12"} style={{ overflow: 'auto', scrollbarWidth: 'none', height: '100vh' }}>
                    <div className="card mycard" style={{ marginTop: 15, marginBottom: 15 }}>
                        {!openBtn && <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>All Revised Buttons</h3>
                                </div>
                            </div>
                            <table className="table mt-3">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Grant</th>
                                        <th>Section</th>
                                        <th>Institute</th>
                                        <th>Created By</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {buttons.map(btn => {
                                        return (
                                            <tr>
                                                <td>{ btn.id }</td>
                                                <td>{ btn.name }</td>
                                                <td>{ btn.grant.name }</td>
                                                <td>{ btn.prompt.name }</td>
                                                <td>{ btn.institute.name == "Undecided" ? "All Institutes" : btn.institute.name }</td>
                                                <td>{ btn.user.first_name } { btn.user.last_name }</td>
                                                <td>
                                                    {(btn.is_published && btn.publish_status == 1) && <span className="badge bg-warning">In Review</span>}
                                                    {(btn.publish_status == 3) && <span className="badge bg-danger">Rejected</span>}
                                                    {(btn.is_published && btn.publish_status == 2) && <span className="badge bg-success">Approved</span>}
                                                </td>
                                                <td>
                                                    <button onClick={() => viewBtn(btn.id)} className="btn btn-success btn-sm">View</button>
                                                    <button onClick={() => removeBtn(btn.id)} className="btn btn-danger btn-sm ml-5">Delete</button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>}
                        {openBtn && <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>{ openBtn.name }</h3>
                                    <button onClick={() => setIsTesting(true)} className="btn btn-primary btn-sm">Test Revise Button</button>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button onClick={() => {
                                        setOpenBtn(null);
                                        setIsTesting(false);
                                    }} className="btn btn-primary btn-sm">Back to All Buttons</button>
                                    {(openBtn.is_published && openBtn.publish_status == 1) && <button onClick={approveBtn} className="btn btn-success btn-sm ml-5">Approve</button>}
                                    {(openBtn.is_published && openBtn.publish_status == 1) && <button onClick={rejectBtn} className="btn btn-danger btn-sm ml-5">Reject</button>}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <table className="table table-bordered">
                                        <tr>
                                            <th>Grant</th>
                                            <td>{ openBtn.grant.name }</td>
                                        </tr>
                                        <tr>
                                            <th>Section of Grant</th>
                                            <td>{ openBtn.prompt.name }</td>
                                        </tr>
                                        <tr>
                                            <th>Institute(s)</th>
                                            <td>{ openBtn.institute.name == "Undecided" ? "All Institutes" : openBtn.institute.name }</td>
                                        </tr>
                                        <tr>
                                            <th>Created By</th>
                                            <td>{ openBtn.user.first_name } { openBtn.user.last_name }</td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>
                                                {(openBtn.is_published && openBtn.publish_status == 1) && <span className="badge bg-warning">In Review</span>}
                                                {(openBtn.is_published && openBtn.publish_status == 0) && <span className="badge bg-danger">Rejected</span>}
                                                {(openBtn.is_published && openBtn.publish_status == 2) && <span className="badge bg-success">Approved</span>}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-md-12 code-container">
                                    <p>{ Helpers.generateCode(openBtn, openBtn.instructions) }</p>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                {openBtn && <div className="col-md-6">
                    <BuilderChat btnId={openBtn.id} fellowship={openBtn.grant.name} />
                </div>}
            </div>
        </div>
    )
}

export default ReviseButtonsCode;