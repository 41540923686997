import FeatherIcon from "feather-icons-react";
import { Link, useNavigate } from "react-router-dom";
import Helpers from "../Config/Helper";

const SidebarLink = ({ text, isActive = false, link, icon, isLogout = false }) => {
    
    let navigate = useNavigate()
    const handleLogout = e => {
        e.preventDefault();
        localStorage.clear();
        Helpers.toast("success", "Logged out successfully");
        navigate('/');
    }

    return (
        !isLogout ? <li className={`tyn-aside-item tyn-aside-item-bubbly js-toggle-main ${isActive && 'active'}`} style={{ marginLeft: '0px' }}>
            <Link to={link} className="tyn-media-group">
                <div className="tyn-media tyn-size-sm icon-color">
                    <FeatherIcon icon={icon} />
                </div>
                <div className="tyn-media-col">
                    <div className="content">{ text }</div>
                </div>
            </Link>
        </li> : <li className={`tyn-aside-item tyn-aside-item-bubbly js-toggle-main ${isActive && 'active'}`} style={{ marginLeft: '0px' }}>
            <div onClick={handleLogout} className="tyn-media-group">
                <div className="tyn-media tyn-size-sm icon-color">
                    <FeatherIcon icon={'log-out'} />
                </div>
                <div className="tyn-media-col">
                    <div className="content">{ 'Logout' }</div>
                </div>
            </div>
        </li>
    );
}

export default SidebarLink;