import axios from "axios";
import { useState } from "react";
import Helpers from "../../Config/Helper";
import { useNavigate } from "react-router-dom";

const ReportIssue = () => {
    const navigate = useNavigate()
    const topics = [
        "Inacurate or bad response",
        "Software issue",
        "Need more features",
    ];

    const defaultReport = {
        topic: "",
        problem: "",
    };

    const [report, setReport] = useState(defaultReport);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const reportIssue = () => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}issues/report`, report, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setIsLoading(false);
            navigate("/user/dashboard");
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setIsLoading(false);
        })
    }

    return (
        <div className="container-fluid">
            <div className="row p-4">
                <div className="col-md-12">
                    <div className="card mycard">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <h3>Report an issue</h3>
                                    <small>We're sorry you're experienceing issues. Please let us know what went wrong so we can make it right. Your data will be kept confidential and will strictly be used for debugging purposes.</small>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12 mb-3">
                                    <label className="form-label">Choose Topic</label>
                                    <select className="form-control" value={report.topic} onChange={e => setReport({...report, topic:e.target.value})}>
                                        <option value={''}>Choose Topic</option>
                                        {topics.map((t, index) => <option key={index} value={t}>{t}</option>)}
                                    </select>
                                    <small className="text-danger">{ errors.topic ? errors.topic : '' }</small>
                                </div>
                                <div className="form-group col-md-12 mb-3">
                                    <label className="form-label">Problem Statement</label>
                                    <textarea className="form-control" value={report.problem} onChange={e => setReport({...report, problem: e.target.value})} placeholder="Write your problem"></textarea>
                                    <small className="text-danger">{ errors.problem ? errors.problem : '' }</small>
                                </div>
                                <div className="form-group col-md-12">
                                    <button onClick={reportIssue} className="btn btn-primary btn-sm" disabled={isLoading}>{isLoading ? "Reporting..." : "Report Problem"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportIssue;