import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import Moment from "react-moment";

const Issues = () => {

    const [issues, setIssues] = useState([]);

    const getIssues = () => {
        axios.get(`${Helpers.apiUrl}issues/all`, Helpers.authHeaders).then(response => {
            setIssues(response.data.issues);
        })
    }

    const resolveIssue = issueId => {
        axios.get(`${Helpers.apiUrl}issues/resolve/${issueId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getIssues();
        });
    }
    
    const removeIssue = issueId => {
        axios.get(`${Helpers.apiUrl}issues/remove/${issueId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getIssues();
        });
    }

    useEffect(() => {
        getIssues()
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card m-3 mycard">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>Issues</h3>
                                </div>
                            </div>
                            <table className="table mt-3">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Topic</th>
                                        <th>Problem</th>
                                        <th>Status</th>
                                        <th>Raised On</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {issues.map(issue => {
                                        return (
                                            <tr>
                                                <td>{ issue.id }</td>
                                                <td>{ `${issue.user.first_name} ${issue.user.last_name}` }</td>
                                                <td>{ issue.topic }</td>
                                                <td>{ issue.problem }</td>
                                                <td>
                                                    {issue.status == 0 && <span className="badge bg-warning">Pending</span>}
                                                    {issue.status == 1 && <span className="badge bg-success">Resolved</span>}
                                                </td>
                                                <td><Moment date={issue.created_at} format="ddd, MMM Do YYYY" /></td>
                                                <td>
                                                    {issue.status == 0 && <button onClick={() => resolveIssue(issue.id)} className="btn btn-primary btn-sm">Mark as Resolved</button>}
                                                    {issue.status == 1 && <button onClick={() => removeIssue(issue.id)} className="btn btn-danger btn-sm">Remove Issue</button>}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Issues;