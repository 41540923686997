import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import Helpers from '../Config/Helper';
import { Link, useNavigate } from 'react-router-dom';
import SidebarLink from './SidebarLink';
import Line from './Line';
import { useEffect, useState } from 'react';
import $ from 'jquery';

const Sidebar = ({ setChatId, chatId, chats, onChange, getChats, tokens, profile }) => {

    let navigate = useNavigate();

    const [grants, setGrants] = useState([]);
    const [institutes, setInstitutes] = useState([]);
    const [userAccountVisible, setUserAccountVisible] = useState(false);

    const getGrants = () => {
        axios.get(`${Helpers.apiUrl}categories/grants/published`, Helpers.authHeaders).then(response => {
            setGrants(response.data.grants);
        })
    }

    const getInstitutes = () => {
        axios.get(`${Helpers.apiUrl}categories/institutes`, Helpers.authHeaders).then(response => {
            setInstitutes(response.data.institutes);
        })
    }

    const handleNewChat = e => {
        e.preventDefault();
        onChange();
        navigate('/user/dashboard');
    }

    const handleOpenChat = chatId => {
        setChatId(chatId)
        navigate('/user/dashboard');
    }

    const handleDeleteChat = (chat_id, event) => {
        event.stopPropagation();
        axios.get(`${Helpers.apiUrl}bot/delete-chat/${chat_id}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message)
            if (chatId == chat_id){
                setChatId(0);
                navigate('/user/dashboard');
            }
            getChats();
        })
    }

    const showUserAccount = () => {
        if(userAccountVisible){
            $(".user-account-options").fadeOut();
        }else{
            $(".user-account-options").fadeIn();
        }
        setUserAccountVisible(!userAccountVisible);
    }

    useEffect(() => {
        getGrants();
        getInstitutes();
    }, []);

    return (
        <div className="tyn-aside tyn-aside-base">
            <div className="tyn-aside-head" style={{ paddingBottom: 0 }}>
                <div className="tyn-aside-head-text">
                    <h3 className="tyn-aside-title tyn-title">All Chats</h3>
                    <span className="tyn-subtext">{ chats.length }  Conversations</span>
                </div>
                <div className="tyn-aside-head-tools">
                    <ul className="tyn-list-inline gap gap-3">
                        <li>
                            <Link className="btn btn-icon btn-light btn-md btn-pill" style={{ width: 115 }} onClick={handleNewChat}>    
                                <FeatherIcon icon="plus" /> Start New
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            {<div className="tyn-aside-head" style={{ paddingTop: 0 }}>
                <div className="tyn-aside-head-text">
                    <span className="tyn-subtext f-10"><strong>Grant:</strong> { (profile.grant > 0 && grants.length > 0) && grants.find(grant => grant.id == profile.grant).name.split('—')[0] }</span>
                    <br/>
                    <span className="tyn-subtext f-10"><strong>Institute:</strong> { (profile.institute > 0 && institutes.length > 0) && institutes.find(inst => inst.id == profile.institute).name.split('—')[0] }</span>
                    <br/>
                    <span className="tyn-subtext f-10"><strong>Applying from:</strong> { profile.applying_uni }</span>
                </div>
                <div className="tyn-aside-head-tools">
                    <ul className="tyn-list-inline gap gap-3">
                        <li>
                            <Link className="btn btn-icon btn-light btn-md btn-pill" to={'/user/institute-setup'}>    
                                <FeatherIcon icon="edit" />
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>}
            <div className="tyn-aside-body" data-simplebar>
                <ul className="tyn-aside-list">
                    {
                        chats.map((chat) => {
                            return (
                                <li key={chat.id} onClick={() => handleOpenChat(chat.id)} className={`tyn-aside-item tyn-aside-item-bubbly js-toggle-main ${chatId === chat.id && 'active'}`}>
                                    <div className="tyn-media-group">
                                        <div className="tyn-media tyn-size-sm">
                                            <FeatherIcon icon="message-square" />
                                        </div>
                                        <div className="tyn-media-col">
                                            <div className="content">{ chat.title }</div>
                                        </div>
                                        <div className="tyn-media-option">
                                            <ul className="tyn-media-option-list">
                                                <li>
                                                    <button onClick={(event) => handleDeleteChat(chat.id, event)} className="btn btn-icon btn-md btn-pill btn-light">
                                                        <FeatherIcon icon="trash-2" />
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="tyn-aside-foot">
                <div className="w-100">
                    {/* <Link to={'/user/questionnaire'} className='btn btn-outline-primary w-100'>Complete Questionnaire</Link> */}
                    <Link to={'/user/report-issue'} className='btn btn-outline-dark btn-sm w-100'><FeatherIcon icon={'alert-circle'} /> Report an issue</Link>
                    <div className='user-account-options' style={{ display: 'none' }}>
                        <Line />
                        <div className='row'>
                            <h6>User Account</h6>
                        </div>
                        <ul className="row gx-3">
                            <SidebarLink icon={'credit-card'} link={'/user/upgrade'} text={'Upgrade Account'} />
                            <SidebarLink icon={'settings'} link={'/user/settings'} text={'Profile & Settings'} />
                            <SidebarLink icon={'book'} link={'/user/workbook'} text={'Workbook'} />
                            {/* <SidebarLink icon={'edit'} link={'/user/editor'} text={'Editor'} /> */}
                            <SidebarLink icon={'calendar'} link={'/user/tasks'} text={'Tasks'} />
                            <SidebarLink isLogout={true} />
                        </ul>
                    </div>
                    <div className='row mt-3' style={{ alignItems: 'center' }}>
                        <div className='col-10'>
                            <h6>{ `${Helpers.authUser.fields.first_name} ${Helpers.authUser.fields.last_name}` } ({ tokens } Tokens Left)</h6>
                            <span className='small'>{ Helpers.authUser.fields.email }</span>
                        </div>
                        <div className='col-2'>
                            <button className="tyn-media tyn-size-sm" onClick={showUserAccount} style={{border:'none'}}>
                                <FeatherIcon icon="more-vertical" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;