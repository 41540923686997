import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'


class Helpers {

    static apiUrl = `//${process.env.REACT_APP_DEPLOYMENT}/api/`;
    static baseUrl = `//${process.env.REACT_APP_DEPLOYMENT}`;

    static authUser = JSON.parse(localStorage.getItem('user'));

    static authHeaders = {
        headers: {
            "Content-Type": 'application/json',
            "Authorization" : `Bearer ${localStorage.getItem('access_token')}`
        }
    }
    
    static authFileHeaders = {
        headers: {
            "Content-Type": 'multipart/form-data',
            "Authorization" : `Bearer ${localStorage.getItem('access_token')}`
        }
    }

    static getItem = (data, isJson = false) => {
      if(isJson){
        return JSON.parse(localStorage.getItem(data));
      }else{
        return localStorage.getItem(data);
      }
    }

    static setItem = (key, data, isJson = false) => {
      if(isJson){
        localStorage.setItem(key, JSON.stringify(data));
      }else{
        localStorage.setItem(key, data);
      }
    }

    static toast = (type, message) => {
        const notyf = new Notyf();
        notyf.open({
            message: message,
            type:type,
            position:{x:'right', y:'top'},
            ripple:true,
            duration:2000,
        });
    }

    static genRand = (len) => {
        return Math.random().toString(36).substring(2,len+2);
    }

    static checkPasswordStrength = password => {
        // Create an object to store the criteria that the password needs to meet
        const criteria = {
          length: false,
          uppercase: false,
          lowercase: false,
          number: false,
          specialChar: false,
        };

        let passed = [];
      
        // Check if the password meets each criteria
        if (password.length >= 8) {
          criteria.length = true;
          passed.push("length");
        }
      
        if (/[A-Z]/.test(password)) {
          criteria.uppercase = true;
          passed.push("uppercase");
        }
      
        if (/[a-z]/.test(password)) {
          criteria.lowercase = true;
          passed.push("lowercase");
        }
      
        if (/\d/.test(password)) {
          criteria.number = true;
          passed.push("number");
        }
      
        if (/[$@!%*?&]/.test(password)) {
          criteria.specialChar = true;
          passed.push("special");
        }
      
        // Calculate the number of criteria met
        const numCriteriaMet = Object.values(criteria).filter(Boolean).length;
      
        // Return the strength of the password based on the number of criteria met
        switch (numCriteriaMet) {
          case 1:
            return {text:'Weak', color: '#a71503', passed:passed, strength: 1};
          case 2:
            return {text:'Fair', color: '#fe860f', passed:passed, strength: 2};
          case 3:
            return {text:'Good', color: '#fcd332', passed:passed, strength: 3};
          case 4:
            return {text:'Strong', color: '#1de70b', passed:passed, strength: 4};
          case 5:
            return {text:'Extremely strong', color: '#106e03', passed:passed, strength: 5};
          default:
            return {text:'Enter a strong password', color: 'red', passed:passed, strength: 0};
        }
      }

      static fellowshipOptions = [
        // "F30 — Ruth L. Kirschstein Individual Predoctoral NRSA for MD/​PhD and other Dual Degree Fellowships",
        // "F31 — Ruth L. Kirschstein Predoctoral Individual National Research Service Award",
        // "F32 — Ruth L. Kirschstein Postdoctoral Individual National Research Service Award",
        // "K01 — Mentored Research Scientist Career Development Award",
        // "K08 — Mentored Clinical Scientist Research Career Development Award",
        // "K23 — Mentored Patient-Oriented Research Career Development Award",
        "K99/R00 — Pathway to Independence Award"
        // "R01 — NIH Research Project Grant",
      ];

      static promptsOptions = {
        "K99/R00 — Pathway to Independence Award": [
          "Specific Aims",
          "Significance",
          "Innovation",
          "Research Strategy",
          "Candidate Background",
          "Career Goals",
          "Career Development and Training",
          "Project Summary",
          "Project Narrative",
          "Title",
          "Training in the Responsible Conduct of Research",
          "Introduction to Application (for Resubmissions only)",
          "Vertebrate Animals",
          "Select Agents",
          "Consortium/Contractual Arrangements",
          "Resource Sharing Plan",
          "Data Sharing and Management Plan",
          "Authentication of key biological and/or chemical resources",
          "Let me type (allow the grant writer to add their own name here)"
        ]
      };

      static NIHInstitutes = [
        "Undecided",
        "NCI — National Cancer Institute",
        "NEI — National Eye Institute",
        "NHLBI — National Heart, Lung, and Blood Institute",
        "NHGRI — National Human Genome Research Institute",
        "NIA — National Institute on Aging",
        "NIAAA — National Institute on Alcohol Abuse and Alcoholism",
        "NIAID — National Institute of Allergy and Infectious Diseases",
        "NIAMS — National Institute of Arthritis and Musculoskeletal and Skin Diseases",
        "NIBIB — National Institute of Biomedical Imaging and Bioengineering",
        "NICHD — Eunice Kennedy Shriver National Institute of Child Health and Human Development",
        "NIDCD — National Institute on Deafness and Other Communication Disorders",
        "NIDCR — National Institute of Dental and Craniofacial Research",
        "NIDDK — National Institute of Diabetes and Digestive and Kidney Diseases",
        "NIDA — National Institute on Drug Abuse",
        "NIEHS — National Institute of Environmental Health Sciences",
        "NIGMS — National Institute of General Medical Sciences",
        "NIMH — National Institute of Mental Health",
        "NIMHD — National Institute on Minority Health and Health Disparities",
        "NINDS — National Institute of Neurological Disorders and Stroke",
        "NINR — National Institute of Nursing Research",
        "NLM — National Library of Medicine",
        "CC — NIH Clinical Center",
        "CIT — Center for Information Technology",
        "CSR — Center for Scientific Review",
        "FIC — Fogarty International Center",
        "NCATS — National Center for Advancing Translational Sciences",
        "NCCIH — National Center for Complementary and Integrative Health",
      ]

      static sectionNames = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Eighth", "Ninth", "Tenth"];

      static generateCode = (revisedButton, revisedSections) => {
        return (
          <pre>
            { revisedButton.intro }
            <br/>
            { (revisedButton.guidelines || revisedSections.length > 0) && <>
              {`<Section ${ revisedButton.name }>`}
                <br/>
                {revisedSections.map((section, index) => {
                    return (
                        <>
                            &emsp;{`<${this.sectionNames[index]}>`}
                            <br/>
                            &emsp;{ section.intro }
                            <br/>
                            {section.guidelines && <>
                                &emsp;&emsp;{`<Guidelines for ${this.sectionNames[index]}>`}
                                <br/>
                                &emsp;&emsp;{section.guidelines}
                                <br/>
                                &emsp;&emsp;{`</Guidelines for ${this.sectionNames[index]}>`}
                                <br/>
                            </>}
                            &emsp;{`</${this.sectionNames[index]}>`}
                            <br/>
                        </>
                    )
                })}
                <br/>

                {revisedButton.guidelines && <>&emsp;{`<General guidance for section ${ revisedButton.name }>`}
                <br/>
                &emsp;{ revisedButton.guidelines }
                <br/>
                &emsp;{`</General guidance for section ${ revisedButton.name }>`}
                <br/></>}
                {`</Section ${ revisedButton.name }>`}
            </> }
            <br/>
            {`${revisedButton.advanced}`}
          </pre>
        )
      }

      static getCodeAsText = (revisedButton, revisedSections) => {
        let code = `${revisedButton.intro}`;
        if (revisedButton.guidelines || revisedSections.length > 0){
          code += `<Section ${ revisedButton.name }>`;
          revisedSections.forEach((section, index) => {
            code += `<${this.sectionNames[index]}>`;
            code += `${section.intro}`;
            if(section.guidelines){
              code += `<Guidelines for ${this.sectionNames[index]}>`;
              code += `${section.guidelines}`;
              code += `</Guidelines for ${this.sectionNames[index]}>`;
            }
            code += `</${this.sectionNames[index]}>`;
          });
          if(revisedButton.guidelines){
            code += `<General guidance for section ${ revisedButton.name }>`;
            code += `${revisedButton.guidelines}`;
            code += `</General guidance for section ${ revisedButton.name }>`;
          }
          code += `</Section ${ revisedButton.name }>`;
        }
        code += `${ revisedButton.advanced }`;
        return code;
      };

      static stripHtmlTags = (htmlString) => {
          return htmlString.replace(/<[^>]+>/g, '');
      };

      static workbook_message = (workbooks_sections) => {
        let msg = ``;
        Object.values(workbooks_sections).forEach(section => {
            msg += `Section Name: ${section.name}`;
            msg += `\n`;
            section.questions.forEach(question => {
                msg += `Question: ${question.question}`;
                msg += `\n`;
                if(question.answer){
                  msg += `Answer: ${question.answer}`;
                  msg += `\n`;
                }else{
                  msg += `Answer: `;
                  msg += `\n`;
                }
            });
            msg += `\n`;
        })

        return msg;
      }
      
      static workbook_code = (workbooks_sections) => {
        let msg = ``;
        Object.values(workbooks_sections).forEach(section => {
            msg += `<Section>`;
            msg += `\n`;
            msg += `<Section Name>${section.name}</Section Name>`;
            msg += `\n`;
            section.questions.forEach(question => {
                msg += `<Question>${question.question}</Question>`;
                msg += `\n`;
                if(question.answer){
                  msg += `<Answer>${question.answer}</Answer>`;
                  msg += `\n`;
                }else{
                  msg += `<Answer></Answer>`;
                  msg += `\n`;
                }
            });
            msg += `</Section>`;
            msg += `\n`;
            msg += `\n`;
        })

        return msg;
      }

      static findAnswer = (questionId, sections) => {
        let foundQuestion = null;
        for (const sectionId in sections) {
          if (sections.hasOwnProperty(sectionId)) {
              const section = sections[sectionId];
              
              // Iterate through each question in the current section
              for (const question of section.questions) {
                  if (question.id === questionId) {
                      foundQuestion = question;
                      break; // Exit the loop since the question is found
                  }
              }
              
              if (foundQuestion) {
                  break; // Exit the outer loop as well
              }
          }
        }

        if(foundQuestion){
          return foundQuestion.answer;
        }else{
          return "";
        }
      }

      static getParam = key => {
        const query = new URLSearchParams(window.location.search);
        const value = query.get(key)

        return value
      }

      static priority = value => {
        let text = "";
        if (value == 0){
          text = "Low";
        }else if (value == 1){
          text = "Medium";
        }else if (value == 2){
          text = "High";
        }else if (value == 3){
          text = "Critical";
        }

        return text;
      }
      
      static taskStatus = value => {
        let text = "";
        if (value == 0){
          text = "Pending";
        }else if (value == 1){
          text = "In Progress";
        }else if (value == 2){
          text = "Completed";
        }

        return text;
      }
      
      static priorityColor = value => {
        let color = "";
        if (value == 0){
          color = "low";
        }else if (value == 1){
          color = "medium";
        }else if (value == 2){
          color = "high";
        }else if (value == 3){
          color = "critical";
        }

        return color;
      }
      
      static statusColor = value => {
        let color = "";
        if (value == 0){
          color = "pending";
        }else if (value == 1){
          color = "in-progress";
        }else if (value == 2){
          color = "completed";
        }

        return color;
      }

      static colors = {
        1: "#FFC107",
        2: "#D6D6D6",
        3: "#FFD3B5",
        4: "#FFB085",
        5: "#B5EAD7",
        6: "#D4A5A5",
        7: "#ACD8AA",
        8: "#E1CCEC",
        9: "#E0BD62",
        10: "#E18340",
        11: "#FAD8D6",
        12: "#FFE0B2",
        13: "#FFD180",
        14: "#FF5722",
        15: "#6B4226",
        16: "#6B8E23",
        17: "#FFE066",
        18: "#AED581",
        19: "#8BC34A",
        20: "#9E9D24",
        21: "#7E57C2"
      }

      static color_labels = {
        1: "Introductory Sentence",
        2: "Background information",
        3: "Gap in knowledge or lack",
        4: "Unmet need",
        5: "Impact of missing knowledge gap",
        6: "Long-term goal",
        7: "Overall objective of this grant",
        8: "Central hypothesis of this grant",
        9: "Preliminary data",
        10: "Support from literature",
        11: "Significance",
        12: "Innovation",
        13: "Strength of the team",
        14: "Specific Aim",
        15: "Hypothesis of the Specific Aim",
        16: "Approach for the Specific Aim",
        17: "Expertise with methods in the Aims",
        18: "Expected outcomes of the Aims",
        19: "Expected advancement of field",
        20: "Impact of the work",
        21: "Research Questions",
      }

      static getTextColor(hexColor) {
        // Remove the "#" character if it's present
        hexColor = hexColor.replace("#", "");
    
        // Parse the hex color into RGB values
        const r = parseInt(hexColor.slice(0, 2), 16);
        const g = parseInt(hexColor.slice(2, 4), 16);
        const b = parseInt(hexColor.slice(4, 6), 16);
    
        // Calculate the relative luminance (brightness) of the color
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    
        // Use a threshold to determine whether to use black or white text
        if (luminance > 0.5) {
            return "black"; // Use black text for light backgrounds
        } else {
            return "white"; // Use white text for dark backgrounds
        }
      }

      static formatSentences(sentences) {
        const sentenceList = sentences.split("\n");
        let newText = "";
      
        for (const sentence of sentenceList) {
          const words = sentence.split(" ");
          let blurb = "";
      
          for (let i = 0; i < 4; i++) {
            blurb += words[i] + " ";
          }
          blurb = blurb.slice(0, -1) + "...";
      
          for (let j = words.length - 2; j < words.length; j++) {
            blurb += words[j] + " ";
          }
          newText += blurb + "\n";
        }
      
        return newText.slice(0, -1); // Remove the trailing newline
      }

      static generateMarkdownTable(sentences) {
        // Split the sentences into an array, assuming 'sentences' is a string with each sentence on a new line
        const sentencesList = sentences.trim().split("\n");
      
        // Process each sentence to get the desired format
        const tableData = [];
      
        sentencesList.forEach(sentence => {
          // Split the sentence into words
          const words = sentence.split(" ");
          
          // Check if there are enough words in the sentence
          if (words.length > 10) { // Changed from >= 5 to > 5 to account for the numbering
            // Extract the first three words (excluding the numbering) and the last two words
            const firstThreeWords = words.slice(1, 5).join(" "); // Start from the second word
            const lastTwoWords = words.slice(-7).join(" ");
      
            // Remove periods and commas from the last two words
            // const lastTwoWordsCleaned = lastTwoWords.replace(/\./g, "").replace(/,/g, "");
            const lastTwoWordsCleaned = lastTwoWords;
      
            // Construct the desired format for the sentence
            const formattedSentence = `${firstThreeWords}...${lastTwoWordsCleaned}`;
      
            // Extract the sentence number (excluding the period)
            const sentenceNumber = words[0].replace(".", "");
      
            // Append to the table data
            tableData.push({ sentenceNumber, formattedSentence });
          }else{
            // Extract the first three words (excluding the numbering) and the last two words
            const firstThreeWords = words.slice(1, 5).join(" "); // Start from the second word
            const lastTwoWords = words.slice(-4).join(" ");
      
            // Remove periods and commas from the last two words
            const lastTwoWordsCleaned = lastTwoWords.replace(/\./g, "").replace(/,/g, "");
      
            // Construct the desired format for the sentence
            const formattedSentence = `${firstThreeWords}...${lastTwoWordsCleaned}`;
      
            // Extract the sentence number (excluding the period)
            const sentenceNumber = words[0].replace(".", "");
      
            // Append to the table data
            tableData.push({ sentenceNumber, formattedSentence });
          }
        });
      
        // Generate the markdown table
        const markdownLines = ["| Sentence Number | First 3 words of sentence...Last 2 words of the sentence. | Structure Label |",
                               "|-----------------|----------------------------------------------------|-----------------|"];
      
        tableData.forEach(row => {
          markdownLines.push(`| ${row.sentenceNumber} | ${row.formattedSentence} |  |`);
        });
      
        const markdown = markdownLines.join("\n");
        return markdown;
      }

      static parseInputString(inputString) {
        // Initialize an empty array to store the dictionaries
        const result = [];
      
        // Split the input string into lines
        const lines = inputString.split('\n');
      
        // Define a regular expression pattern to match the data lines
        const pattern = /\| (\d+) \| ([^\|]+)\| (\d+) \|/;
      
        // Iterate over the lines and extract data
        for (const line of lines) {
          const match = line.match(pattern);
          if (match) {
            // Extract the matched values
            const id = parseInt(match[1].trim());
            const sentence = match[2].trim();
            const colorId = parseInt(match[3].trim());
      
            // Create an object and push it to the result array
            const dataObj = { id, sentence, colorId };
            result.push(dataObj);
          }
        }
      
        return result;
      }
}

export default Helpers;