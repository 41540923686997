const Input = ({ label, placeholder, value, error, onChange, type="text", maxLength = 524288, strength }) => {
    return (
        <div className="form-group">
            <label className="form-label" style={{ width: '100%' }}>
                {label}
                {strength && <span className="small" style={{color: strength.color, float: 'right'}}>{ strength.text }</span>}
            </label>
            <div className="form-control-wrap">
                <input type={type} className="form-control" value={value} maxLength={maxLength} placeholder={placeholder ? placeholder : label} onChange={onChange} />
                <small className="text-danger" style={{ fontSize: 10 }}>{error}</small>
            </div>
        </div>
    );
}

export default Input;