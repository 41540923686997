import { Outlet } from "react-router-dom";
import AdminSidebar from "../../Components/Admin/Sidebar";

const AdminLayout = () => {
    return (
        <div className="tyn-root">
            <div className="tyn-content tyn-content-full-height tyn-chatbot tyn-chatbot-page has-aside-base">
                <AdminSidebar />
                <div className="tyn-main" id="tynMain">
                    <Outlet />   
                    <div className="footer">
                        <p>&copy; 2023 Next Thought AI, Inc. <a href="https://grantteacherai.com/terms/" target="_blank" rel="noreferrer">Terms of Service</a>. <a href="https://grantteacherai.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>. Grant Teacher AI may display inaccurate, biased, or offensive content.</p>
                    </div>                 
                </div>
            </div>
        </div>
    );
}

export default AdminLayout;