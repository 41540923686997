import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import axios from "axios";

const WorkbookBuilder = () => {

    const defaultBasic = {
        "section": 0,
        "question": "",
        "question_type": "text",
        "no_row": 1,
        "optional": 0,
    }

    const [grants, setGrants] = useState([]);
    const [grantsLoading, setGrantsLoading] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [promptsLoading, setPromptsLoading] = useState(false);
    const [sections, setSections] = useState([]);
    const [sectionsLoading, setSectionsLoading] = useState(false);

    const [workbookQuestions, setWorkbookQuestions] = useState([]);
    const [allSectionQuestions, setAllSectionQuestions] = useState([]);
    
    const [grant, setGrant] = useState("");
    const [prompt, setPrompt] = useState("");
    const [typePrompt, setTypePrompt] = useState("");
    const [workbook, setWorkbook] = useState(defaultBasic);
    const [addingNew, setAddingNew] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const getSections = () => {
        setSectionsLoading(true);
        axios.get(`${Helpers.apiUrl}workbook/question/section/${prompt}`, Helpers.authHeaders).then(response => {
            setSections(response.data.sections);
            setSectionsLoading(false);
        });
    }

    const getQuestions = () => {
        let data = {
            grant_id: grant,
            prompt_id: prompt, 
        };
        axios.post(`${Helpers.apiUrl}workbook/question/all`, data, Helpers.authHeaders).then(response => {
            setWorkbookQuestions(response.data.questions);
            setAllSectionQuestions(response.data.all);
        });
    }
    

    const getGrants = () => {
        setGrantsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/grants`, Helpers.authHeaders).then(response => {
            setGrants(response.data.grants);
            setGrantsLoading(false);
        });
    }


    const getPrompts = grantId => {
        setPromptsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/prompts/${grantId}`, Helpers.authHeaders).then(response => {
            setPrompts(response.data.prompts);
            setPromptsLoading(false);
        });
    }

    const selectFellowship = e => {
        let fellow = e.target.value;
        getPrompts(fellow);
        setGrant(fellow);
    }

    const addNewQuestion = () => {
        setAddingNew(true);
    }

    const cancelButton = () => {
        setAddingNew(false);
        setWorkbook(defaultBasic);
    }

    const editQuestion = (queId) => {
        let question = workbookQuestions.find(que => que.id == queId);
        console.log(question);
        setWorkbook(question);
        setAddingNew(true);
    }

    const saveQuestion = () => {
        let data = workbook;
        data["grant_id"] = grant;
        data["prompt_id"] = prompt;
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}workbook/question/save`, data, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setWorkbook(defaultBasic);
            setAddingNew(false);
            getQuestions();
        }).catch(error => {
            if(error.response){
                Helpers.toast("error", error.response.data.message);
            }else{
                Helpers.toast("error", "Unexpected error occured");
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const removeQuestion = (queId) => {
        axios.get(`${Helpers.apiUrl}workbook/question/remove/${queId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getQuestions();
        }).catch(error => {
            Helpers.toast("error", "Unexpected Error occured");
        });
    }

    const addNewPrompt = () => {
        if(typePrompt){
            const data = {
                "grant_id" : grant,
                "name" : typePrompt
            }
            axios.post(`${Helpers.apiUrl}categories/prompts/add`, data, Helpers.authHeaders).then(response => {
                setPrompts(response.data.prompts);
                setPrompt(response.data.prompt_id);
            }).catch(error => {
                Helpers.toast("error", "Enexpected error occured")
            })
        }
    }

    useEffect(() => {
        getGrants();
    }, []);
    
    useEffect(() => {
        getQuestions();
    }, [grant, prompt]);

    useEffect(() => {
        if (prompt){
            getSections();
        }
    }, [prompt]);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12" style={{ overflow: 'auto', scrollbarWidth: 'none', height: '100vh' }}>
                        <div className="card mycard" style={{ marginTop: 15, marginBottom: 15 }}>
                            <div className="card-body">
                                <h3>
                                    Workbook Builder Playground
                                </h3>
                                <div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <h6>What Fellowship (F), Career Development Award (K), or Grant (R) are you building a prompt for?</h6>
                                            <select value={grant} className="form-control" disabled={grantsLoading} onChange={selectFellowship}>
                                                {grantsLoading ? <option>Loading...</option> : <option value={''}>Choose Fellowship / Grant / Award</option>}
                                                {grants.map((grant, index) => <option key={index} value={grant.id}>{grant.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <h6>What section of the application are you preparing a prompt for?</h6>
                                            <select className="form-control" value={prompt} disabled={promptsLoading} onChange={e => setPrompt(e.target.value)}>
                                                {promptsLoading ? <option value={''}>Loading sections </option> : <option value={''}>Choose Section </option>}
                                                {prompts.map((prompt, index) => <option key={index} value={prompt.id}>{prompt.name}</option>)}
                                                {(prompts.length > 0 && !promptsLoading) && <option value={'type'}>Let me type</option>}
                                            </select>
                                        </div>
                                        {prompt == 'type' && <div className="col-md-12 mt-3">
                                            <h6>Type the section of application are you preparing a prompt for?</h6>
                                            <input className="form-control" onBlur={addNewPrompt} placeholder="Type section here.." value={typePrompt} onChange={e => setTypePrompt(e.target.value)} />
                                        </div>}
                                    </div>
                                    {(grant && prompt) && <div className="row mt-3">
                                        <div className="col-md-12">
                                            {addingNew ? <h6>Add New Question</h6> :
                                            <button onClick={addNewQuestion} className="btn btn-primary btn-sm">Add New Question</button>}
                                        </div>
                                    </div>}
                                    {!addingNew && <div className="row mt-3">
                                        {Object.values(allSectionQuestions).map(single => {
                                            return (
                                                single.questions.length > 0 ? <div key={single.id} className="col-md-12">
                                                    <h5>{ single.name }</h5>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Workbook Question</th>
                                                                <th>No. Of Rows</th>
                                                                <th>Is Optional</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {single.questions.length === 0 && <tr><td colSpan={4}>No records found...</td></tr>}
                                                            {single.questions.map(que => {
                                                                return (
                                                                    <tr key={que.id}>
                                                                        <td>{ que.question }</td>
                                                                        <td>{ que.no_row }</td>
                                                                        <td>{ que.optional ? 'Yes' : 'No' }</td>
                                                                        <td>
                                                                            <button onClick={() => editQuestion(que.id)} className="btn btn-primary btn-sm">Edit</button>
                                                                            <button onClick={() => removeQuestion(que.id)} className="btn btn-danger btn-sm ml-5">Delete</button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div> : <div></div>
                                            );
                                        })}
                                    </div>}
                                    {(grant && prompt && addingNew) && <div className="row mt-3">
                                        <div className="col-md-12">
                                            <small>Select Section</small>
                                            <select className="form-control" onChange={e => setWorkbook({...workbook, section: e.target.value})} value={workbook.section}>
                                                <option value={0}>Choose Section</option>
                                                {sections.map(section => <option key={section.id} value={section.id}>{section.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-12">
                                            <small>Question</small>
                                            <input className="form-control" value={workbook.question} onChange={e => setWorkbook({...workbook, question: e.target.value})} placeholder="Write Question" />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <small>No. of Rows</small>
                                            <input className="form-control" type="number" value={workbook.no_row} onChange={e => setWorkbook({...workbook, no_row:e.target.value})} placeholder="No. of row for answer" />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <small>Is Optional</small>
                                            <select className="form-control" value={workbook.optional} onChange={e => setWorkbook({...workbook, optional: e.target.value})}>
                                                    <option value={1}>True</option>
                                                    <option value={0}>False</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <button onClick={saveQuestion} disabled={isLoading} className="btn btn-primary btn-sm">{ isLoading ? "Saving..." : "Save Question" }</button>
                                            <button onClick={cancelButton} className="btn btn-danger btn-sm ml-5">Cancel</button>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WorkbookBuilder;