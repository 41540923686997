import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import Moment from "react-moment";
import { Link } from "react-router-dom";

const UsersScreen = () => {
    const [users, setUsers] = useState([]);

    const getUsers = () => {
        axios.get(`${Helpers.apiUrl}admin/users/all`, Helpers.authHeaders).then(response => {
            setUsers(response.data.users)
        });
    }

    useEffect(() => {
        getUsers()
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card m-5 mycard">
                        <div className="card-body">
                            <h3>All Users</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sr. #</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Tokens</th>
                                        <th>Date Joined</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => {
                                        return (
                                            <tr>
                                                <td>{ user.id }</td>
                                                <td>{ user.first_name }</td>
                                                <td>{ user.last_name }</td>
                                                <td>{ user.email }</td>
                                                <td>{ user.tokens }</td>
                                                <td><Moment date={user.date_joined} format="ddd, MMM Do YYYY h:mm A" /></td>
                                                <td>
                                                    <Link to={`/admin/user/chats/${user.id}`} className="btn btn-primary btn-sm">View Chats</Link>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersScreen;