const APIResponse = ({ response }) => {
    // Format the response text
    const formattedResponse = response.replace(/```/g, '');

    // Split the formatted response into separate paragraphs
    const paragraphs = formattedResponse.split('\n');

    return (
        <div>
        {response && paragraphs.map((paragraph, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
        ))}
        </div>
    );
}

export default APIResponse;

