import FeatherIcon from "feather-icons-react";
import { Link, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helper";

const styles = {
    iconColor: {
        color: '#64748b',
    }
}

const NavItem = ({ link, icon, title, isLogout = false }) => {
    const path = window.location.pathname;

    const checkPath = targetPath => {
        if( targetPath === path ){
            return true;
        }else{
            return false;
        }
    }

    let navigate = useNavigate()
    const handleLogout = e => {
        e.preventDefault();
        localStorage.clear();
        Helpers.toast("success", "Logged out successfully");
        navigate('/');
    }

    return (
        <>
        {!isLogout ? 
        <li className={`tyn-aside-item tyn-aside-item-bubbly js-toggle-main ${checkPath(link) ? 'active' : ''}`}>
            <Link to={link} className="tyn-media-group">
                <div className="tyn-media tyn-size-sm" style={styles.iconColor}>
                    <FeatherIcon icon={icon} />
                </div>
                <div className="tyn-media-col">
                    <div className="content">{title}</div>
                </div>
            </Link>
        </li> : <li className={`tyn-aside-item tyn-aside-item-bubbly js-toggle-main`}>
            <div onClick={handleLogout} className="tyn-media-group">
                <div className="tyn-media tyn-size-sm" style={styles.iconColor}>
                    <FeatherIcon icon={'log-out'} />
                </div>
                <div className="tyn-media-col">
                    <div className="content">Logout</div>
                </div>
            </div>
        </li>}
        </>
    );
}

export default NavItem;