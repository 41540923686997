import { Link, useNavigate } from "react-router-dom";
import LogoContainer from "../../Components/Auth/LogoContainer";
import { useState } from "react";
import Input from "../../Components/Input";
import Helpers from "../../Config/Helper";
import axios from "axios";
import Button from "../../Components/Button";
import StrengthCheck from "./StrengthCheck";
import { useGoogleLogin } from '@react-oauth/google';

const Register = () => {
    const userInit = {
        first_name: '',
        last_name: '',
        // username:'',
        email: '',
        password: '',
        password_confirmation: '',
    }
    let defaultStrengthState = Helpers.checkPasswordStrength(""); 
    let navigate = useNavigate()
    const [user, setUser] = useState(userInit);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [strength, setStrength] = useState(defaultStrengthState);
    const [showEmailContainer, setShowEmailContainer] = useState(false);
    const hanldeRegiser = e => {
        e.preventDefault();
        if(strength.strength >= 5){
            if(user.password === user.password_confirmation){
                setIsLoading(true);
                setErrors({});
                axios.post(`${Helpers.apiUrl}auth/register`, user).then(response => {
                    Helpers.toast("success", response.data.message);
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    navigate('/');
                    setIsLoading(false);
                }).catch(error => {
                    Helpers.toast("error", error.response.data.message);
                    setErrors(error.response.data.errors || {});
                    setIsLoading(false);
                });
            }else{
                let errors = {
                    password: "Password confirmation does not match"
                }
                setErrors(errors);
                Helpers.toast("error", "The given data was invalid");
            }
        }else{
            Helpers.toast("error", "Enter a strong password to continue");
        }
    }

    const loginWithGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            setIsLoading(true);
            await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
            }).then(response => {
                let data = {
                    first_name: response.data.given_name,
                    last_name: response.data.family_name,
                    email: response.data.email,
                    sub: response.data.sub,
                }
                axios.post(`${Helpers.apiUrl}auth/login-google`, data).then(res => {
                    Helpers.toast("success", res.data.message);
                    localStorage.setItem('user', JSON.stringify(res.data.user));
                    localStorage.setItem('access_token', res.data.access_token);
                    localStorage.setItem('refresh_token', res.data.refresh_token);
                    let timenow = new Date().getTime();
                    localStorage.setItem('timestamp', timenow);
                    window.location.href = "/";
                    setIsLoading(false);
                }).catch(err => {
                    Helpers.toast("error", "Unexpected error occured");
                    setIsLoading(false);
                })
            }).catch(error => {
                console.log(error);
                Helpers.toast("error", "Unexpected error occured");
                setIsLoading(false);
            });
        },
        onError: error => {
            Helpers.toast("error", "Unexpected error occured Google OAuth");
            setIsLoading(false);
        }
    });

    const handleCheckPasword = e => {
        let value = e.target.value;
        setUser({ ...user, password: value});
        let checkStrength = Helpers.checkPasswordStrength(value);
        setStrength(checkStrength);
    }
    const [agreed, setAgreed] = useState(false);
    const onChange = () => {
    setAgreed(!agreed);
    }


    return (
        <div className="tyn-root">
            <div className="tyn-content tyn-auth tyn-auth-centered">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-10 col-sm-9">
                            <div className="my-3 text-center">
                                <LogoContainer />
                            </div>
                            <div className="text-center mt-4">
                                <p className="small">Already have an account? <Link to="/">Login</Link></p>
                            </div>
                            <div className="card border-0">
                                <div className="p-4">
                                    <div className="row g-3">
                                        <div className="col-12 mb-3">
                                            <h3>You’re one click away from better grant applications.</h3>
                                        </div>
                                        <div className="col-12">
                                            <button onClick={loginWithGoogle} disabled={isLoading} className="btn w-100 btn-primary">
                                                <svg width="24" height="24" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#ffffff" d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301c1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                                                </svg>
                                                {isLoading ? 'Please wait...' : 'Sign Up with your Google Account'}</button>
                                        </div>
                                        <div className="col-12 text-center">
                                            <small>OR</small>
                                        </div>
                                        {!showEmailContainer && <div className="col-12">
                                            <button onClick={() => setShowEmailContainer(true)} disabled={isLoading} className="btn w-100 btn-dark">Create a Free Account</button>
                                        </div>}
                                    </div>
                                    {showEmailContainer && <div className="row g-3">
                                        <div className="col-12">
                                            <h5>Continue with Email</h5>
                                        </div>
                                        <div className="col-6">
                                            <Input label={"First Name"} error={errors.first_name ? errors.first_name : ''} value={user.first_name} onChange={e => setUser({ ...user, first_name: e.target.value })} />
                                        </div>
                                        <div className="col-6">
                                            <Input label={"Last Name"} error={errors.last_name ? errors.last_name : ''} value={user.last_name} onChange={e => setUser({ ...user, last_name: e.target.value })} />
                                        </div>
                                        <div className="col-12">
                                            <Input label={"Email Address"} placeholder={"youremail@example.com"} error={errors.email ? errors.email : ''} value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
                                        </div>
                                        <div className="col-12">
                                            <Input type="password" strength={strength} label={"Password"} error={errors.password ? errors.password : ''} value={user.password} onChange={handleCheckPasword} />
                                        </div>
                                        <div className="col-12">
                                            <Input type="password" label={"Confirm Password"} error={errors.password_confirmation ? errors.password_confirmation : ''} value={user.password_confirmation} onChange={e => setUser({ ...user, password_confirmation: e.target.value })} />
                                        </div>
                                        <StrengthCheck passwordStrength={strength} />
                                        <div>
                                            <input type="checkbox" id="terms" checked={agreed} onChange={onChange}/>
                                            <label for="terms">&nbsp;I agree to the <a href="https://grantteacherai.com/terms/" target="_blank" rel="noreferrer">terms and conditions</a></label>
                                        </div>
                                        <div className="col-12">
                                            <Button onClick={hanldeRegiser} isLoading={isLoading} disabled={!agreed || strength.strength < 5} text={"Create Account"} />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;