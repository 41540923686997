import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Helpers from "../../../Config/Helper";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Quill.register("modules/imageUploader", ImageUploader);

const IntroScreen = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [grants, setGrants] = useState([]);
    const [grantsLoading, setGrantsLoading] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [promptsLoading, setPromptsLoading] = useState(false);
    const [grant, setGrant] = useState("");
    const [prompt, setPrompt] = useState("");
    const [text, setText] = useState("");
    const [errors, setErrors] = useState({});
    const [typePrompt, setTypePrompt] = useState("");
    const [editId, setEditId] = useState(null);
    const [intros, setIntros] = useState([]);
    const [introsLoading, setIntrosLoading] = useState(false);

    // const imageHandler = async () => {
    //     console.log("imageHandler called");
    //     try {
    //       const input = document.createElement('input');
    //       input.setAttribute('type', 'file');
    //       input.setAttribute('accept', 'image/*');
    //       input.click();
        
    //       input.onchange = async () => {
    //         console.log("Input changed");
      
    //         const file = input.files[0];
    //         const formData = new FormData();
    //         formData.append('image', file);
      
    //         const quillInstance = quill.current ? quill.current.getEditor() : null;
      
    //         if (quillInstance) {
    //           console.log("Quill instance found");
              
    //           const range = quillInstance.getSelection(true);
    //           quillInstance.insertEmbed(range.index, 'image', `/assets/images/app/loading.gif`);
    //           quillInstance.setSelection(range.index + 1);
              
    //           const res = await fetch(`${Helpers.apiUrl}intro/upload`, {
    //             method: 'POST',
    //             body: formData,
    //           });
      
    //           const data = await res.json();
      
    //           console.log("Upload response:", data);
      
    //           if (data.url) {
    //             quillInstance.deleteText(range.index, 1);
    //             quillInstance.insertEmbed(range.index, 'image', data.url);
    //           } else {
    //             console.log("No image URL received");
    //           }
    //         } else {
    //           console.log("No Quill instance found");
    //         }
    //       };
    //     } catch (error) {
    //       console.error("Exception caught in imageHandler:", error);
    //     }
    // };

    const modules = {
        toolbar: {
            container:[
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'direction': 'rtl' }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['link'],
                ['clean'],
                // ['image']
            ],
            // handlers: {
            //     'image': imageHandler,
            // },
        },
        // imageUploader: {
        //     upload: file => {
        //       return new Promise((resolve, reject) => {
        //         const formData = new FormData();
        //         formData.append("image", file);
      
        //         fetch(`${Helpers.apiUrl}intro/upload`,
        //           {
        //             method: "POST",
        //             body: formData
        //           }
        //         )
        //           .then(response => response.json())
        //           .then(result => {
        //             console.log(result);
        //             resolve(Helpers.baseUrl + result.url);
        //           })
        //           .catch(error => {
        //             reject("Upload failed");
        //             console.error("Error:", error);
        //           });
        //       });
        //     }
        // }
    }

    

    const getGrants = () => {
        setGrantsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/grants`, Helpers.authHeaders).then(response => {
            setGrants(response.data.grants);
            setGrantsLoading(false);
        });
    }

    const getPrompts = grantId => {
        setPromptsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/prompts/${grantId}`, Helpers.authHeaders).then(response => {
            setPrompts(response.data.prompts);
            setPromptsLoading(false);
        });
    }

    const selectFellowship = e => {
        let fellow = e.target.value;
        getPrompts(fellow);
        setGrant(fellow);
    }

    const addNewPrompt = () => {
        if(typePrompt){
            const data = {
                "grant_id" : grant,
                "name" : typePrompt
            }
            axios.post(`${Helpers.apiUrl}categories/prompts/add`, data, Helpers.authHeaders).then(response => {
                setPrompts(response.data.prompts);
                setPrompt(response.data.prompt_id);
            }).catch(error => {
                Helpers.toast("error", "Enexpected error occured")
            })
        }
    }

    const getIntros = () => {
        setIntrosLoading(true);
        axios.get(`${Helpers.apiUrl}intro/all`, Helpers.authHeaders).then(response => {
            setIntros(response.data.data);
            setIntrosLoading(false);
        });
    }

    const saveIntro = () => {
        setIsLoading(true);
        let data = {
            "grant_id": grant, 
            "prompt_id": prompt, 
            "text": text,
            "id": editId,
        }
        axios.post(`${Helpers.apiUrl}intro/save`, data, Helpers.authHeaders).then(response => {
            setDefault();
            Helpers.toast("success", response.data.message);
            setEditId(null);
            getIntros();
            setIsEditing(false);
            setIsLoading(false);
        }).catch(errors => {
            setErrors(errors.response.data.errors || {});
            Helpers.toast("error", errors.response.data.message);
            setIsLoading(false);
        })
    }

    const setDefault = () => {
        setGrant("");
        setPrompt("");
        setText("");
    }

    const editIntro = intro => {
        setGrant(intro.grant.id);
        getPrompts(intro.grant.id);
        setPrompt(intro.prompt.id);
        setText(intro.intro_text);
        setEditId(intro.id);
        setIsEditing(true);
    }

    const deleteIntro = introId => {
        axios.get(`${Helpers.apiUrl}intro/delete/${introId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getIntros();
        });
    }

    const cancelEdit = () => {
        setEditId(null);
        setIsEditing(false);
    }

    useEffect(() => {
        getIntros();
        getGrants();
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                
                <div className="col-md-12">
                    <div className="card m-3 mycard">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>{isEditing ? 'Add New Intro' : 'All Intros'}</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    {!isEditing ? <button onClick={() => setIsEditing(true)} className="btn btn-primary btn-sm">Add New Intro</button>
                                    : <button onClick={cancelEdit} className="btn btn-danger btn-sm">Cancel</button>}
                                </div>
                            </div>
                            {isEditing ?
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h6>What Fellowship (F), Career Development Award (K), or Grant (R) are you building a prompt for?</h6>
                                        <select value={grant} disabled={grantsLoading} className="form-control" onChange={selectFellowship}>
                                            {grantsLoading ? <option>Loading...</option> : <option value={''}>Choose Fellowship / Grant / Award</option>}
                                            {grants.map((grant, index) => <option key={index} value={grant.id}>{grant.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <h6>What section of the application are you preparing a prompt for?</h6>
                                        <select className="form-control" disabled={promptsLoading} value={prompt} onChange={e => setPrompt(e.target.value)}>
                                            {promptsLoading ? <option value={''}>Loading sections </option> : <option value={''}>Choose Section </option>}
                                            {prompts.map((prompt, index) => <option key={index} value={prompt.id}>{prompt.name}</option>)}
                                            {(prompts.length > 0 && !promptsLoading) && <option value={'type'}>Let me type</option>}
                                        </select>
                                    </div>
                                    {prompt === 'type' && <div className="col-md-12 mt-2">
                                        <h6>Type the section of application are you preparing a prompt for?</h6>
                                        <input className="form-control" onBlur={addNewPrompt} placeholder="Type section here.." value={typePrompt} onChange={e => setTypePrompt(e.target.value)} />
                                    </div>}
                                    <div className="form-group col-md-12 mt-2">
                                        <h6>Introduction Text</h6>
                                        <ReactQuill placeholder='Start writing...' theme="snow" className='editor' modules={modules} onChange={setText} value={text} />
                                    </div>
                                    <div className="form-group col-md-12 mt-2">
                                        <button onClick={saveIntro} disabled={isLoading} className="btn btn-primary btn-sm">{isLoading ? 'Saving...' : 'Save Intro Text'}</button>
                                    </div>
                                </div> : 
                                <table className="table mt-3">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Grant</th>
                                            <th>Prompt</th>
                                            <th>Text</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {introsLoading && <tr>
                                            <td colSpan={5}>Please wait. Loading...</td>
                                        </tr>}
                                        {intros.length === 0 && <tr>
                                            <td colSpan={5}>No records found...</td>
                                        </tr>}
                                        {intros.map(intro => {
                                            return (
                                                <tr key={intro.id}>
                                                    <td>{ intro.id }</td>
                                                    <td>{ intro.grant.name }</td>
                                                    <td>{ intro.prompt.name }</td>
                                                    <td dangerouslySetInnerHTML={ { __html: intro.intro_text}}></td>
                                                    <td>
                                                        <button onClick={() => editIntro(intro)} className="btn btn-primary btn-sm"><FeatherIcon icon="edit" /></button>
                                                        <button onClick={() => deleteIntro(intro.id)} className="btn btn-danger btn-sm" style={{ marginLeft: 5 }}><FeatherIcon icon="trash-2" /></button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroScreen;