import { useEffect, useRef, useState } from "react";
import ChatAdjust from "../../../Components/ChatAdjust";
import { useParams } from "react-router-dom";
import axios from "axios";
import Helpers from "../../../Config/Helper";
import ChatMessage from "../../../Components/ChatMessage";

const MessagesScreen = () => {
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null)
    const { chat_id } = useParams();

    const getMessages = () => {
        axios.get(`${Helpers.apiUrl}admin/users/messages/${chat_id}`, Helpers.authHeaders).then(response => {
            setMessages(response.data.messages);
            setTimeout(() => {
                scrollToBottom();
            }, 500);
        })
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", top: messagesEndRef.current.scrollHeight, block:"end" })
    }

    useEffect(() => {
        getMessages();
    }, []);

    return (
        <>
            <ChatAdjust />
            <div className="tyn-chat-body my-4 px-4" data-simplebar>
                <div className="container px-0">
                    <div className="tyn-qa tyn-qa-bubbly">
                        {
                            messages.map(message => {
                                return (
                                    !message.is_hidden && <ChatMessage key={message.id} id={message.id} message={message.message} isBot={message.is_bot} canCopy={message.can_copy} />
                                );
                            })
                        }
                    </div>
                    <div id="something" ref={messagesEndRef} />
                </div>
            </div>
        </>
    )
}

export default MessagesScreen;