import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import Helpers from '../../Config/Helper';
import NavItem from '../Admin/NavItam';

const GrantWriterSidebar = () => {
    
    let navigate = useNavigate()
    const handleLogout = e => {
        e.preventDefault();
        localStorage.clear();
        Helpers.toast("success", "Logged out successfully");
        navigate('/');
    }

    return (
        <div className="tyn-aside tyn-aside-base">
            <div className="tyn-aside-head">
                <div className="tyn-aside-head-text text-center">
                    <img src='/assets/images/app/logo.png' alt='' style={{ width: '70%' }} />
                </div>
            </div>
            <div className="tyn-aside-body" data-simplebar>
                <ul className="tyn-aside-list">
                    <NavItem link={'/writer/dashboard'} title={"Dashboard"} icon={'home'} />
                    <NavItem link={'/writer/builder-section'} title={"Section Builder"} icon={'layout'} />
                    <NavItem link={'/writer/specific-aims'} title={"Specific Aims"} icon={'file-text'} />
                    <NavItem link={'/writer/additional-docs'} title={"Additional Docs"} icon={'file-text'} />
                    <NavItem link={'/writer/workbook/sections'} title={"Workbook Sections"} icon={'columns'} />
                    <NavItem link={'/writer/workbook/builder'} title={"Workbook Builder"} icon={'layout'} />
                    <NavItem link={'/writer/questions/builder'} title={"Questions Builder"} icon={'help-circle'} />
                </ul>
            </div>
            <div className="tyn-aside-foot">
                <div className="w-100">
                    <div className='row mt-3' style={{ alignItems: 'center' }}>
                        <div className='col-10'>
                            <h6>{ Helpers.authUser.fields.username }</h6>
                            <span className='small'>{ Helpers.authUser.fields.email }</span>
                        </div>
                        <div className='col-2'>
                            <button className="tyn-media tyn-size-sm" onClick={handleLogout} style={{border:'none'}}>
                                <FeatherIcon icon="log-out" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GrantWriterSidebar;