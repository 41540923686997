import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { SortableList, SortableItem } from "@thaddeusjiang/react-sortable-list";

const IntroQuestions = () => {

    const defaultQuestion = {
        question: "",
        answer_limit: 100,
    }

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [grants, setGrants] = useState([]);
    const [grantsLoading, setGrantsLoading] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [promptsLoading, setPromptsLoading] = useState(false);
    const [grant, setGrant] = useState("");
    const [prompt, setPrompt] = useState("");
    const [question, setQuestion] = useState(defaultQuestion);
    const [errors, setErrors] = useState({});
    const [typePrompt, setTypePrompt] = useState("");
    const [questions, setQuestions] = useState([]);
    const [orderItems, setOrderItems] = useState([]);
    const [orderLoading, setOrderLoading] = useState(false);
    const [showOrderWindow, setShowOrderWindow] = useState(false);

    const getGrants = () => {
        setGrantsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/grants`, Helpers.authHeaders).then(response => {
            setGrants(response.data.grants);
            setGrantsLoading(false);
        });
    }

    const getOrderItems = promptId => {
        if(promptId){
            axios.get(`${Helpers.apiUrl}intro/questions/prompt/${promptId}`).then(response => {
                setOrderItems(response.data.data);
            });
        }
    }

    const getPrompts = (grantId, promptId = 0) => {
        setPromptsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/prompts/${grantId}`, Helpers.authHeaders).then(response => {
            setPrompts(response.data.prompts);
            if(promptId){
                setPrompt(promptId);
            }
            setPromptsLoading(false);
        });
    }

    const selectFellowship = e => {
        let fellow = e.target.value;
        getPrompts(fellow);
        setGrant(fellow);
    }

    const addNewPrompt = () => {
        if(typePrompt){
            const data = {
                "grant_id" : grant,
                "name" : typePrompt
            }
            axios.post(`${Helpers.apiUrl}categories/prompts/add`, data, Helpers.authHeaders).then(response => {
                setPrompts(response.data.prompts);
                setPrompt(response.data.prompt_id);
            }).catch(error => {
                Helpers.toast("error", "Enexpected error occured")
            })
        }
    }

    const getQuestions = () => {
        axios.get(`${Helpers.apiUrl}intro/question/all`, Helpers.authHeaders).then(response => {
            setQuestions(response.data.data);
        });
    }

    const saveQuestion = () => {
        setIsLoading(true);
        let data = {
            "grant_id": grant, 
            "prompt_id": prompt, 
            "question": question.question,
            "answer_limit": question.answer_limit,
            "id": question.id ? question.id : null,
        }
        axios.post(`${Helpers.apiUrl}intro/question/save`, data, Helpers.authHeaders).then(response => {
            setDefault();
            Helpers.toast("success", response.data.message);
            getQuestions();
            setIsEditing(false);
            setIsLoading(false);
        }).catch(errors => {
            setErrors(errors.response.data.errors || {});
            Helpers.toast("error", errors.response.data.message);
            setIsLoading(false);
        })
    }

    const setDefault = () => {
        setGrant("");
        setPrompt("");
        setQuestion(defaultQuestion);
    }

    const editQuestion = intro => {
        setGrant(intro.grant.id);
        getPrompts(intro.grant.id, intro.prompt.id);
        setQuestion(intro);
        setIsEditing(true);
    }

    const deleteQuestion = questionId => {
        axios.get(`${Helpers.apiUrl}intro/question/delete/${questionId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getQuestions();
        });
    }

    const updatePrompt = e => {
        let value = e.target.value;
        setPrompt(value);
        getOrderItems(value);
    }

    const updateOrder = () => {
        setOrderLoading(true);
        let data = {
            data: orderItems,
        }
        
        axios.post(`${Helpers.apiUrl}intro/question/update-order`, data, Helpers.authHeaders).then(response => {
            getQuestions();
            setOrderItems([]);
            setGrant("");
            setPrompt("");
            setPrompts([]);
            setShowOrderWindow(false);
            setOrderLoading(false);
            Helpers.toast("success", response.data.message);
        }).catch(error => {
            Helpers.toast("error", "Unexpected error");
            setOrderLoading(false);
        });
    }

    useEffect(() => {
        getQuestions();
        getGrants();
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                
                <div className="col-md-12">
                    <div className="card m-3 mycard">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    {showOrderWindow && <h3>{'Manage Questions Order'}</h3>}
                                    {!showOrderWindow && <h3>{isEditing ? 'Add New Intro Question' : 'All Intro Questions'}</h3>}
                                </div>
                                <div className="col-md-6 text-right">
                                    {(!isEditing && !showOrderWindow) && <button onClick={() => setIsEditing(true)} className="btn btn-primary btn-sm">Add New Intro Question</button>}
                                    {(isEditing && !showOrderWindow) && <button onClick={() => setIsEditing(false)} className="btn btn-danger btn-sm">Cancel</button>}
                                    {(!isEditing && !showOrderWindow) && <button onClick={() => setShowOrderWindow(true)} className="btn btn-primary btn-sm ml-5"><FeatherIcon icon={'list'} /> Manage Questions Order</button>}
                                    {(!isEditing && showOrderWindow) && <button onClick={updateOrder} className="btn btn-primary btn-sm ml-5" disabled={orderLoading}>{orderLoading ? 'Saving...' : 'Update Order'}</button>}
                                    {(!isEditing && showOrderWindow) && <button onClick={() => setShowOrderWindow(false)} className="btn btn-danger btn-sm ml-5">Cancel</button>}
                                </div>
                            </div>
                            {(showOrderWindow && !isEditing) && <div className="row mt-3">
                                <div className="col-md-12">
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <h6>Select Fellowship (F), Career Development Award (K), or Grant (R)</h6>
                                            <select value={grant} className="form-control" disabled={grantsLoading} onChange={selectFellowship}>
                                                {grantsLoading ? <option value={''}>Loading...</option> : <option value={''}>Choose Fellowship / Grant / Award</option>}
                                                {grants.map((grant, index) => <option key={index} value={grant.id}>{grant.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <h6>Select prompt</h6>
                                            <select className="form-control" value={prompt} disabled={promptsLoading} onChange={updatePrompt}>
                                                {promptsLoading ? <option value={''}>Loading prompts</option> : <option value={''}>Choose Prompt</option>}
                                                {prompts.map((prompt, index) => <option key={index} value={prompt.id}>{prompt.name}</option>)}
                                                {(!promptsLoading && prompts.length > 0) && <option value={'type'}>Let me type</option>}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            {orderItems.length === 0 && <h5>No Questions Found...</h5>}
                                            {orderItems.length > 0 && <table className="table mt-3">
                                                <thead>
                                                    <tr>
                                                        <th>Question (Drag & Drop the questions to set the order)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <SortableList items={orderItems} setItems={setOrderItems}>
                                                        {({ items }) => (
                                                            <>
                                                            {items.map(que => (
                                                                <SortableItem key={que.id} id={que.id}>
                                                                    <tr>
                                                                        <td style={{ width: '100vw' }}>{ que.question }</td>
                                                                    </tr>
                                                                </SortableItem>
                                                            ))}
                                                            </>
                                                        )}
                                                    </SortableList>
                                                </tbody>
                                            </table>}
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(isEditing && !showOrderWindow) &&
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h6>What Fellowship (F), Career Development Award (K), or Grant (R) are you building a prompt for?</h6>
                                        <select value={grant} className="form-control" disabled={grantsLoading} onChange={selectFellowship}>
                                            {grantsLoading ? <option value={''}>Loading...</option> : <option value={''}>Choose Fellowship / Grant / Award</option>}
                                            {grants.map((grant, index) => <option key={index} value={grant.id}>{grant.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <h6>What section of the application are you preparing a prompt for?</h6>
                                        <select className="form-control" value={prompt} disabled={promptsLoading} onChange={e => setPrompt(e.target.value)}>
                                            {promptsLoading ? <option value={''}>Loading... </option> : <option value={''}>Choose Section </option>}
                                            {prompts.map((prompt, index) => <option key={index} value={prompt.id}>{prompt.name}</option>)}
                                            {(!promptsLoading && prompts.length > 0) && <option value={'type'}>Let me type</option>}
                                        </select>
                                    </div>
                                    {prompt === 'type' && <div className="col-md-12 mt-2">
                                        <h6>Type the section of application are you preparing a prompt for?</h6>
                                        <input className="form-control" onBlur={addNewPrompt} placeholder="Type section here.." value={typePrompt} onChange={e => setTypePrompt(e.target.value)} />
                                    </div>}
                                    <div className="form-group col-md-12 mt-2">
                                        <h6>Introduction Question</h6>
                                        <textarea placeholder="Intro question" className="form-control" value={question.question} rows={2} onChange={e => setQuestion({...question, question:e.target.value})}></textarea>
                                    </div>
                                    <div className="form-group col-md-12 mt-2">
                                        <h6>Answer Limit</h6>
                                        <input className="form-control" placeholder="Limit for answer" value={question.answer_limit} onChange={e => setQuestion({...question, answer_limit:e.target.value})} />
                                    </div>
                                    <div className="form-group col-md-12 mt-2">
                                        <button onClick={saveQuestion} disabled={isLoading} className="btn btn-primary btn-sm">{isLoading ? 'Saving...' : 'Save Question'}</button>
                                    </div>
                                </div>}
                                {(!isEditing && !showOrderWindow) && <table className="table mt-3">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Grant</th>
                                            <th>Prompt</th>
                                            <th>Question</th>
                                            <th>Answer Limit</th>
                                            <th>Order</th>
                                            <th style={{ width: 90 }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {questions.map(que => {
                                            return (
                                                <tr key={que.id}>
                                                    <td>{ que.id }</td>
                                                    <td>{ que.grant.name.split("—")[0] }</td>
                                                    <td>{ que.prompt.name }</td>
                                                    <td>{ que.question }</td>
                                                    <td>{ que.answer_limit }</td>
                                                    <td>{ que.question_order }</td>
                                                    <td>
                                                        <button onClick={() => editQuestion(que)} className="btn btn-primary btn-sm"><FeatherIcon icon="edit" /></button>
                                                        <button onClick={() => deleteQuestion(que.id)} className="btn btn-danger btn-sm" style={{ marginLeft: 5 }}><FeatherIcon icon="trash-2" /></button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroQuestions;