import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Helpers from "../../../../Config/Helper";
import ChatAdjust from "../../../../Components/ChatAdjust";
import AskAnything from "../../../../Components/AskAnything";
import ChatMessage from "../../../../Components/ChatMessage";
import BotTyping from "../../../../Components/BotTyping";

const BuilderChat = ({ btnId, fellowship, isSelected = false, saveButton }) => {

    const API_ENDPOINT = 'https://api.openai.com/v1/chat/completions';

    const messagesEndRef = useRef(null)
    
    const [aimsDocs, setAimsDocs] = useState([]);
    const [addsDocs, setAddsDocs] = useState([]);
    const [theory, setTheory] = useState("");
    const [aimEditor, setAimEditor] = useState(false);
    const [additional, setAdditional] = useState("");
    const [messages, setMessages] = useState([]);
    const [showChatbot, setShowChatbot] = useState(false)
    
    const [addEditor, setAddEditor] = useState(false);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const gptResponse = async (messages, temperature, n) => {
        let res = "";
        await axios.post(API_ENDPOINT, {
            model: "gpt-4-0613",
            messages:messages, 
            temperature: temperature,
            n: n,
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
            }
        }).then(response => {
            res = response;
        }).catch(error => {
            res = "error";
        });

        return res;
    }
    
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", top: messagesEndRef.current.scrollHeight, block:"end" })
    }

    const getAimsDocs = () => {
        axios.get(`${Helpers.apiUrl}documents/specific/all`, Helpers.authHeaders).then(response => {
            setAimsDocs(response.data.specificaims);
        });
    }
    
    const getAddsDocs = () => {
        axios.get(`${Helpers.apiUrl}documents/additional/all`, Helpers.authHeaders).then(response => {
            setAddsDocs(response.data.additionals);
        });
    }

    const updateTheory = e => {
        setTheory(e.target.value);
        setAimEditor(true);
    }

    const updateAdditional = e => {
        setAdditional(e.target.value);
        setAddEditor(true);
    }

    const addMessage = (user_msg, isBot = false) => {
        let msg = {
            message: user_msg,
            is_bot: isBot,
            is_included: true,
            is_hidden: false,
            id: Helpers.genRand(8),
        }
        let new_messages = messages;
        new_messages.push(msg);
        setMessages(new_messages);
    }

    const setupChat = () => {
        if(fellowship && theory){
            if(isSelected){
                saveButton({is_selected: true});
            }
            addMessage("Which Fellowship or Award are you applying for (e.g., F30, K99, R01)?", true);
            addMessage(fellowship);
            addMessage(`Do you have a draft of a document? If so, please include it now.`, true);
            addMessage(theory);
            if(additional){
                addMessage("Do you have any additional text that you would like to use (e.g., title, abstract, results, preliminary data, ..., up to 1,500 words)?", true);
                addMessage(additional);
            }
            addMessage("How can I assist you futher. Please click the Test Revise Button to test your revise button", true);
            setShowChatbot(true);
            setTimeout(() => {
                scrollToBottom();
            }, 500);
        }else{
            Helpers.toast("error", "Please select the draft of a document to continue");
        }
    }

    const testRevise = () => {
        setIsLoading(true);
        if(isSelected){
            saveButton({is_selected: true});
        }
        setTimeout(() => {
            scrollToBottom();
        }, 500);
        let data = {
            "messages": messages,
            "button": btnId,
            "is_hidden": true
        }
        axios.post(`${Helpers.apiUrl}bot/test-revise-init`, data, Helpers.authHeaders).then(async res => {
            let previous_messages = res.data.previous_messages;
            let no_res = res.data.no_res;
            let gptRes = await gptResponse(previous_messages, 1, no_res);
            if(gptRes){
                data.response = gptRes.data.choices[0].message.content;
                axios.post(`${Helpers.apiUrl}bot/test-revise-final`, data, Helpers.authHeaders).then(async response => {
                    setMessages(response.data.messages)
                    setIsLoading(false);
                    setTimeout(() => {
                        scrollToBottom();
                    }, 500);
                }).catch(error => {
                    Helpers.toast("error", "Unexpected Error");
                    setIsLoading(false);
                });
            }
            
        }).catch(error => {
            Helpers.toast("error", "Unexpected Error");
            setIsLoading(false);
        });
    }

    const getReply = () => {
        setIsLoading(true);
        if(isSelected){
            saveButton({is_selected: true});
        }
        setTimeout(() => {
            scrollToBottom();
        }, 500);
        let data = {
            "messages": messages,
            "button": 0,
            "message": message,
            "is_hidden": false
        }
        axios.post(`${Helpers.apiUrl}bot/test-revise-init`, data, Helpers.authHeaders).then(async res => {
            let previous_messages = res.data.previous_messages;
            let no_res = res.data.no_res;
            let gptRes = await gptResponse(previous_messages, 1, no_res);
            if(gptRes){
                data.response = gptRes.data.choices[0].message.content;
                axios.post(`${Helpers.apiUrl}bot/test-revise-final`, data, Helpers.authHeaders).then(async response => {
                    setMessages(response.data.messages)
                    setIsLoading(false);
                    setMessage("");
                    setTimeout(() => {
                        scrollToBottom();
                    }, 500);
                }).catch(error => {
                    Helpers.toast("error", "Unexpected Error");
                    setIsLoading(false);
                });
            }
            
        }).catch(error => {
            Helpers.toast("error", "Unexpected Error");
            setIsLoading(false);
        });
    }

    const cancelChatbot = () => {
        setMessages([]);
        setShowChatbot(false);
    }

    useEffect(() => {
        getAimsDocs();
        getAddsDocs();
    }, []);

    return (
        <>
        {!showChatbot && <div className="card mycard" style={{ marginTop: 15, marginBottom: 15 }}>
            <div className="card-body">
                <h3>Test Revise Button</h3>
                <div className="row mt-3">
                    <div className="col-md-12 form-group">
                        <small>
                            Specific Aims Document
                            <button className="btn btn-primary btn-sm fr" onClick={() => setAimEditor(!aimEditor)}>{aimEditor ? 'Choose from Existing' : 'Add Your Own'}</button>
                        </small>
                        {!aimEditor ? <select className="form-control mt-2" value={theory} onChange={updateTheory}>
                            <option value={''}>Select Document</option>
                            {aimsDocs.map(doc => {
                                return <option key={doc.id} value={doc.document}>{ doc.title }</option>;
                            })}
                        </select> :
                        <textarea className="form-control mt-3" rows={5} onChange={e => setTheory(e.target.value)} value={theory}></textarea>}
                    </div>
                    <div className="col-md-12 form-group mt-3">
                        <small>
                            Additional Document
                            <button className="btn btn-primary btn-sm fr"  onClick={() => setAddEditor(!addEditor)}>{addEditor ? 'Choose from Existing' : 'Add Your Own'}</button>
                        </small>
                        {!addEditor ? <select className="form-control mt-2" value={additional} onChange={updateAdditional}>
                            <option value={''}>Select Document</option>
                            {addsDocs.map(doc => {
                                return <option key={doc.id} value={doc.document}>{ doc.title }</option>;
                            })}
                        </select> :
                        <textarea className="form-control mt-3" rows={5} onChange={e => setAdditional(e.target.value)} value={additional}></textarea>}
                    </div>
                    <div className="col-md-12 form-group mt-3">
                        <button onClick={setupChat} className="btn btn-primary btn-sm">Go to Chatbot</button>
                    </div>
                </div>
            </div>
        </div>}
        {showChatbot && <div style={{ height: '75vh', marginBottom: '100px' }}>
            <ChatAdjust />
            <div className="tyn-chat-body my-4 px-4" data-simplebar>
                <div className="container px-0">
                    <div className="tyn-qa tyn-qa-bubbly">
                    {
                        messages.map(message => {
                            return (
                                !message.is_hidden && <ChatMessage key={message.id} id={message.id} message={message.message} isBot={message.is_bot} canCopy={message.can_copy} />
                            );
                        })
                    }
                    {isLoading && <BotTyping />}
                    </div>
                    <div id="something" ref={messagesEndRef} />
                </div>
            </div>
            <AskAnything inputLimit={100} showCancel={true} onCancel={cancelChatbot} isLoading={isLoading} tryAgain={() => {}} showTryAgain={false} reviseSectionInit={testRevise} reviseText={"Test Revise Button"} setMessage={setMessage} getReply={getReply} message={message} />
        </div>}
        </>
    )
}

export default BuilderChat;