import { useCallback, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import ChatScreen from "./ChatScreen";
import Welcome from "./Welcome";
import axios from "axios";
import Helpers from "../../Config/Helper";
import SettingsScreen from "../Admin/Screens/Settings";
import Upgrade from "./Upgrade";
import Screen from "./Screen";
import { useNavigate, useParams } from "react-router-dom";
import InstituteScreen from "./Institute";
import Workbook from "./Workbook/Workbook";
import Editor from "./Editor";
import Tasks from "./Tasks";
import Questionnnaire from "./Questionnaire";
import ReportIssue from "./Report";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_live_51Ndv6dDFumR5PGL37V7IUrW1g8RYQwXUYE4pxqUnpVnm1Cy4Du2wldsfrmKNpP1k4lZSRfXDYqKuKERrd02mRpCN00lxJjmoXD');
// const stripePromise = loadStripe('pk_test_51KZXSSDEVtOkYDT1qB1qXyW7O35EqnEefkceMK2SbOmq554x7M2XKZ7qMTz4W0DBRH8kOGEJ95yx7lWq3wFkZo9A00sDQFTorl');

const Dashboard = () => {

    const navigate = useNavigate()

    const { screen_name } = useParams();
    const [isTalking, setIsTalking] = useState(false);
    const [chats, setChats] = useState([]);
    const [chatId, setChatId] = useState(0);
    const [tokens, setTokens] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const user_profile = Helpers.authUser.profile;
    const [userProfile, setUserProfile] = useState(user_profile);
    const [document, setDocument] = useState("");
    const [workbookId, setWorkbookId] = useState(null);
    const [feedbackBox, setFeedbackBox] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const hanldeStartTalkingScreen = useCallback(() => {
        setIsTalking(true);
        setFeedbackBox(false);
    }, []);

    const handleNewChatScreen = useCallback(() => {
        setChatId(0)
        setIsTalking(false)
    }, []);

    const getChats = () => {
        setIsLoading(false)
        axios.get(`${Helpers.apiUrl}bot/get-chats`, Helpers.authHeaders).then(response => {
            setChats(response.data.chats);
            setIsLoading(true);
        }).catch(error => {
            if(error.response.data.code === "token_not_valid"){
                localStorage.clear();
                window.location.reload();
            }
        })
    }

    const checkParam = () => {
        const workbook = Helpers.getParam('workbook');
        if(workbook){
            axios.get(`${Helpers.apiUrl}workbook/check/${workbook}`, Helpers.authHeaders).then(response => {
                if(response.data.is_valid){
                    setWorkbookId(workbook);
                    setIsTalking(true);
                }else{
                    navigate(-1);
                }
            });
        }
    }

    const sendFeedback = () => {
        setLoading(true);
        setErrors({});
        let data = {
            "feedback": feedback,
        }
        axios.post(`${Helpers.apiUrl}issues/feedback/send`, data, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setLoading(false);
            setFeedback("");
            setFeedbackBox(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setLoading(false);
        })
    }

    const openFeedbackBox = () => {
        if(!feedbackBox){
            setFeedbackBox(true);
        }
    }

    useEffect(() => {
        checkParam();
        getChats();
        setTokens(Helpers.authUser.profile.tokens);
        
    }, []);

    useEffect(() => {
        if(screen_name === 'dashboard' && !isTalking){
            setFeedbackBox(true);
        }else{
            setFeedbackBox(false);
        }
    }, [isTalking, screen_name]);

    return (
        <div className="tyn-root">
            <div className="tyn-content tyn-content-full-height tyn-chatbot tyn-chatbot-page has-aside-base">
                <Sidebar tokens={tokens} profile={userProfile} chats={chats} setChatId={setChatId} chatId={chatId} getChats={getChats} onChange={handleNewChatScreen} />
                <Screen screen={screen_name} name={'dashboard'}>
                    {isTalking || chatId ? 
                    <ChatScreen workbookId={workbookId} document={document} userProfile={userProfile} setTokens={setTokens} setChatId={setChatId} chatId={chatId} getChats={getChats} /> : 
                    <Welcome setDocument={setDocument} userProfile={userProfile} setUserProfile={setUserProfile} setChatId={setChatId} onChange={hanldeStartTalkingScreen} />}
                </Screen>
                <Screen screen={screen_name} name={'report-issue'}><ReportIssue /></Screen>
                <Screen screen={screen_name} name={'editor'}><Editor /></Screen>
                <Screen screen={screen_name} name={'workbook'}><Workbook /></Screen>
                <Screen screen={screen_name} name={'settings'}><SettingsScreen /></Screen>
                <Screen screen={screen_name} name={'upgrade'}>
                    <Elements stripe={stripePromise}>
                        <Upgrade />
                    </Elements>
                </Screen>
                <Screen screen={screen_name} name={'tasks'}><Tasks /></Screen>
                <Screen screen={screen_name} name={'questionnaire'}><Questionnnaire /></Screen>
                <Screen screen={screen_name} name={'institute-setup'} overflow="auto"><InstituteScreen userProfile={userProfile} setUserProfile={setUserProfile} /></Screen>
            </div>
            <div className={feedbackBox ? "feedback-container" : "feedback-hidden"} onClick={openFeedbackBox}>
                {!feedbackBox && <div className="feedback-header"><small><strong>How can we improve?</strong></small></div>}
                {feedbackBox && <div className="row">
                    <div className="col-12 mt-2">
                        <div className="text-center">
                            <h5>What would make you love us?</h5>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="form-group">
                            <label className="form-label">How can we improve GTAI?</label>
                            <textarea className="form-control" value={feedback} onChange={e => setFeedback(e.target.value)} placeholder="Write your feedback" ></textarea>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <button onClick={sendFeedback} disabled={loading} className="btn btn-primary btn-sm">{loading ? "Sending..." : "Send Feedback"}</button>
                        <button onClick={() => setFeedbackBox(false)} className="btn btn-danger btn-sm ml-5">Cancel</button>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default Dashboard;