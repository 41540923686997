import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import { SortableList, SortableItem } from "@thaddeusjiang/react-sortable-list";
import axios from "axios";
import ButtonCode from "./Builder/Code";
import BuilderChat from "./Builder/Chat";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Modal from "../../../Components/Modal";
import Moment from "react-moment";

const Builder = () => {

    const defalutRevised = {
        "id": "",
        "intro": "",
        "guidelines": "",
    }

    const defaultBasic = {
        "name": "",
        "intro": "",
        "guidelines": "",
        "message": "",
        "instructions": [],
        "advanced": "",
    }

    
    const buttonNames = ["One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten"];

    const [grants, setGrants] = useState([]);
    const [grantsLoading, setGrantsLoading] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [promptsLoading, setPromptsLoading] = useState(false);
    const [institutes, setInstitutes] = useState([]);
    const [institutesLoading, setInstitutesLoading] = useState(false);
    const [institute, setInstitute] = useState("");

    const [reviseButtons, setReviseButtons] = useState([]);
    const [buttonsLoading, setButtonsLoading] = useState(false);
    
    const [grant, setGrant] = useState("");
    const [prompt, setPrompt] = useState("");
    const [typePrompt, setTypePrompt] = useState("");
    const [revisedSections, setRevisedSections] = useState([]);
    const [revisedSection, setRevisedSection] = useState(defalutRevised);
    const [revisedButton, setRevisedButton] = useState(defaultBasic);
    const [addingNew, setAddingNew] = useState(false);
    const [newInstruction, setNewInstruction] = useState(false);
    const [isCodeView, setIsCodeView] = useState(false);

    const [isPublishing, setIsPublishing] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [selectedButtonId, setSelectedButtonId] = useState(0);

    const [isOpen, setIsOpen] = useState(false);
    const [openIns, setOpenIns] = useState(null);

    const [isSaving, setIsSaving] = useState(false);
    const [versions, setVersions] = useState([]);
    const [versionView, setVersionView] = useState(false);
    const [isRestoring, setIsRestoring] = useState(false);

    
    const getButtons = (btnId = 0) => {
        setButtonsLoading(true);
        let data = {
            grant_id: grant,
            prompt_id: prompt, 
            institute_id: institute,
        };
        axios.post(`${Helpers.apiUrl}documents/button/get`, data, Helpers.authHeaders).then(response => {
            setReviseButtons(response.data.buttons);
            if(btnId){
                let btn = response.data.buttons.find(b => b.id == btnId);
                selectButton(btn);
                setVersionView(false);
            }
            setButtonsLoading(false);
        });
    }
    

    const getGrants = () => {
        setGrantsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/grants`, Helpers.authHeaders).then(response => {
            setGrants(response.data.grants);
            setGrantsLoading(false);
        });
    }

    const getInstitutes = () => {
        setInstitutesLoading(true);
        axios.get(`${Helpers.apiUrl}categories/institutes`, Helpers.authHeaders).then(response => {
            let insts = response.data.institutes;
            let undecidedIndex = insts.findIndex(ins => ins.name === "Undecided");
            insts[undecidedIndex]["name"] = "All Institutes";
            setInstitutes(insts);
            setInstitutesLoading(false);
        });
    }

    const getPrompts = grantId => {
        setPromptsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/prompts/${grantId}`, Helpers.authHeaders).then(response => {
            setPrompts(response.data.prompts);
            setPromptsLoading(false);
        });
    }

    const selectFellowship = e => {
        let fellow = e.target.value;
        getPrompts(fellow);
        setGrant(fellow);
    }

    const saveRevisedSection = () => {
        if(revisedSection.intro){
            let old_sections = revisedSections;
            let section_to_add = revisedSection;
            let nextId = 0;
            if(section_to_add.id){
                nextId = section_to_add.id;
                const sectionIndex = revisedSections.findIndex(section => section.id == nextId);
                old_sections[sectionIndex] = section_to_add;
            }else{
                if(old_sections.length === 0){
                    nextId = 1;
                    section_to_add.id = nextId;
                }else{
                    let previous_id = old_sections[old_sections.length - 1]["id"];
                    nextId = parseInt(previous_id) + 1;
                    section_to_add.id = nextId;
                }
                old_sections.push(section_to_add);
            }
            setRevisedButton({ ...revisedButton, instructions: old_sections });
            setRevisedSections(old_sections);
            setRevisedSection(defalutRevised);
            setNewInstruction(false);
        }
    }

    const addNewButton = () => {
        if(reviseButtons.length === 0){
            defaultBasic.name = buttonNames[0];
            setRevisedButton(defaultBasic);
        }else{
            defaultBasic.name = buttonNames[reviseButtons.length];
            setRevisedButton(defaultBasic);
        }
        setAddingNew(true);
    }

    const cancelButton = () => {
        setAddingNew(false);
        setSelectedButtonId(0);
    }

    const addIns = () => {
        setNewInstruction(true);
    }

    const cancelIns = () => {
        setRevisedSection(defalutRevised);
        setNewInstruction(false);
    }


    const saveButton = ({is_selected = false}) => {
        if(revisedButton.message){
            let data = revisedButton;
            data["grant_id"] = grant;
            data["prompt_id"] = prompt;
            data["institute_id"] = institute;
            data["instructions"] = revisedSections;
            data["code"] = Helpers.getCodeAsText(revisedButton, revisedSections);
            setErrors({});
            setIsLoading(true);
            axios.post(`${Helpers.apiUrl}documents/button/save`, data, Helpers.authHeaders).then(response => {
                console.log(is_selected);
                if(!is_selected){
                    Helpers.toast("success", response.data.message);
                }
                if(!selectedButtonId){
                    setAddingNew(false);
                    setRevisedButton(defaultBasic);
                    setRevisedSections([]);
                }
                setIsSaving(false);
                setRevisedButton({...revisedButton, message: ""});
                setReviseButtons(response.data.buttons);
            }).catch(error => {
                if(error.response.data){
                    setErrors(error.response.data.errors);
                    Helpers.toast("error", error.response.data.message);
                }else{
                    Helpers.toast("error", "Unexpected error occured");
                    setErrors({
                        "intro":"",
                        "guidelines":"",
                    })
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }else{
            Helpers.toast("error", "Please enter your message to save");
        }
    }

    const removeBtn = btnId => {
        axios.get(`${Helpers.apiUrl}documents/button/delete/${btnId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setAddingNew(false);
            setSelectedButtonId(0);
            getButtons();
        });
    }

    const selectButton = btn => {
        setSelectedButtonId(btn.id);
        setRevisedButton(btn);
        setRevisedSections(btn.instructions);
        setAddingNew(true);
    }

    const getVersions = () => {
        axios.get(`${Helpers.apiUrl}documents/button/versions/${selectedButtonId}`, Helpers.authHeaders).then(response => {
            setVersions(response.data.versions);
            setVersionView(true)
        });
    }

    const publishBtn = btnId => {   
        setIsPublishing(true);
        axios.get(`${Helpers.apiUrl}documents/button/publish/${btnId}`, Helpers.authHeaders).then(response => {
            getButtons();
            Helpers.toast("success", response.data.message);
        }).catch(error => {
            Helpers.toast("error", "Unexpected error occured");
        }).finally(() => {
            setIsPublishing(false);
        });
    }

    const editIns = insId => {
        const sectionToEdit = revisedSections.find(section => section.id == insId);
        setRevisedSection(sectionToEdit);
        setNewInstruction(true);
    }

    const removeIns = insId => {
        const updatedSections = revisedSections.filter(section => section.id !== insId);
        setRevisedSections(updatedSections);
    }

    const viewIns = singleIns => {
        setOpenIns(singleIns);
        setIsOpen(true);
    }

    const closeModal = () => {
        setOpenIns(null);
        setIsOpen(false);
    }

    const addNewPrompt = () => {
        if(typePrompt){
            const data = {
                "grant_id" : grant,
                "name" : typePrompt
            }
            axios.post(`${Helpers.apiUrl}categories/prompts/add`, data, Helpers.authHeaders).then(response => {
                setPrompts(response.data.prompts);
                setPrompt(response.data.prompt_id);
            }).catch(error => {
                Helpers.toast("error", "Enexpected error occured")
            })
        }
    }

    const restoreVersion = verId => {
        axios.get(`${Helpers.apiUrl}documents/button/version/restore/${verId}`, Helpers.authHeaders).then(response => {
            getButtons(response.data.button_id);
        });
    }

    useEffect(() => {
        getGrants();
        getInstitutes();
    }, []);
    
    useEffect(() => {
        getButtons();
    }, [grant, prompt, institute]);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className={selectedButtonId ? "col-md-6" : "col-md-12"} style={{ overflow: 'auto', scrollbarWidth: 'none', height: '100vh' }}>
                        <div className="card mycard" style={{ marginTop: 15, marginBottom: 50 }}>
                            <div className="card-body">
                                <h3>
                                    Section Builder Playground
                                    {(grant && prompt && institute && !isCodeView) && <button onClick={() => setIsCodeView(true)} className="btn btn-primary btn-sm fr ml-5">View Code</button>}
                                    {(grant && prompt && institute && isCodeView) && <button onClick={() => setIsCodeView(false)} className="btn btn-primary btn-sm fr ml-5">Back to Editor</button>}
                                    {!versionView && <button onClick={getVersions} className="btn btn-primary btn-sm fr">Versions</button>}
                                    {versionView && <button onClick={() => setVersionView(false)} className="btn btn-primary btn-sm fr">Editor</button>}
                                </h3>
                                {(versionView && !isCodeView) && <div className="row mt-3">
                                    <div className="col-md-12">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. #</th>
                                                    <th>Version Message</th>
                                                    <th>Date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {versions.map((ver, index) => {
                                                    return (<tr key={index}>
                                                        <td>{ index + 1 }</td>
                                                        <td>{ ver.message }</td>
                                                        <td><small><Moment date={ver.created_at} format="MMM Do YYYY, h:mm a" /></small></td>
                                                        <td>
                                                            <button onClick={() =>restoreVersion(ver.id)} className="btn btn-outline-primary btn-sm">Restore</button>
                                                        </td>
                                                    </tr>);
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                {(!isCodeView && !versionView) && <div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <h6>What Fellowship (F), Career Development Award (K), or Grant (R) are you building a prompt for?</h6>
                                            <select value={grant} disabled={grantsLoading} className="form-control" onChange={selectFellowship}>
                                                {grantsLoading ? <option>Loading...</option> : <option value={''}>Choose Fellowship / Grant / Award</option>}
                                                {grants.map((grant, index) => <option key={index} value={grant.id}>{grant.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <h6>What section of the application are you preparing a prompt for?</h6>
                                            <select className="form-control" value={prompt} disabled={promptsLoading} onChange={e => setPrompt(e.target.value)}>
                                                {promptsLoading ? <option value={''}>Loading sections </option> : <option value={''}>Choose Section </option>}
                                                {prompts.map((prompt, index) => <option key={index} value={prompt.id}>{prompt.name}</option>)}
                                                {(!promptsLoading && prompts.length > 0) && <option value={'type'}>Let me type</option>}
                                            </select>
                                        </div>
                                        {prompt === 'type' && <div className="col-md-12 mt-3">
                                            <h6>Type the section of application are you preparing a prompt for?</h6>
                                            <input className="form-control" onBlur={addNewPrompt} placeholder="Type section here.." value={typePrompt} onChange={e => setTypePrompt(e.target.value)} />
                                        </div>}
                                        <div className="col-md-12 mt-3">
                                            <h6>Which NIH Institute(s) or Center(s) are you building a prompt for?</h6>
                                            <select className="form-control" value={institute} disabled={institutesLoading} onChange={e => setInstitute(e.target.value)}>
                                                {institutesLoading ? <option value={''}>Loading institutes </option> : <option value={''}>Choose Institute </option>}
                                                {institutes.map((inst, index) => <option key={index} value={inst.id}>{inst.name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            {addingNew && <h6>Add New Sub-Section</h6>}
                                            {(grant && prompt && institute && !addingNew) && <button onClick={addNewButton} className="btn btn-primary btn-sm">Add New Sub-Section</button>}
                                        </div>
                                        {addingNew && <div className="col-md-12 mt-2">
                                            {isSaving && <div className="row">
                                                <div className="col-md-8">
                                                    <input type="text" value={revisedButton.message} onChange={e => setRevisedButton({...revisedButton, message: e.target.value})} placeholder="Enter message..." className="form-control form-control-sm" />
                                                </div>
                                                <div className="col-md-4">
                                                    <button onClick={saveButton} disabled={isLoading} className="btn btn-primary btn-sm">{ isLoading ? "Saving..." : "Save Button" }</button>
                                                    <button onClick={() => setIsSaving(false)} className="btn btn-danger btn-sm ml-5">X</button>
                                                </div>
                                            </div>}
                                            {!isSaving && <div>
                                                <button onClick={() => setIsSaving(true)} disabled={isLoading} className="btn btn-primary btn-sm">{ isLoading ? "Saving..." : "Save Button" }</button>
                                                <button onClick={cancelButton} className="btn btn-danger btn-sm ml-5">Cancel</button>
                                            </div>}
                                            {/* <button onClick={saveButton} disabled={isLoading} className="btn btn-primary btn-sm">{ isLoading ? "Saving..." : "Save Button" }</button> */}
                                        </div>}
                                    </div>
                                    {(grant && prompt && institute && reviseButtons.length === 0 && !addingNew) && <div className="row mt-3">
                                        <div className="col-12">
                                            <h5>No records found...</h5>
                                        </div>
                                    </div>}
                                    {buttonsLoading && <div className="row mt-3">
                                        <div className="col-12">
                                            <h5>Please wait. Loading buttons...</h5>
                                        </div>
                                    </div>}
                                    {reviseButtons.length > 0 && <div className="row mt-3">
                                        <div className="col-md-12">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>All Sub-Sections</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {reviseButtons.map(btn => {
                                                        return (
                                                            <tr key={btn.id}>
                                                                <td>{ btn.name }</td>
                                                                <td>
                                                                    {!(btn.is_published && btn.publish_status == 2) && <button onClick={() => selectButton(btn)} className={`btn ${ selectedButtonId == btn.id ? 'btn-primary' : 'btn-outline-primary' } btn-sm`}>{ selectedButtonId == btn.id ? 'Selected' : 'Select' }</button>}
                                                                </td>
                                                                <td>
                                                                    {(btn.is_published && btn.publish_status == 1) && <span className="badge bg-warning">In Review</span>}
                                                                    {(btn.publish_status == 3) && <span className="badge bg-danger">Rejected</span>}
                                                                    {(btn.is_published && btn.publish_status == 2) && <span className="badge bg-success">Approved</span>}
                                                                </td>
                                                                {!(btn.is_published && btn.publish_status == 2) ? <td>
                                                                    {btn.is_published ? <button className="btn btn-primary btn-sm" disabled={true}>Published</button> :
                                                                    <button onClick={() => publishBtn(btn.id)} disabled={isPublishing} className={`btn btn-primary btn-sm`}>{isPublishing ? 'Publishing...' : 'Publish'}</button>}
                                                                    <button onClick={() => removeBtn(btn.id)} className={`btn btn-danger btn-sm ml-5`}>Remove</button>
                                                                </td> : <td></td>}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                    {(grant && prompt && addingNew) && <div className="row mt-3">
                                        <div className="col-md-12">
                                            <small>Label for Sub-Section</small>
                                            <input className="form-control" value={revisedButton.name} onChange={e => setRevisedButton({...revisedButton, name: e.target.value})} placeholder="" />
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <small>Introduction Text</small>
                                            <textarea className="form-control" rows={3} value={revisedButton.intro} onChange={e => setRevisedButton({...revisedButton, intro: e.target.value})} placeholder="Introduction Text" style={{ resize: 'none' }}></textarea>
                                            <small className="text-danger">{ errors.intro ? errors.intro : '' }</small>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <small>General Guidelines</small>
                                            <textarea className="form-control" rows={3} value={revisedButton.guidelines} onChange={e => setRevisedButton({...revisedButton, guidelines: e.target.value})} placeholder="General Guidelines" style={{ resize: 'none' }}></textarea>
                                            <small className="text-danger">{ errors.guidelines ? errors.guidelines : '' }</small>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                {newInstruction ? <h6>Add New Part</h6> :
                                                <button onClick={addIns} className="btn btn-primary btn-sm">Adding New Part</button>}
                                            </div>
                                            {newInstruction && <div className="col-md-6 text-right">
                                                <button onClick={cancelIns} className="btn btn-danger btn-sm">Cancel</button>
                                            </div>}
                                        </div>
                                        {newInstruction ? <div className="col-md-12 mt-3">
                                            <h6>Parts Section</h6>
                                            <textarea className="form-control" value={revisedSection.intro} onChange={e => setRevisedSection({ ...revisedSection, intro: e.target.value })} rows={2} placeholder="Introduction for Instruction" style={{ resize: 'none' }}></textarea>
                                            <textarea className="form-control mt-3" value={revisedSection.guidelines} onChange={e => setRevisedSection({ ...revisedSection, guidelines: e.target.value })} rows={3} placeholder="Guidelines for Instruction" style={{ resize: 'none' }}></textarea>
                                            <button onClick={saveRevisedSection} className="btn btn-primary btn-sm mt-2">Save</button>
                                        </div> :
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Section</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {revisedSections.length === 0 && <tr>
                                                            <td colSpan={2}>Instruction will show here...</td>
                                                        </tr>}
                                                        <SortableList items={revisedSections} setItems={setRevisedSections}>
                                                            {({ items }) => (
                                                                <>
                                                                {items.map(single => (
                                                                    <SortableItem key={single.id} id={single.id}>
                                                                    <tr>
                                                                        <td className="three-dots" style={{ maxWidth: selectedButtonId ? 325 : 650 }}>{ single.intro }</td>
                                                                    </tr>
                                                                    </SortableItem>
                                                                ))}
                                                                </>
                                                            )}
                                                        </SortableList>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-md-4">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {revisedSections.map(single => {
                                                                return (<tr>
                                                                    <td style={{ paddingBottom: 10 }}>
                                                                        <button onClick={() => viewIns(single)} className="btn btn-primary btn-sm">
                                                                            <FeatherIcon icon={"eye"} />
                                                                        </button>
                                                                        <button onClick={() => editIns(single.id)} className="btn btn-primary btn-sm ml-5">
                                                                            <FeatherIcon icon={"edit"} />
                                                                        </button>
                                                                        <button onClick={() => removeIns(single.id)} className="btn btn-danger btn-sm ml-5">
                                                                            <FeatherIcon icon={"trash-2"} />
                                                                        </button>
                                                                    </td>
                                                                </tr>)
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="form-group md-3">
                                                <small>Advanced</small>
                                                <textarea className="form-control" rows={3} value={revisedButton.advanced} onChange={e => setRevisedButton({...revisedButton, advanced: e.target.value})} placeholder="Advanced Prompt" style={{ resize: 'none' }}></textarea>
                                            </div>
                                        </div>}
                                    </div>}
                                </div>}
                                {(isCodeView && !versionView) && <ButtonCode btnCode={Helpers.generateCode(revisedButton, revisedSections)} />}
                            </div>
                        </div>
                    </div>
                    {selectedButtonId && <div className="col-md-6">
                        <BuilderChat isSelected={true} saveButton={saveButton} btnId={selectedButtonId} fellowship={grants.find(gnt => gnt.id == grant).name} />
                    </div>}
                </div>
            </div>
            {(isOpen && openIns) && <Modal isOpen={isOpen} modalTitle={"Instruction Details"} closeModal={closeModal}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h5>Intro</h5>
                            <p>{ openIns.intro }</p>
                            <h5>Guidelines</h5>
                            <p>{ openIns.guidelines }</p>
                        </div>
                    </div>
                </div>
            </Modal>}
        </>
    );
}

export default Builder;
