import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";

const UserChatsScreen = () => {
    const [chats, setChats] = useState([]);
    const [user, setUser] = useState({
        username: '',
        first_name: '',
        last_name: ''
    });
    
    const { user_id } = useParams()

    const getChats = () => {
        axios.get(`${Helpers.apiUrl}admin/users/chats/${user_id}`, Helpers.authHeaders).then(response => {
            setChats(response.data.chats)
            setUser(response.data.user)
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
        });
    }

    useEffect(() => {
        getChats()
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card m-5 mycard">
                        <div className="card-body">
                            <h3>All Chats for { `${user.first_name} ${user.last_name} (${user.username})` }</h3>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sr. #</th>
                                        <th>Chat Title</th>
                                        <th>Date Time</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chats.map(chat => {
                                        return (
                                            <tr>
                                                <td>{ chat.id }</td>
                                                <td>
                                                    <div className="wrap-text">
                                                        { chat.title }
                                                    </div>
                                                </td>
                                                <td><Moment date={chat.created_at} format="ddd, MMM Do YYYY h:mm A" /></td>
                                                <td>
                                                    <Link to={`/admin/messages/${chat.id}`} className="btn btn-primary btn-sm">View Messages</Link>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserChatsScreen;