import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import axios from "axios";
import FeatherIcon from "feather-icons-react";

const BuilderSection = () => {
    const sectionInit = {
        grant_id: '',
        prompt_id: '',
        name: '',
    };

    const [grants, setGrants] = useState([]);
    const [grantsLoading, setGrantsLoading] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [promptsLoading, setPromptsLoading] = useState(false);
    const [typePrompt, setTypePrompt] = useState("");
    
    const [isEditing, setIsEditing] = useState(false);
    const [section, setSection] = useState(sectionInit);
    const [sections, setSections] = useState([]);
    const [sectionsLoading, setSectionsLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const getGrants = () => {
        setGrantsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/grants`, Helpers.authHeaders).then(response => {
            setGrants(response.data.grants);
            setGrantsLoading(false);
        });
    }

    const getPrompts = grantId => {
        setPromptsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/prompts/${grantId}`, Helpers.authHeaders).then(response => {
            setPrompts(response.data.prompts);
            setPromptsLoading(false);
        });
    }

    const selectFellowship = e => {
        let fellow = e.target.value;
        getPrompts(fellow);
        setSection({...section, grant_id: fellow});
    }
    
    const saveSection = () => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}workbook/section/save`, section, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getSections();
            setSection(sectionInit);
            setIsEditing(false);
            setIsLoading(false);
        }).catch(error => {
            if(error.response){
                Helpers.toast("error", error.response.data.message);
                setErrors(error.response.data.errors);
            }else{
                Helpers.toast("error", "Unexpected error!");
            }
            setIsLoading(false);
        });
    }

    const getSections = () => {
        setSectionsLoading(true);
        axios.get(`${Helpers.apiUrl}workbook/section/all`, Helpers.authHeaders).then(response => {
            let data = response.data.sections.reverse();
            setSections(data);
            setSectionsLoading(false);
        });
    }

    const editSection = sec => {
        setSection(sec);
        getPrompts(sec.grant_id);
        setIsEditing(true);
    }

    const deleteSection = section_id => {
        axios.get(`${Helpers.apiUrl}workbook/section/delete/${section_id}`, Helpers.authHeaders).then(response => {
            getSections();
            Helpers.toast("success", response.data.message);
        });
    }

    const addSection = () => {
        setIsEditing(true);
    }

    const cancelSection = () => {
        setIsEditing(false);
        setErrors({});
        setSection(sectionInit);
    }

    const addNewPrompt = () => {
        if(typePrompt){
            const data = {
                "grant_id" : section.grant_id,
                "name" : typePrompt
            }
            axios.post(`${Helpers.apiUrl}categories/prompts/add`, data, Helpers.authHeaders).then(response => {
                setPrompts(response.data.prompts);
                setSection({...section, prompt_id: response.data.prompt_id});
            }).catch(error => {
                Helpers.toast("error", "Unexpected error occured")
            })
        }
    }

    useEffect(() => {
        getGrants();
        // getSections();
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card m-2 mycard">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>{isEditing ? 'Add New Section' : 'All Sections'}</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    {!isEditing ? <button onClick={addSection} className="btn btn-primary btn-sm">Add New Section</button>
                                    : <button onClick={cancelSection} className="btn btn-danger btn-sm">Cancel</button>}
                                </div>
                            </div>
                            {isEditing ?
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <h6>What Fellowship (F), Career Development Award (K), or Grant (R) are you building a prompt for?</h6>
                                        <select value={section.grant_id} disabled={grantsLoading} className="form-control" onChange={selectFellowship}>
                                            {grantsLoading ? <option value={''}>Loading...</option> : <option value={''}>Choose Fellowship / Grant / Award</option>}
                                            {grants.map((grant, index) => <option key={index} value={grant.id}>{grant.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <h6>What section of the application are you preparing a prompt for?</h6>
                                        <select className="form-control" disabled={promptsLoading} value={section.prompt_id} onChange={e => setSection({ ...section, prompt_id: e.target.value })}>
                                            {promptsLoading ? <option value={''}>Loading sections </option> : <option value={''}>Choose Section </option>}
                                            {prompts.map((prompt, index) => <option key={index} value={prompt.id}>{prompt.name}</option>)}
                                            {(!promptsLoading && prompts.length > 0) && <option value={'type'}>Let me type</option>}
                                        </select>
                                        <small className="text-danger">{ errors.prompt_id ? errors.prompt_id : '' }</small>
                                    </div>
                                    {section.prompt_id == 'type' && <div className="col-md-12 mt-3">
                                        <h6>Type the section of application are you preparing a prompt for?</h6>
                                        <input className="form-control" onBlur={addNewPrompt} placeholder="Type section here.." value={typePrompt} onChange={e => setTypePrompt(e.target.value)} />
                                    </div>}
                                    <div className="form-group col-md-12 mt-2">
                                        <h6>Section Name</h6>
                                        <input type="text" value={section.name} className="form-control" placeholder="Enter section name" onChange={e => setSection({ ...section, name: e.target.value })} />
                                        <small className="text-danger">{ errors.name ? errors.name : '' }</small>
                                    </div>
                                    <div className="form-group col-md-12 mt-2">
                                        <button onClick={saveSection} disabled={isLoading} className="btn btn-primary btn-sm">{isLoading ? 'Saving...' : 'Save Section'}</button>
                                    </div>
                                </div> : 
                                <table className="table mt-3">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Section Name</th>
                                            <th>Grant</th>
                                            <th>Prompt</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sectionsLoading && <tr>
                                            <td colSpan={5}>Loading sections. Please wait...</td>
                                        </tr>}
                                        {sections.length === 0 && <tr><td colSpan={5}>No records found...</td></tr>}
                                        {sections.map(sec => {
                                            return (
                                                <tr>
                                                    <td>{ sec.id }</td>
                                                    <td>{ sec.name }</td>
                                                    <td>{ sec.prompt.grant.name }</td>
                                                    <td>{ sec.prompt.name }</td>
                                                    <td>
                                                        {!(sec.is_expired) && <button onClick={() => editSection(sec)} className="btn btn-primary btn-sm"><FeatherIcon icon="edit" /></button>}
                                                        {!(sec.is_expired) && <button onClick={() => deleteSection(sec.id)} className="btn btn-danger btn-sm" style={{ marginLeft: 5 }}><FeatherIcon icon="trash-2" /></button>}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuilderSection