import axios from "axios";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";

const AdditionalButtons = () => {

    const btnDefault = {
        "name": "",
        "intro": "",
        "guidelines": "",
    }

    const [isEditing, setIsEditing] = useState(false);
    const [button, setButton] = useState(btnDefault);
    const [buttons, setButtons] = useState([]);
    const [buttonsLoading, setButtonsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [codeView, setCodeView] = useState(false);

    const saveBtn = () => {
        setLoading(true);
        setErrors({});
        let data = button;
        data.code = generateCode();
        axios.post(`${Helpers.apiUrl}additional/save`, data, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setButton(btnDefault);
            getButtons();
            setIsEditing(false);
            setLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setLoading(false);
        })
    }

    const getButtons = () => {
        setButtonsLoading(true);
        axios.get(`${Helpers.apiUrl}additional/all`, Helpers.authHeaders).then(response => {
            setButtons(response.data.buttons);
            setButtonsLoading(false);
        });
    }

    const generateCode = () => {
        let code = '';
        if(button.intro){
            code += `${button.intro}\n`;
        }
        if(button.guidelines){
            code += `<Guidelines>\n`;
            code += `${button.guidelines}\n`;
            code += `</Guidelines>\n`;
        }
        return code;
    }

    const editButton = btn => {
        setButton(btn);
        setIsEditing(true);
        console.log("something, ");
    }

    const deleteButton = btnId => {
        axios.get(`${Helpers.apiUrl}additional/delete/${btnId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getButtons();
        })
    }

    useEffect(() => {
        getButtons();
    }, []);

    return(
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-12">
                    <div className="card m-3 mycard">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <h3>Additional Buttons</h3>
                                </div>
                                <div className="col-6 text-right">
                                    {(isEditing && !codeView) && <button onClick={() => setCodeView(true)} className="btn btn-primary btn-sm">View Code</button>}
                                    {(isEditing && codeView) && <button onClick={() => setCodeView(false)} className="btn btn-primary btn-sm">Back to Editor</button>}
                                    {!isEditing && <button onClick={() => setIsEditing(true)} className="btn btn-primary btn-sm"><FeatherIcon icon={'plus'} /> Add Button</button>}
                                </div>
                            </div>
                            {(isEditing && !codeView) && <div className="row mt-3">
                                <div className="col-12 form-group mb-3">
                                    <label className="form-label">Button Text</label>
                                    <input className="form-control" value={button.name} onChange={e => setButton({...button, name: e.target.value})} placeholder="Button Text" />
                                    <small className="text-danger">{ errors.name ? errors.name : '' }</small>
                                </div>
                                <div className="col-12 form-group mb-3">
                                    <label className="form-label">Intro</label>
                                    <textarea className="form-control" value={button.intro} onChange={e => setButton({...button, intro: e.target.value})} rows={2} placeholder="Introduction"></textarea>
                                    <small className="text-danger">{ errors.intro ? errors.intro : '' }</small>
                                </div>
                                <div className="col-12 form-group mb-3">
                                    <label className="form-label">Guidelines</label>
                                    <textarea className="form-control" rows={3} value={button.guidelines} onChange={e => setButton({...button, guidelines: e.target.value})} placeholder="Guidelines for button"></textarea>
                                </div>
                                <div className="col-12 form-group">
                                    <button onClick={saveBtn} disabled={loading} className="btn btn-primary btn-sm">{loading ? 'Saving...' : 'Save Button'}</button>
                                    <button onClick={() => setIsEditing(false)} className="btn btn-danger btn-sm ml-5">Cancel</button>
                                </div>
                            </div>}
                            {(isEditing && codeView) && <div className="row mt-3">
                                <div className="col-md-12 code-container">
                                    <pre>
                                        <p>{ generateCode() }</p>
                                    </pre>
                                </div>
                            </div>}
                            {!isEditing && <div className="row mt-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Intro</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {buttonsLoading && <tr><td colSpan={4}>Please wait. Loading...</td></tr>}
                                        {buttons.length === 0 && <tr><td colSpan={4}>No records found</td></tr>}
                                        {buttons.map(btn => {
                                            return (
                                                <tr key={btn.id}>
                                                    <td>{ btn.id }</td>
                                                    <td>{ btn.name }</td>
                                                    <td>{ btn.intro }</td>
                                                    <td>
                                                        <div>
                                                            <button onClick={() => editButton(btn)} className="btn btn-primary btn-sm"><FeatherIcon icon="edit" /></button>
                                                            <button onClick={() => deleteButton(btn.id)} className="btn btn-danger btn-sm ml-5"><FeatherIcon icon="trash-2" /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdditionalButtons;