import FeatherIcon from "feather-icons-react"

const StatCard = ({ columns = 3, title, value, icon }) => {
    return (
        <div className={`mb-3 col-md-${columns}`}>
            <div className="card statCard">
                <div className="row">
                    <div className="col-12">
                        <h4 className="light">{ title }</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <h1>{value ? value : 0}</h1>
                    </div>
                    <div className="col-6 text-right">
                        <FeatherIcon icon={icon} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatCard;