import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../../Config/Helper";

const SiteSettings = () => {
    const initSettings = {
        default_tokens: '',
    }
    const [settings, setSettings] = useState(initSettings);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const getSettings = () => {
        axios.get(`${Helpers.apiUrl}settings/get`, Helpers.authHeaders).then(response => {
            const site_settings = response.data.settings
            setSettings(site_settings);
        });
    }

    const saveSettings = () => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}settings/update`, settings, Helpers.authHeaders).then(response => {
            setSettings(response.data.settings);
            Helpers.toast("success", response.data.message);
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getSettings();
    }, []);

    return (
        <div className="card mycard mt-3">
            <div className="card-body">
                <h3>Settings</h3>
                <div className="row mt-3">
                    <div className="col-md-12 form-group mb-3">
                        <label>Default Tokens</label>
                        <input className="form-control" value={settings.default_tokens} onChange={e => setSettings({...settings, default_tokens: e.target.value})} placeholder="Default Tokens" />
                        <small className="text-danger">{ errors.default_tokens }</small>
                    </div>
                    <div className="col-md-12 form-group">
                        <button onClick={saveSettings} disabled={isLoading} className="btn btn-primary">{isLoading ? 'Saving...' : 'Save Settings'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SiteSettings;