import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoContainer from "../../Components/Auth/LogoContainer";
import Input from "../../Components/Input";
import axios from "axios";
import Helpers from "../../Config/Helper";
import Button from "../../Components/Button";

const ForgotVerification = () => {
    let regex = /^[0-9\b]+$/;
    let navigate = useNavigate();
    let userInit = {
        id: '',
        code:'',
    }
    const [user, setUser] = useState(userInit);
    const [userData, setUserData] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const checkRegister = () => {
        let checkUser = JSON.parse(localStorage.getItem('user'));
        if(checkUser){
            setUser({...user, id: checkUser.id});
            setUserData(checkUser);
        }else{
            navigate('/register');
        }
    }

    const handleVerify = e => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}auth/forgot-password-verification`, user).then(response => {
            Helpers.toast("success", response.data.message);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            navigate('/recover-password');
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setIsLoading(false);
        });
    }
    
    const handleResendCode = e => {
        e.preventDefault();
        setIsSending(true);
        let data = {
            id: Helpers.getItem('user', true).id,
        }
        axios.post(`${Helpers.apiUrl}auth/resend-code`, data).then(response => {
            Helpers.toast("success", response.data.message);
            setIsSending(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setIsSending(false);
        });
    }

    useEffect(() => {
        checkRegister();
    }, []);

    return (
        <div className="tyn-root">
            <div className="tyn-content tyn-auth tyn-auth-centered">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-10 col-sm-9">
                            <div className="my-3 text-center">
                                <LogoContainer />
                            </div>
                            <div className="card border-0">
                                <div className="p-4">
                                    <h3>Verify Email Address</h3>
                                    <p>Enter a 6-digit code sent to your email address ({ userData.fields ? userData.fields.email : "" }) to verify your account</p>
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <Input label={"Code"} maxLength={6} placeholder={"6-digit Code"} error={errors.code ? errors.code : ''} value={user.code} onChange={e => {
                                                if (e.target.value === '' || regex.test(e.target.value)) {
                                                    setUser({...user, code: e.target.value})
                                                }
                                            }} />
                                        </div>
                                        <div className="col-12">
                                            <Button onClick={handleVerify} isLoading={isLoading} text={"Verify Account"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <p className="small">Didn't received code? <a href="#!" onClick={isSending ? null : handleResendCode}>{isSending ? 'Sending Code...' : 'Resend'}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotVerification;