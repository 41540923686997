import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useEffect, useRef, useState } from "react";

const AskAnything = ({ getReply, message, setMessage, reviseSectionInit, reviseText, isLoading = false, tryAgain, showTryAgain, inputLimit, showCancel = false, onCancel, additional, callAdditional, showAdditional }) => {
    const textareaRef = useRef(null);
    const [showMore, setShowMore] = useState(false);

    const resizeTextarea = () => {
        if (textareaRef.current && message) {
            if(textareaRef.current.scrollHeight > 80){
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight / 3}px`;
            }
        }else{
            textareaRef.current.style.height = 'auto';
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          getReply();
        }
    };

    const [inputWords, setInputWords] = useState(0);

    const handleInputChange = e => {
        const msg = e.target.value;
        const words = msg.trim().split(/\s+/).length;
        if(words <= inputLimit){
            setMessage(msg)
            setInputWords(words);
        }
        if(msg === ""){
            setInputWords(0);
        }
        resizeTextarea();
    }

    const submitForm = e => {
        e.preventDefault();
        getReply();
    }

    useEffect(() => {
        resizeTextarea();
    }, [message]);
    
    return (
        <div className="tyn-chat-form border-0 px-4">
            <div className="container px-0">
                <div className="ps-3 pe-4 py-3 bg-white mb-5 rounded-3">
                    <form onSubmit={getReply}>
                        <div className="tyn-chat-form-enter">
                            <textarea ref={textareaRef} className="tyn-chat-form-input" rows={1} value={message} onKeyDown={handleKeyPress} onChange={handleInputChange} placeholder="Send a message" style={{ border: 'none', }}></textarea>
                            <ul className="tyn-list-inline me-n2 my-1">
                                <li>
                                    <button disabled={!message} onClick={submitForm} className="btn btn-icon btn-white btn-md btn-pill">        
                                        <FeatherIcon icon={'send'} />
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </form>
                    <div className="chat-bottom">
                        <div className="init-buttons">
                            {reviseText && <button onClick={() => reviseSectionInit()} disabled={isLoading} className="btn btn-primary btn-sm">{reviseText}</button>}
                            {showTryAgain && <button onClick={tryAgain} disabled={isLoading} className="btn btn-primary btn-sm" style={{marginLeft: 10}}>Try Again</button>}
                            {showCancel && <button onClick={onCancel} disabled={isLoading} className="btn btn-danger btn-sm" style={{marginLeft: 10}}>Cancel</button>}
                        </div>
                        {showAdditional && <div className="add-buttons">
                            {additional.length > 0 && (showMore ? additional : additional.slice(0, 2)).map((addBtn) => {
                                    return (
                                        <button key={addBtn.id} disabled={isLoading} onClick={() => callAdditional(addBtn.id)} className="btn btn-primary btn-sm ml-5">{ addBtn.name }</button>
                                    );
                                })
                            }
                            <button className="btn btn-sm ml-5" onClick={() => setShowMore(!showMore)}>
                                { showMore ? 'Show Less' : 'Show More' }
                            </button>
                        </div>}
                        <span className="f-10 word-counter">{inputWords}/{inputLimit}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AskAnything;