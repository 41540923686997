import { Link, useNavigate } from "react-router-dom";
import LogoContainer from "../../Components/Auth/LogoContainer";
import { useEffect, useState } from "react";
import Input from "../../Components/Input";
import Button from "../../Components/Button";
import axios from "axios";
import Helpers from "../../Config/Helper";
import StrengthCheck from "./StrengthCheck";

const RecoverPassword = () => {
    let navigate = useNavigate()
    let userInit = {
        new_password: '',
        confirm_password: '',
    }
    let defaultStrengthState = Helpers.checkPasswordStrength(""); 
    const [user, setUser] = useState(userInit);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [strength, setStrength] = useState(defaultStrengthState);
    
    const checkUser = () => {
        let checkUser = JSON.parse(localStorage.getItem('user'));
        if(checkUser){
            setUser({...user, id: checkUser.id});
        }else{
            navigate('/register');
        }
    }

    const handleRecoverPassword = e => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        if(user.new_password === user.confirm_password){
            axios.post(`${Helpers.apiUrl}auth/set-new-password`, user).then(response => {
                Helpers.toast("success", response.data.message);
                localStorage.clear();
                navigate('/');
                setIsLoading(false);
            }).catch(error => {
                Helpers.toast("error", error.response.data.message);
                setErrors(error.response.data.errors || {});
                setIsLoading(false);
            });
        }else{
            setErrors({
                new_password: "Password confirmation does not match",
            });
            Helpers.toast("error", "Given data was invalid");
            setIsLoading(false);
        }
    }

    const handleCheckPasword = e => {
        let value = e.target.value;
        setUser({ ...user, new_password: value });
        let checkStrength = Helpers.checkPasswordStrength(value);
        setStrength(checkStrength);
    }

    useEffect(() => {
        checkUser()
    }, []);

    return (
        <div className="tyn-root">
            <div className="tyn-content tyn-auth tyn-auth-centered">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-10 col-sm-9">
                            <div className="my-3 text-center">
                                <LogoContainer />
                            </div>
                            <div className="card border-0">
                                <div className="p-4">
                                    <h3>Set Password</h3>
                                    <p>Set a new password for your account</p>
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <Input type="password" strength={strength} label={"New Password"} error={errors.new_password ? errors.new_password : ''} value={user.new_password} onChange={handleCheckPasword} />
                                        </div>
                                        <div className="col-12">
                                            <Input type="password" label={"Confirm Password"} error={errors.confirm_password ? errors.confirm_password : ''} value={user.confirm_password} onChange={e => setUser({ ...user, confirm_password: e.target.value })} />
                                        </div>
                                        <StrengthCheck passwordStrength={strength} />
                                        <div className="col-12">
                                            <Button onClick={handleRecoverPassword} isLoading={isLoading} disabled={strength.strength < 5} text={"Update Password"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecoverPassword;