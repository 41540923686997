import StatCard from "../../../Components/StatCard";

const WriterDashboard = () => {

    return (
        <div className="container-fluid p-4">
            <div className="row">
                <StatCard title={"Total Something"} value={''} icon={"users"} columns={6} />
            </div>
        </div>
    )
}

export default WriterDashboard;