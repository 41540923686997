const Screen = ({ overflow = "hidden", screen, name, children }) => {
    if(screen === name){
        return (
            <div className="tyn-main" id="tynMain" style={{ overflow: overflow }}>
                {children}
                <div className="footer" style={{ wordWrap: "break-word" }}>
                    <p>&copy; 2023 Next Thought AI, Inc. <a href="https://grantteacherai.com/terms/" target="_blank" rel="noreferrer">Terms of Service</a>. <a href="https://grantteacherai.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>. Grant Teacher AI may display inaccurate, biased, or offensive content.</p>
                </div>
            </div>
        );
    }else{
        return null;
    }
}

export default Screen;