import FeatherIcon from 'feather-icons-react';
import Helpers from '../../Config/Helper';
import NavItem from './NavItam';
import { useNavigate } from 'react-router-dom';

const AdminSidebar = () => {

    let navigate = useNavigate()
    const handleLogout = e => {
        e.preventDefault();
        localStorage.clear();
        Helpers.toast("success", "Logged out successfully");
        navigate('/');
    }

    return (
        <div className="tyn-aside tyn-aside-base">
            <div className="tyn-aside-head">
                <div className="tyn-aside-head-text text-center">
                    <img src='/assets/images/app/logo.png' alt='' style={{ width: '70%' }} />
                </div>
            </div>
            <div className="tyn-aside-body" data-simplebar>
                <ul className="tyn-aside-list">
                    <NavItem link={'/admin/dashboard'} title={"Dashboard"} icon={'home'} />
                    <NavItem link={'/admin/users'} title={"Users"} icon={'users'} />
                    <NavItem link={'/admin/intro-texts'} title={"Introduction Texts"} icon={'sun'} />
                    <NavItem link={'/admin/intro-questions'} title={"Intro Questions"} icon={'help-circle'} />
                    <NavItem link={'/admin/additional-buttons'} title={"Additional Buttons"} icon={'box'} />
                    <NavItem link={'/admin/grant-writers'} title={"Grant Writers"} icon={'pen-tool'} />
                    <NavItem link={'/admin/revise-buttons'} title={"Revise Buttons"} icon={'code'} />
                    <NavItem link={'/admin/specific-aims'} title={"Specific Aims"} icon={'file-text'} />
                    <NavItem link={'/admin/additional-docs'} title={"Additional Docs"} icon={'file-text'} />
                    <NavItem link={'/admin/chats'} title={"Chats"} icon={'message-square'} />
                    <NavItem link={'/admin/coupons'} title={"Coupons"} icon={'percent'} />
                    <NavItem link={'/admin/settings'} title={"Settings"} icon={'settings'} />
                    <NavItem isLogout={true} />
                </ul>
            </div>
            <div className="tyn-aside-foot">
                <div className="w-100">
                    <div className='row mt-3' style={{ alignItems: 'center' }}>
                        <div className='col-10'>
                            <h6>{ Helpers.authUser.fields.username }</h6>
                            <span className='small'>{ Helpers.authUser.fields.email }</span>
                        </div>
                        <div className='col-2'>
                            <button className="tyn-media tyn-size-sm" onClick={handleLogout} style={{border:'none'}}>
                                <FeatherIcon icon="log-out" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSidebar;