import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import APIResponse from "./APIResponse";
import Helpers from "../Config/Helper";

const ChatMessage = ({ id, message = null, isBot = false, canCopy = false, setMessage, deleteMessage }) => {

    const handleCopy = () => {
        var range = document.createRange();
        range.selectNode(document.getElementById(`message-${id}`));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        Helpers.toast("success", "Copied to clipboard");
    }

    // const handleEdit = () => {
    //     setMessage(message);
    // }

    return (
        <>
        {message ? <div>
            {
                !isBot ? 
                    <div className="tyn-qa-item">
                        <div className="tyn-qa-avatar">
                            <div className="tyn-media tyn-size-md">
                                <img src="/assets/images/avatar/user-avatar.png" alt="" />
                            </div>
                        </div>
                        <div className="tyn-qa-message tyn-text-block" style={{ whiteSpace: 'pre-line' }}> { message }</div>
                    </div>
                : 
                    <div className="tyn-qa-item mt-2">
                        <div className="tyn-qa-avatar">
                            <div className="tyn-qa-avatar-wrap">
                                <div className="tyn-media tyn-size-md">
                                    <img src="/assets/images/avatar/bot-1.jpg" alt="" />
                                </div>
                                {(message && canCopy) && <ul className="d-flex flex-column mt-2">
                                    <li>
                                        <button onClick={handleCopy} className="btn btn-icon btn-md btn-pill btn-transparent">
                                            <FeatherIcon icon="clipboard" />
                                        </button>
                                    </li>
                                    {/* <li>
                                        <button onClick={handleEdit} className="btn btn-icon btn-md btn-pill btn-transparent">
                                            <FeatherIcon icon="edit" />
                                        </button>
                                    </li> */}
                                    {/* <li>
                                        <button onClick={() => deleteMessage(id)} className="btn btn-icon btn-md btn-pill btn-transparent">
                                            <FeatherIcon icon="trash-2" />
                                        </button>
                                    </li> */}
                                </ul>}
                            </div>
                        </div>
                        <div className="tyn-qa-message tyn-text-block">
                            <span id={`message-${id}`} className="copy-message">{message && <APIResponse response={message} />}</span>
                        </div>
                    </div>
            }
        </div> : null}
        </>
    )
}

export default ChatMessage;