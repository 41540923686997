import { useEffect, useState } from "react";
import Helpers from "../../Config/Helper";
import axios from "axios";

const Welcome = ({onChange, setChatId, userProfile, setUserProfile, setDocument}) => {
    
    const [fellowshipOptions, setFellowshipOptions] = useState([]);
    const [fellowshipLoading, setFellowshipLoading] = useState(false);
    const [institutes, setInstitutes] = useState([]);
    const [institutesLoading, setInstitutesLoading] = useState(false);
    const [sections, setSections] = useState([]);
    const [sectionsLoading, setSectionsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hasInfo, setHasInfo] = useState(userProfile);

    const getFellowshipOptions = () => {
        setFellowshipLoading(true);
        axios.get(`${Helpers.apiUrl}categories/grants/published`, Helpers.authHeaders).then(response => {
            setFellowshipOptions(response.data.grants);
            setFellowshipLoading(false);
        }).catch(error => {
            Helpers.toast("error", "Unexpected error occured.");
            setFellowshipLoading(false);
        })
    }

    const getInstitutes = () => {
        setInstitutesLoading(true);
        axios.get(`${Helpers.apiUrl}categories/institutes`, Helpers.authHeaders).then(response => {
            setInstitutes(response.data.institutes);
            setInstitutesLoading(false);            
        }).catch(error => {
            Helpers.toast("error", "Unexpected error occured.");
            setInstitutesLoading(false);
        })
    }

    const getSections = grantId => {
        setSectionsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/prompts/active/${grantId}`, Helpers.authHeaders).then(response => {
            setSections(response.data.prompts);
            setSectionsLoading(false);
        });
    }

    const handleGrantChange = e => {
        let grant = e.target.value;
        getSections(grant);
        setUserProfile({...userProfile, grant:grant})
    }
    
    const handleSaveProfile = () => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}auth/save-grant`, userProfile, Helpers.authHeaders).then(response => {
            setUserProfile(response.data.user.profile);
            setDocument(sections.find(section => section.id == response.data.user.profile.section).name)
            Helpers.setItem("user", response.data.user, true);
            setChatId(0);
            onChange();
        }).catch(error => {
            if(error.response){
                Helpers.toast("error", error.response.data.message);
                setErrors(error.response.data.errors);
            }else{
                Helpers.toast("error", "Unexpected error");
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getFellowshipOptions();
        getInstitutes();
        if(userProfile.section > 0){
            getSections(userProfile.grant);
        }
    }, []);

    return (
        <div className="tyn-section" style={{ overflow: 'auto' }}>
            <div className="container">
                <div className="tyn-text-block text-center pb-4 pb-lg-5">
                    <a className="tyn-logo" href="index.html">
                        <img src="/assets/images/app/logo.png" alt="" />
                    </a>
                    <h1 className="mt-3">Welcome Back</h1>
                    <p>Your AI-powered grant coach</p>
                </div>
                <div className="row">
                    {hasInfo.grant == 0 && <div className="col-md-12 mb-3">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>What Fellowship, Career Development Award, or Grant are you applying for?</label>
                                    <select className="form-control" value={userProfile.grant} disabled={fellowshipLoading} onChange={handleGrantChange}>
                                        {fellowshipLoading ? <option value={""}>Loading Options</option> : <option value={""}>Choose option</option>}
                                        {fellowshipOptions.map(option => <option value={option.id} key={option.id}>{option.name}</option>)}
                                    </select>
                                    <small className="text-danger">{errors.grant ? errors.grant : ''}</small>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>}
                    <div className="col-md-12 mb-3">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>What Section of the application would you like to work on?</label>
                                    <select className="form-control" value={userProfile.section} disabled={sectionsLoading} onChange={e => setUserProfile({...userProfile, section:e.target.value})}>
                                        {sectionsLoading ? <option value={""}>Loading options</option> : <option value={""}>Choose option</option>}
                                        {sections.map(option => <option value={option.id} key={option.id}>{option.name}</option>)}
                                    </select>
                                    <small className="text-danger">{errors.section ? errors.section : ''}</small>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>What NIH Institute or Center are you applying to?</label>
                                    <select className="form-control" value={userProfile.institute} onChange={e => setUserProfile({...userProfile, institute:e.target.value})}>
                                        <option value={""}>Choose option</option>
                                        {institutes.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
                                    </select>
                                    <small className="text-danger">{errors.institute ? errors.institute : ''}</small>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                    {(hasInfo.applying_uni == "" || !hasInfo.applying_uni) && <div className="col-md-12 mb-3">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>From which University or Institute are you applying?</label>
                                    <input type="text" className="form-control" placeholder="Applying University" value={userProfile.applying_uni} onChange={e => setUserProfile({...userProfile, applying_uni:e.target.value})} />
                                    <small className="text-danger">{errors.applying_uni ? errors.applying_uni : ''}</small>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>}
                </div>
                <div className="text-center mt-4">
                    <div className="mt-4">
                        <button disabled={isLoading} className="btn btn-primary btn-rg btn-pill" href="#!" onClick={handleSaveProfile}>
                            <span>{ isLoading ? 'Saving...' : 'Continue' }</span>
                        </button> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome;