import { Link, useNavigate } from "react-router-dom";
import LogoContainer from "../../Components/Auth/LogoContainer";
import { useState } from "react";
import axios from "axios";
import Helpers from "../../Config/Helper";
import Button from "../../Components/Button";
import Input from "../../Components/Input";

import { useGoogleLogin } from '@react-oauth/google';

const Login = () => {
    
    let userInit = {
        email: "",
        password: "",
    }

    const [user, setUser] = useState(userInit);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const hanldeLogin = e => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}auth/login`, user).then(response => {
            Helpers.toast("success", response.data.message);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            let timenow = new Date().getTime();
            localStorage.setItem('timestamp', timenow);
            window.location.href = "/";
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setIsLoading(false);
        });
    }

    const loginWithGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            setIsLoading(true);
            await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
            }).then(response => {
                let data = {
                    first_name: response.data.given_name,
                    last_name: response.data.family_name,
                    email: response.data.email,
                    sub: response.data.sub,
                }
                axios.post(`${Helpers.apiUrl}auth/login-google`, data).then(res => {
                    Helpers.toast("success", res.data.message);
                    localStorage.setItem('user', JSON.stringify(res.data.user));
                    localStorage.setItem('access_token', res.data.access_token);
                    localStorage.setItem('refresh_token', res.data.refresh_token);
                    let timenow = new Date().getTime();
                    localStorage.setItem('timestamp', timenow);
                    window.location.href = "/";
                    setIsLoading(false);
                }).catch(err => {
                    Helpers.toast("error", "Unexpected error occured");
                    setIsLoading(false);
                })
            }).catch(error => {
                console.log(error);
                Helpers.toast("error", "Unexpected error occured");
                setIsLoading(false);
            });
        },
        onError: error => {
            Helpers.toast("error", "Unexpected error occured Google OAuth");
            setIsLoading(false);
        }
    });

    return (
        <div className="tyn-root">
            <div className="tyn-content tyn-auth tyn-auth-centered">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-10 col-sm-9">
                            <LogoContainer />
                            <div className="card border-0">
                                <div className="p-4">
                                    <div className="row g-3">
                                        <div className="col-12 mb-3">
                                            <h3>You’re one click away from better grant applications.</h3>
                                        </div>
                                        <div className="col-12">
                                            <button onClick={loginWithGoogle} disabled={isLoading} className="btn w-100 btn-primary">
                                                <svg width="24" height="24" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#ffffff" d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301c1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                                                </svg>
                                                {isLoading ? 'Please wait...' : 'Sign In with your Google Account'}</button>
                                        </div>
                                        <div className="col-12 text-center">
                                            <small>OR</small>
                                        </div>
                                        <div className="col-12">
                                            <h5>Login With</h5>
                                        </div>
                                        <div className="col-12">
                                            <Input label={"Email"} error={errors.email ? errors.email : ''} value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
                                        </div>
                                        <div className="col-12">
                                            <Input type="password" label={"Password"} error={errors.password ? errors.password : ''} value={user.password} onChange={e => setUser({ ...user, password: e.target.value })} />
                                        </div>
                                        <div className="col-12">
                                            <Button onClick={hanldeLogin} isLoading={isLoading} text={"Login"} />
                                        </div>
                                        <div className="col-12 text-center">
                                            <p className="small"><Link to={'/forgot-password'}>Forgot Password?</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <p className="small">Don't have an account? <Link to={'/register'}>Register Now</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;