const Line = () => {
    const styles = {
        line:{
            border: '.1px solid #eceaea',
            margin: '10px 0px',
        }
    }
    return (
        <div style={styles.line}>

        </div>
    )
}

export default Line;