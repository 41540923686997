import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../Config/Helper";

const Questionnnaire = () => {

    const [questions, setQuestions] = useState([]);

    const getQuestions = () => {
        const prompt = Helpers.authUser.profile.section;
        if(prompt){
            axios.get(`${Helpers.apiUrl}tasks/question/get/${prompt}`, Helpers.authHeaders).then(response => {
                setQuestions(response.data.data);
            });
        }
    }

    const yesResponse = questionId => {
        let question = questions.find(q => q.id == questionId);
        let questionIndex = questions.findIndex(q => q.id == questionId);
        question.answer_status = 1;
        let updatedQuestions = [...questions];
        updatedQuestions[questionIndex] = question;
        setQuestions(updatedQuestions);
    }
    
    const noResponse = questionId => {
        let question = questions.find(q => q.id == questionId);
        let questionIndex = questions.findIndex(q => q.id == questionId);
        question.answer_status = 0;
        let updatedQuestions = [...questions];
        updatedQuestions[questionIndex] = question;
        setQuestions(updatedQuestions);
    }

    useEffect(() => {
        getQuestions()
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card m-3 mycard">
                        <div className="card-body">
                            <h3>Questionnaire</h3>
                            {questions.length === 0 && <p>No questionnaire for you...</p>}
                            {questions.map(question => {
                                return (
                                    <div className="row mt-3">
                                        <h5>{ question.question }</h5>
                                        {question.answer_status === undefined ? <div className="row">
                                            <div className="col-3">
                                                <button onClick={() => yesResponse(question.id)} className="btn btn-outline-primary btn-sm w-100">Yes</button>
                                            </div>
                                            <div className="col-3">
                                                <button onClick={() => noResponse(question.id)} className="btn btn-outline-primary btn-sm w-100">No</button>
                                            </div>
                                            <div className="col-6"></div>
                                        </div> : <div className="row">
                                            <div class="alert alert-success" role="alert">
                                                <small>{ question.answer_status == 1 ? question.yes_response : question.no_response }</small>
                                            </div>
                                            {question.answer_status == 1 && <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Task</th>
                                                        <th>Priority</th>
                                                        <th>Note</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {question.tasks.length === 0 && <tr><td colSpan={3}>No tasks</td></tr>}
                                                    {question.tasks.map((task) => {
                                                        return (
                                                            <tr key={task.id}>
                                                                <td>
                                                                    <div>
                                                                        { task.title }
                                                                        <br />
                                                                        <small>{ task.description }</small>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className={`badge bg-${ Helpers.priorityColor(task.priority) }`}>{ Helpers.priority(task.priority) }</span>
                                                                </td>
                                                                <td>{ task.note }</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>}
                                        </div>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Questionnnaire;