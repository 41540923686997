const BotTyping = () => {
    return (
        <div className="tyn-qa-item mt-2">
            <div className="tyn-qa-avatar">
                <div className="tyn-qa-avatar-wrap">
                    <div className="tyn-media tyn-size-md">
                        <img src="/assets/images/avatar/bot-1.jpg" alt="" />
                    </div>
                </div>
            </div>
            <div className="tyn-qa-message tyn-text-block">
                <img src="/assets/images/app/typing-chat.gif" alt="Typing" style={{ height: 25 }} />
            </div>
        </div>
    );
}

export default BotTyping;