import { Link } from "react-router-dom";

const LogoContainer = () => {
    return (
        <div className="my-3 text-center">
            <Link className="tyn-logo tyn-logo-sm" to="/">
                <img src="/assets/images/app/logo.png" alt="GrantTeacherAI Logo" />
            </Link>
        </div>
    );
}

export default LogoContainer;