import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../Config/Helper";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import { 
    CardNumberElement,
    CardExpiryElement, 
    CardCvcElement, 
    CardElement, 
    useStripe, 
    useElements
} from '@stripe/react-stripe-js';


const Upgrade = () => {

    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState('');

    const dataInit = {
        code: ''
    }

    const [data, setData] = useState(dataInit);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [plans, setPlans] = useState([]);
    const [selecting, setSelecting] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [isSelected, setIsSelected] = useState(false);
    const [subscribing, setSubscribing] = useState(false);
    const [showCouponPage, setShowCouponPage] = useState(false)

    const getPlans = () => {
        axios.get(`${Helpers.apiUrl}plan/all`, Helpers.authHeaders).then(response => {
            setPlans(response.data.data);
        });
    }

    const selectPlan = planId => {
        setSelecting(true);
        let data = {
            plan_id: planId,
        }
        axios.post(`${Helpers.apiUrl}plan/select`, data, Helpers.authHeaders).then(response => {
            setClientSecret(response.data.clientSecret);
            let plan = plans.find(p => p.id == planId);
            setSelectedPlan(plan);
            setIsSelected(true);
            setSelecting(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setSelecting(false);
        });
    }

    const subscribePlan = async () => {
        setSubscribing(true);
        let planToSubscribe = selectedPlan;

        const cardNumberElement = elements.getElement(CardNumberElement);
        stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
        }).then(({ paymentMethod, error }) => {
            if(error){
                Helpers.toast("error", error)
                setSubscribing(false);
            }else{
                stripe.confirmCardPayment(clientSecret, {
                    payment_method: paymentMethod.id,
                }).then(result => {
                    if(result.error){
                        Helpers.toast("error", result.error.message);
                        setSubscribing(false);
                    }else{
                        if (result.paymentIntent.status === 'succeeded') {
                            axios.post(`${Helpers.apiUrl}plan/success`, {plan_id:planToSubscribe.id}, Helpers.authHeaders).then(response => {
                                Helpers.toast("success", response.data.message);
                                localStorage.setItem('user', JSON.stringify(response.data.user));
                                setIsSelected(false);
                                setSelectedPlan({});
                                setSubscribing(false);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 2000);
                            }).catch(error => {
                                Helpers.toast("error", "Unexpected error occured");
                                setSubscribing(false);
                            });
                        }
                    }
                });
            }
        })

        // const payload = await stripe.confirmCardPayment(clientSecret, {
        //     payment_method: {
        //         card: elements.getElement(CardElement),
        //     }
        // });
        // if(payload.error){
        //     Helpers.toast("error", payload.error);
        // }else{
            
        // }
    }

    const upgradeAccount = () => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}auth/upgrade`, data, Helpers.authHeaders).then(response => {
            localStorage.setItem('user', JSON.stringify(response.data.user));
            Helpers.toast("success", response.data.message);
            setData(dataInit);
            setIsLoading(false);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getPlans();
        return () => {
            getPlans()
        };
    }, []);

    return (
        <div className="container-fluid">
            {!showCouponPage && <div className="row m-3">
                {plans.map(plan => {
                    return (
                        <div className="col-12">
                            <div className="card mycard">
                                <div className="card-header">
                                    <div className="row align-center">
                                        <div className="col-6">
                                            <h5>{ plan.name }</h5>
                                            <h1><span style={{ textDecoration: 'line-through', fontSize: 16 }}>${ plan.org_price }</span> ${ plan.sale_price }</h1>
                                        </div>
                                        <div className="col-6 text-right">
                                            <h3>Tokens: { plan.tokens }</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="mb-2">
                                        <small>{ plan.description }</small>
                                    </div>
                                    {plan.grants.map(grant => {
                                        return (
                                            <div className="mb-2">
                                                <small>
                                                    <FeatherIcon size={14} icon={'check'} /> {grant.name}
                                                </small>
                                            </div>
                                        );
                                    })}
                                    {!isSelected && <div className="row mt-3">
                                        <div className="col-12 text-center">
                                            <button onClick={() => selectPlan(plan.id)} disabled={selecting} className="btn btn-primary btn-sm">{selecting ? 'Please wait...' : 'Select & Continue'}</button>
                                            {/* <button onClick={() => setShowCouponPage(true)} className="btn btn-primary btn-sm ml-5">Have a coupon code?</button> */}
                                        </div>
                                    </div>}
                                    {isSelected && <div className="container mt-3">
                                        <div className="row">
                                            {/* <div className="col-12">
                                                <CardElement  />
                                            </div> */}
                                            <div className="col-md-12 form-group card-element">
                                                <label className="form-label">Card Number</label>
                                                <CardNumberElement />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-md-6 form-group card-element">
                                                <label className="form-label">Card Expiry</label>
                                                <CardExpiryElement />
                                            </div>
                                            <div className="col-md-6 form-group card-element">
                                                <label className="form-label">CVC</label>
                                                <CardCvcElement />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button onClick={subscribePlan} disabled={subscribing} className="btn btn-primary btn-sm">{subscribing ? 'Processing Payment...' : `Subscribe ${ selectedPlan.name }`}</button>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>}
            {showCouponPage && <div className="row">
                <div className="col-12 p-4">
                    <div className="card mycard">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <h3>Upgrade Account</h3>
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn btn-danger btn-sm" onClick={() => setShowCouponPage(false)}>Cancel</button>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 form-group mb-3">
                                    <label className="form-label">Enter Coupon Code</label>
                                    <input className="form-control" placeholder="Coupon Code" value={data.code} onChange={e => setData({...data, code: e.target.value})} />
                                    <small className="text-danger">{ errors.code ? errors.code : "" }</small>
                                </div>
                                <div className="col-12 form-group">
                                    <button onClick={upgradeAccount} disabled={isLoading} className="btn btn-primary btn-sm">{ isLoading ? 'Please wait...' : 'Upgrade Account'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {!showCouponPage && <button onClick={() => setShowCouponPage(true)} className="btn btn-primary btnCoupon">Have a coupon code?</button>}
        </div>
    );
}

export default Upgrade;