import Helpers from "../../../Config/Helper";
import Password from "./Settings/Password";
import Profile from "./Settings/Profile";
import SiteSettings from "./Settings/SiteSettings";

const SettingsScreen = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 p-4">
                    <Profile />
                    {Helpers.authUser.fields.is_superuser == 1 && <SiteSettings />}
                </div>
                <div className="col-md-6 p-4">
                    <Password />
                </div>
            </div>
        </div>
    )
}

export default SettingsScreen;