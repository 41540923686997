import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Helpers from "../../../Config/Helper";

const Workbook = () => {

    const workbookInit = {
        "name": "",
        "grant_id": "",
        "prompt_id": "",
    }

    const [fellowshipOptions, setFellowshipOptions] = useState([]);
    const [prompts, setPrompts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [sections, setSections] = useState([]);
    const [answers, setAnswers] = useState({});
    const [errors, setErrors] = useState({});
    const [workbook, setWorkbook] = useState(workbookInit);
    const [workbooks, setWorkbooks] = useState([]);
    const [addingWorkbook, setAddingWorkbook] = useState(false);
    const [hasGrantPrompt, setHasGrantPrompt] = useState(false);
    const [selectedWorkbook, setSelectedWorkbook] = useState(null);

    const getFellowshipOptions = () => {
        axios.get(`${Helpers.apiUrl}categories/grants/published`, Helpers.authHeaders).then(response => {
            setFellowshipOptions(response.data.grants);
        }).catch(error => {
            Helpers.toast("error", "Unexpected error occured.");
        })
    }

    const getAllWorkbooks = () => {
        axios.get(`${Helpers.apiUrl}workbook/all`, Helpers.authHeaders).then(response => {
            setWorkbooks(response.data.workbooks);
        });
    }

    const checkGrantPrompt = () => {
        if (workbook.grant_id && workbook.prompt_id){
            getQuestions();
            setHasGrantPrompt(true);
        }else{
            Helpers.toast("error", "Please choose grant and prompt");
        }
    }

    const cancelGrantPrompt = () => {
        setHasGrantPrompt(false);
        setQuestions([]);
        setSections([]);
    }

    const getPrompts = (grantId) => {
        axios.get(`${Helpers.apiUrl}categories/prompts/active/${grantId}`, Helpers.authHeaders).then(response => {
            setPrompts(response.data.prompts);
        });
    }

    const handleGrantChange = e => {
        let grant = e.target.value;
        getPrompts(grant);
        setWorkbook({...workbook, grant_id: grant})
    }

    const getQuestions = () => {
        let data = {
            "grant_id": workbook.grant_id,
            "prompt_id": workbook.prompt_id,
        }
        axios.post(`${Helpers.apiUrl}workbook/question/workbook`, data, Helpers.authHeaders).then(response => {
            let allquestions = response.data.questions;
            setQuestions(allquestions);
            setSections(response.data.all);
            let ans = {};
            allquestions.forEach(que => {
                ans[que.id] = ""
            });
            setAnswers(ans);
        }).catch(error => {
            Helpers.toast("error", "Unexpected error occured");
        });
    }

    const saveWorkbook = (goToBot = false) => {
        setIsLoading(true);
        setErrors({});
        let data = {
            "questions": questions,
            "answers": answers,
            "workbook": workbook,
        }
        axios.post(`${Helpers.apiUrl}workbook/save`, data, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            if(goToBot){
                window.location.href = `/user/dashboard?workbook=${response.data.workbook_id}`;
            }else{
                getAllWorkbooks();
                cancelGrantPrompt();
                setAddingWorkbook(false);
                setSelectedWorkbook(null);
                setIsLoading(false);
                setWorkbook(workbookInit);
            }
        }).catch(error => {
            if(error.response){
                Helpers.toast("error", error.response.data.message)
                setErrors(error.response.data.errors);
            }else{
                Helpers.toast("error", "Unexpected error occured")
            }
            setIsLoading(false);
        });
    }

    const setAnswer = (e, questionId) => {
        const value = e.target.value;
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: value,
        }));
    }

    const viewWorkbook = workbook_id => {
        axios.get(`${Helpers.apiUrl}workbook/single/${workbook_id}`, Helpers.authHeaders).then(response => {
            setSelectedWorkbook(response.data);
        });
    }

    const deleteWorkbook = workbook_id => {
        axios.get(`${Helpers.apiUrl}workbook/delete/${workbook_id}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getAllWorkbooks();
        });
    }

    const editWorkbook = workbook_id => {
        axios.get(`${Helpers.apiUrl}workbook/single/${workbook_id}`, Helpers.authHeaders).then(response => {
            let book = response.data.workbook;
            // let book = workbooks.find(b => b.id == workbook_id)
            setWorkbook(book);
            getPrompts(book.grant_id);
            setAddingWorkbook(true);
            setHasGrantPrompt(true);
            setSections(response.data.sections);
            setQuestions(response.data.questions);
            let ans = {};
            response.data.questions.forEach(que => {
                ans[que.id] = Helpers.findAnswer(que.id, response.data.sections);
            });
            setAnswers(ans);
        });
    }

    const cancelViewWorkbook = () => {
        setSelectedWorkbook(null);
    }

    const continueToBot = () => {
        saveWorkbook(true);
    }

    useEffect(() => {
        getAllWorkbooks();
        getFellowshipOptions();
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                {!addingWorkbook && <div className="col-12 p-4">
                    {!selectedWorkbook && <div className="card mycard">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-6">
                                    <h3>All Workbooks</h3>
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn btn-primary btn-sm" onClick={() => setAddingWorkbook(true)}>Create Workbook</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <tr>
                                    <th>ID</th>
                                    <th>Workbook Name</th>
                                    <th>Grant</th>
                                    <th>Prompt</th>
                                    <th></th>
                                </tr>
                                {workbooks.map(book => {
                                    return (
                                        <tr>
                                            <td>{ book.id }</td>
                                            <td>{ book.name }</td>
                                            <td>{ book.grant.name }</td>
                                            <td>{ book.prompt.name }</td>
                                            <td>
                                                <div>
                                                    <a className="btn btn-primary btn-sm" href={`/user/dashboard?workbook=${book.id}`}>Go to Chat Bot</a>
                                                    <button className="btn btn-primary btn-sm ml-5" onClick={() => viewWorkbook(book.id)}>View</button>
                                                    <button className="btn btn-primary btn-sm ml-5" onClick={() => editWorkbook(book.id)}>Edit</button>
                                                    <button className="btn btn-danger btn-sm ml-5" onClick={() => deleteWorkbook(book.id)}>Delete</button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </div>
                    </div>}
                    {selectedWorkbook && <div className="card mycard">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-6">
                                    <h3>{ selectedWorkbook.workbook.name }</h3>
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn btn-primary btn-sm" onClick={cancelViewWorkbook}>Back to All Workbooks</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <table className="table">
                                        <tr>
                                            <th>Grant</th>
                                            <td>{ selectedWorkbook.workbook.grant.name }</td>
                                        </tr>
                                        <tr>
                                            <th>Prompt</th>
                                            <td>{ selectedWorkbook.workbook.prompt.name }</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="col-12 mb-3">
                                    {Object.values(selectedWorkbook.sections).map(singleSection => {
                                        return (
                                            <div className="mb-3" key={singleSection.id}>
                                                <h4>{singleSection.name}</h4>
                                                {singleSection.questions.map(question => {
                                                    return (
                                                        <div className="mt-3" key={question.id}>
                                                            <p>
                                                                <small><strong>{question.question}</strong></small>
                                                            </p>
                                                            <p>
                                                                <small>{question.answer}</small>
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>}
                {addingWorkbook && <div className="col-12 p-4">
                    {!hasGrantPrompt && <div className="card mycard">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-6">
                                    <h3>Create Workbook - Select Grant & Prompt</h3>
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn btn-primary btn-sm" onClick={() => setAddingWorkbook(false)}>Back to All Workbooks</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row mt-3">
                                <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label>What Fellowship, Career Development Award, or Grant are you applying for?</label>
                                        <select className="form-control" value={workbook.grant_id} onChange={handleGrantChange}>
                                            <option value={""}>Choose option</option>
                                            {fellowshipOptions.map(option => <option value={option.id} key={option.id}>{option.name}</option>)}
                                        </select>
                                        <small className="text-danger">{errors.grant ? errors.grant : ''}</small>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label>What Section of the application are you applying for?</label>
                                        <select className="form-control" value={workbook.prompt_id} onChange={e => setWorkbook({...workbook, prompt_id:e.target.value})}>
                                            <option value={""}>Choose option</option>
                                            {prompts.map(option => <option value={option.id} key={option.id}>{option.name}</option>)}
                                        </select>
                                        <small className="text-danger">{errors.section ? errors.section : ''}</small>
                                    </div>
                                </div>
                                <div className="col-12 form-group">
                                    <button onClick={checkGrantPrompt} disabled={isLoading} className="btn btn-primary btn-sm">Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {hasGrantPrompt && <div className="card mycard">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-6">
                                    <h3>{ prompts.find(prompt => prompt.id == workbook.prompt_id) ? prompts.find(prompt => prompt.id == workbook.prompt_id).name : '' } Workbook</h3>
                                </div>
                                <div className="col-6 text-right">
                                    <button className="btn btn-primary btn-sm" onClick={saveWorkbook} disabled={isLoading}>{isLoading ? 'Saving...' : 'Save Workbook'}</button>
                                    <button onClick={cancelGrantPrompt} className="btn btn-danger btn-sm ml-5">Back to Grant & Prompt</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="form-group col-12">
                                    <small className="mb-2">Workbook Name</small>
                                    <input placeholder="Explain here..." value={workbook.name} onChange={e => setWorkbook({...workbook, name: e.target.value})} className="form-control" />
                                    <small className="text-danger">{ errors['name'] ? errors[`name`] : '' }</small>
                                </div>
                            </div>
                            {Object.values(sections).map(section => {
                                return (
                                    <div key={section.id} className="row mb-3">
                                        <div className="mb-3">
                                            <h5>{ section.name }</h5>
                                        </div>
                                        {section.questions.map(que => {
                                            return (
                                                <div className="form-group col-12 mb-3">
                                                    <small className="mb-2">{ que.question }</small>
                                                    <textarea placeholder="Explain here..." value={answers[`${que.id}`]} onChange={e => setAnswer(e, que.id)} className="form-control" rows={que.no_row}></textarea>
                                                    <small className="text-danger">{ errors[`${que.id}`] ? errors[`${que.id}`] : '' }</small>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                            <div className="row">
                                <div className="col-12">
                                    <button onClick={continueToBot} className="btn btn-primary btn-sm">Continue to Chatbot</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>}
            </div>
        </div>
    );
}

export default Workbook;