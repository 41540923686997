import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const Modal = ({ children, closeModal, isOpen, modalTitle }) => {
    return (
        isOpen ? <>
            <div className="darkBG" onClick={closeModal} />
            <div className="centered">
                <div className="modalMain">
                    <div className="modalHeader">
                        <h5 className="heading">{modalTitle}</h5>
                    </div>
                    <button className="closeBtn" onClick={closeModal}>
                        <FeatherIcon icon={'x'} />
                    </button>
                    <div className="modalContent">
                        {children}
                    </div>
                </div>
            </div>
        </> : <></>
    )
}

export default Modal;