import axios, { AxiosHeaders } from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Input from "../../../Components/Input";
import StrengthCheck from "../../Auth/StrengthCheck";
import Button from "../../../Components/Button";

const GrantWriters = () => {
    let defaultUser = {
        "first_name": "",
        "last_name": "",
        "email": "",
        "password": "",
    }

    let defaultStrengthState = Helpers.checkPasswordStrength(""); 
    const [users, setUsers] = useState([]);
    const [errors, setErrors] = useState({});
    const [user, setUser] = useState(defaultUser);
    const [strength, setStrength] = useState(defaultStrengthState);
    const [showForm, setShowForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getUsers = () => {
        axios.get(`${Helpers.apiUrl}admin/users/writers`, Helpers.authHeaders).then(response => {
            setUsers(response.data.users)
        });
    }

    const saveWriter = e => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}admin/users/save-writer`, user, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getUsers();
            setIsLoading(false);
            setShowForm(false);
            setUser(defaultUser);
        }).catch(error => {
            if(error.response){
                Helpers.toast("error", error.response.data.message)
                setErrors(error.response.data.errors)
            }
            setIsLoading(false);
        });
    }

    const cancelEditing = () => {
        setErrors({});
        setUser(defaultUser);
        setShowForm(false);
    }

    const handleCheckPasword = e => {
        let value = e.target.value;
        setUser({ ...user, password: value});
        let checkStrength = Helpers.checkPasswordStrength(value);
        setStrength(checkStrength);
    }

    const blockUser = userId => {
        axios.get(`${Helpers.apiUrl}admin/user/block/${userId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getUsers();
        });
    }
    
    const unblockUser = userId => {
        axios.get(`${Helpers.apiUrl}admin/user/unblock/${userId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getUsers();
        });
    }

    useEffect(() => {
        getUsers()
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card m-5 mycard">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>{showForm ? 'Add New Grant Writer' : 'Grant Writers'}</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    {!showForm ? <button className="btn btn-primary btn-sm" onClick={() => setShowForm(true)}>Add Grant Writer</button> :
                                    <button className="btn btn-danger btn-sm" onClick={cancelEditing}>Cancel</button>}
                                </div>
                            </div>
                            {showForm && <div className="row mt-3">
                                <div className="col-6 mb-3">
                                    <Input label={"First Name"} error={errors.first_name ? errors.first_name : ''} value={user.first_name} onChange={e => setUser({ ...user, first_name: e.target.value })} />
                                </div>
                                <div className="col-6 mb-3">
                                    <Input label={"Last Name"} error={errors.last_name ? errors.last_name : ''} value={user.last_name} onChange={e => setUser({ ...user, last_name: e.target.value })} />
                                </div>
                                <div className="col-6 mb-3">
                                    <Input label={"Email Address"} placeholder={"youremail@example.com"} error={errors.email ? errors.email : ''} value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
                                </div>
                                <div className="col-6 mb-3">
                                    <Input type="password" strength={strength} label={"Password"} error={errors.password ? errors.password : ''} value={user.password} onChange={handleCheckPasword} />
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary" onClick={saveWriter} disabled={isLoading}>{isLoading ? 'Saving writer...' : 'Save Grant Writer'}</button>
                                </div>
                            </div>}
                            {!showForm && <table className="table mt-3">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Date Joined</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => {
                                        return (
                                            <tr>
                                                <td>{ user.id }</td>
                                                <td>{ user.first_name }</td>
                                                <td>{ user.last_name }</td>
                                                <td>{ user.email }</td>
                                                <td><Moment date={user.date_joined} format="ddd, MMM Do YYYY h:mm A" /></td>
                                                <td>
                                                    {user.is_active ? 
                                                    <button onClick={() => blockUser(user.id)} className="btn btn-danger btn-sm">Block</button> :
                                                    <button onClick={() => unblockUser(user.id)} className="btn btn-success btn-sm">Unblock</button>}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GrantWriters;