import { useEffect, useState } from "react";
import Helpers from "../../../../Config/Helper";
import axios from "axios";

const Profile = () => {
    const userInit = {
        first_name:'',
        last_name:'',
        email:'',
    }
    const [user, setUser] = useState(userInit);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const saveUser = () => {
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}auth/update-profile`, user, Helpers.authHeaders).then(response => {
            localStorage.setItem('user', JSON.stringify(response.data.user));
            Helpers.toast("success", response.data.message);
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        setUser({
            first_name: Helpers.authUser.fields.first_name,
            last_name: Helpers.authUser.fields.last_name,
            email: Helpers.authUser.fields.email,
        });
    }, []);
    return (
        <div className="card mycard">
            <div className="card-body">
                <h3>Profile Info</h3>
                <div className="row mt-3">
                    <div className="col-md-12 form-group mb-3">
                        <label>First Name</label>
                        <input className="form-control" value={user.first_name} onChange={e => setUser({...user, first_name: e.target.value})} placeholder="First Name" />
                        <small className="text-danger">{ errors.first_name }</small>
                    </div>
                    <div className="col-md-12 form-group mb-3">
                        <label>Last Name</label>
                        <input className="form-control" value={user.last_name} onChange={e => setUser({...user, last_name: e.target.value})} placeholder="Last Name" />
                        <small className="text-danger">{ errors.last_name }</small>
                    </div>
                    <div className="col-md-12 form-group mb-3">
                        <label>Email</label>
                        <input className="form-control" value={user.email} onChange={e => setUser({...user, email: e.target.value})} placeholder="Email Name" />
                        <small className="text-danger">{ errors.email }</small>
                    </div>
                    <div className="col-md-12 form-group">
                        <button onClick={saveUser} disabled={isLoading} className="btn btn-primary">{isLoading ? 'Saving...' : 'Save Info'}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;