const StrengthCheck = ({ passwordStrength }) => {
    const styles = {
        light: {
            color: '#c2c2c2',
            fontSize: 10,
        },
        dark: {
            color: '#1e1e1e',
            fontSize: 12,
        },
    }
    return (
        <div className="col-12">
            <small>Your password must contain:</small>
            <div className="row">
                <div className="col-6 lh12">
                    <small style={ passwordStrength.passed.includes("uppercase") ? styles.dark : styles.light }>A Capital Character</small>
                </div>
                <div className="col-6 lh12">
                    <small style={ passwordStrength.passed.includes("lowercase") ? styles.dark : styles.light }>A small Character</small>
                </div>
                <div className="col-6 lh12">
                    <small style={ passwordStrength.passed.includes("special") ? styles.dark : styles.light }>A Special Character</small>
                </div>
                <div className="col-6 lh12">
                    <small style={ passwordStrength.passed.includes("number") ? styles.dark : styles.light }>A Number</small>
                </div>
                <div className="col-6 lh12">
                    <small style={ passwordStrength.passed.includes("length") ? styles.dark : styles.light }>Minimum Length: 8</small>
                </div>
            </div>
        </div>
    );
}

export default StrengthCheck;