import { Link, useNavigate } from "react-router-dom";
import LogoContainer from "../../Components/Auth/LogoContainer";
import { useState } from "react";
import axios from "axios";
import Helpers from "../../Config/Helper";
import Input from "../../Components/Input";
import Button from "../../Components/Button";

const ForgotPassword = () => {
    let navigate = useNavigate()
    let userInit = {
        email: '',
    }
    const [user, setUser] = useState(userInit);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const handleForgotPassword = e => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}auth/forgot-password`, user).then(response => {
            Helpers.toast("success", response.data.message);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            navigate('/forgot-password-verification');
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setIsLoading(false);
        });
    }
    return (
        <div className="tyn-root">
            <div className="tyn-content tyn-auth tyn-auth-centered">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-6 col-md-10 col-sm-9">
                            <div className="my-3 text-center">
                                <LogoContainer />
                            </div>
                            <div className="card border-0">
                                <div className="p-4">
                                    <h3>Forgot Password</h3>
                                    <p>Enter your email address and verify your email to create a new password for your account</p>
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <Input label={"Email Address"} placeholder={"youremail@example.com"} error={errors.email ? errors.email : ''} value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
                                        </div>
                                        <div className="col-12">
                                            <Button onClick={handleForgotPassword} isLoading={isLoading} text={"Send Verification Code"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <p className="small">Back to Login? <Link to={'/'}>Login</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;