import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Login from "./Screens/Auth/Login";
import Register from './Screens/Auth/Register';
import ForgotPassword from './Screens/Auth/ForgotPassword';
import VerifyAccount from './Screens/Auth/VerifyAccount';
import RecoverPassword from './Screens/Auth/RecoverPassword';
import Dashboard from './Screens/User/Dashboard';
import ForgotVerification from './Screens/Auth/ForgotVerification';
import AdminLayout from './Screens/Admin/Layout';
import AdminDashboard from './Screens/Admin/Screens/Dashboard';
import UsersScreen from './Screens/Admin/Screens/Users';

import './App.css'
import ChatsScreen from './Screens/Admin/Screens/Chats';
import MessagesScreen from './Screens/Admin/Screens/Messages';
import CouponsScreen from './Screens/Admin/Screens/Coupons';
import SettingsScreen from './Screens/Admin/Screens/Settings';
import UserChatsScreen from './Screens/Admin/Screens/UserChats';
import WriterLayout from './Screens/GrantWriter/Layout';
import WriterDashboard from './Screens/GrantWriter/Screens/Dashboard';
import GrantWriters from './Screens/Admin/Screens/GrantWriters';
import SpecificAims from './Screens/Admin/Screens/SpecificAims';
import AdditionalDocs from './Screens/Admin/Screens/AdditionalDocs';
import Builder from './Screens/GrantWriter/Screens/Builder';
import ReviseButtonsCode from './Screens/Admin/Screens/ReviseButtons';
import WorkbookBuilder from './Screens/GrantWriter/Screens/WorkbookBuilder';
import BuilderSection from './Screens/GrantWriter/Screens/BuilderSection';

import { GoogleOAuthProvider } from '@react-oauth/google';
import QuestionBuilder from './Screens/GrantWriter/Screens/QuestionBuilder';
import IntroScreen from './Screens/Admin/Screens/Intros';
import IntroQuestions from './Screens/Admin/Screens/IntroQuestions';
import Issues from './Screens/Admin/Screens/Issues';
import FeedbacksScreen from './Screens/Admin/Screens/Feedbacks';
import AdditionalButtons from './Screens/Admin/Screens/AdditionalButtons';


function Auth({children, isAdmin = false, isWriter = false}){

  let user = JSON.parse(localStorage.getItem('user'));
  let access_token = localStorage.getItem('access_token');
  let timenow = new Date().getTime();
  let loginTime = localStorage.getItem('timestamp');
  let afterLogin = ( timenow - loginTime ) / 1000;
  let totalMinutes = afterLogin / 60;
  if(user && access_token && totalMinutes > 1440){
    localStorage.clear();
    return <Navigate to='/' />
  }else{
    if(isAdmin && !isWriter){
      if(user && access_token && user.fields.is_superuser){
        return children;
      }else{
        window.location.href = '/';
      }
    }else if(!isAdmin && isWriter){
      if(user && access_token && user.fields.is_staff){
        return children;
      }else{
        window.location.href = '/';
      }
    }
    else{
      return user && access_token && !user.fields.is_staff && !user.fields.is_superuser ? children : <Navigate to='/' />;
    }
  }
}

function AfterAuth({children}){
  let user = localStorage.getItem('user');
  let token = localStorage.getItem('access_token');
  if(user && token){
    let parsed = JSON.parse(user)
    if(parsed.fields){
      if(parsed.fields.is_superuser){
        return <Navigate to='/admin/dashboard' />;
      }else if(parsed.fields.is_staff){
        return <Navigate to='/writer/dashboard' />;
      }
      else{
        return <Navigate to='/user/dashboard' />;
      }
    }else{
      return children;
    }
  }else{
    return children
  }
}

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={ <AfterAuth>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
            <Login />
          </GoogleOAuthProvider>
        </AfterAuth> } />
        <Route path="/register" element={ <AfterAuth>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
            <Register />
          </GoogleOAuthProvider>
        </AfterAuth> } />
        <Route path="/forgot-password" element={ <AfterAuth><ForgotPassword /></AfterAuth> } />
        <Route path="/forgot-password-verification" element={ <AfterAuth><ForgotVerification /></AfterAuth> } />
        <Route path="/verify-account" element={ <AfterAuth><VerifyAccount /></AfterAuth> } />
        <Route path="/recover-password" element={ <AfterAuth><RecoverPassword /></AfterAuth> } />
        <Route path="/user/:screen_name" element={ <Auth><Dashboard /></Auth> } />
        <Route path='/admin' element={ <AdminLayout /> }>
          <Route path="/admin/dashboard" element={ <Auth isAdmin={true}><AdminDashboard /></Auth> } />
          <Route path="/admin/issues" element={ <Auth isAdmin={true}><Issues /></Auth> } />
          <Route path="/admin/feedbacks" element={ <Auth isAdmin={true}><FeedbacksScreen /></Auth> } />
          <Route path="/admin/users" element={ <Auth isAdmin={true}><UsersScreen /></Auth> } />
          <Route path="/admin/intro-texts" element={ <Auth isAdmin={true}><IntroScreen /></Auth> } />
          <Route path="/admin/additional-buttons" element={ <Auth isAdmin={true}><AdditionalButtons /></Auth> } />
          <Route path="/admin/intro-questions" element={ <Auth isAdmin={true}><IntroQuestions /></Auth> } />
          <Route path="/admin/grant-writers" element={ <Auth isAdmin={true}><GrantWriters /></Auth> } />
          <Route path="/admin/revise-buttons" element={ <Auth isAdmin={true}><ReviseButtonsCode /></Auth> } />
          <Route path="/admin/specific-aims" element={ <Auth isAdmin={true}><SpecificAims /></Auth> } />
          <Route path="/admin/additional-docs" element={ <Auth isAdmin={true}><AdditionalDocs /></Auth> } />
          <Route path="/admin/chats" element={ <Auth isAdmin={true}><ChatsScreen /></Auth> } />
          <Route path="/admin/user/chats/:user_id" element={ <Auth isAdmin={true}><UserChatsScreen /></Auth> } />
          <Route path="/admin/messages/:chat_id" element={ <Auth isAdmin={true}><MessagesScreen /></Auth> } />
          <Route path="/admin/coupons" element={ <Auth isAdmin={true}><CouponsScreen /></Auth> } />
          <Route path="/admin/settings" element={ <Auth isAdmin={true}><SettingsScreen /></Auth> } />
        </Route>
        <Route path='/writer' element={ <WriterLayout /> }>
          <Route path="/writer/dashboard" element={<Auth isWriter={true}><WriterDashboard /></Auth>} />
          <Route path="/writer/builder-section" element={<Auth isWriter={true}><Builder /></Auth>} />
          <Route path="/writer/specific-aims" element={ <Auth isWriter={true}><SpecificAims /></Auth> } />
          <Route path="/writer/additional-docs" element={ <Auth isWriter={true}><AdditionalDocs /></Auth> } />
          <Route path="/writer/workbook/builder" element={<Auth isWriter={true}><WorkbookBuilder /></Auth>} />
          <Route path="/writer/workbook/sections" element={<Auth isWriter={true}><BuilderSection /></Auth>} />
          <Route path="/writer/questions/builder" element={<Auth isWriter={true}><QuestionBuilder /></Auth>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
