import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const BotMessage = ({ message, isHtml = false }) => {
    return (
        <div className="tyn-qa-item mt-2">
            <div className="tyn-qa-avatar">
                <div className="tyn-qa-avatar-wrap">
                    <div className="tyn-media tyn-size-md">
                        <img src="/assets/images/avatar/bot-1.jpg" alt="" />
                    </div>
                </div>
            </div>
            {isHtml ? <div className="tyn-qa-message tyn-text-block" style={{ background: 'white' }} dangerouslySetInnerHTML={{ __html: message }}>
            </div> : <div className="tyn-qa-message tyn-text-block">
                {message}
            </div>}
        </div>
    )
}

export default BotMessage;