import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import Moment from "react-moment";
import Input from "../../../Components/Input";
const AdditionalDocs = () => {
    let defaultDoc = {
        "title": "",
        "document": "",
    }

    const [addsDocs, setAddsDocs] = useState([]);
    const [errors, setErrors] = useState({});
    const [addDoc, setAddDoc] = useState(defaultDoc);
    const [showForm, setShowForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openDoc, setOpenDoc] = useState(null);

    const getSpecDocs = () => {
        axios.get(`${Helpers.apiUrl}documents/additional/all`, Helpers.authHeaders).then(response => {
            setAddsDocs(response.data.additionals);
        });
    }

    const saveSpecificAim = e => {
        e.preventDefault();
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}documents/additional/save`, addDoc, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getSpecDocs();
            setIsLoading(false);
            setShowForm(false);
            setAddDoc(defaultDoc);
        }).catch(error => {
            if(error.response){
                Helpers.toast("error", error.response.data.message)
                setErrors(error.response.data.errors)
            }
            setIsLoading(false);
        });
    }

    const editDoc = docId => {
        let docToEdit = addsDocs.find(doc => doc.id == docId);
        setAddDoc(docToEdit);
        setShowForm(true);
    }

    const deleteDoc = docId => {
        axios.get(`${Helpers.apiUrl}documents/additional/delete/${docId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getSpecDocs();
        });
    }

    const uploadFile = event => {
        const file = event.target.files[0];
        if(file){
            const reader = new FileReader()
            reader.onload = e => {
                const content = e.target.result;
                setAddDoc({...addDoc, document: content});
            }
            reader.readAsText(file);
        }
    }

    const cancelEditing = () => {
        setErrors({});
        setAddDoc(defaultDoc);
        setShowForm(false);
    }

    const viewDoc = docId => {
        let docToView = addsDocs.find(doc => doc.id == docId);
        setOpenDoc(docToView);
    }

    useEffect(() => {
        getSpecDocs()
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card m-5 mycard">
                        {!openDoc && <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>{showForm ? addDoc.id ? `Updating ${addDoc.title}` : 'Add New Additional Document' : 'All Additional Documents'}</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    {!showForm ? <button className="btn btn-primary btn-sm" onClick={() => setShowForm(true)}>Add New Additional Document</button> :
                                    <button className="btn btn-danger btn-sm" onClick={cancelEditing}>Cancel Editing</button>}
                                </div>
                            </div>
                            {showForm && <div className="row mt-3">
                                <div className="col-12 mb-3">
                                    <Input label={"Title"} error={errors.title ? errors.title : ''} value={addDoc.title} onChange={e => setAddDoc({ ...addDoc, title: e.target.value })} />
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">Write Document Content OR Upload Document File</label>
                                        <input type="file" onChange={uploadFile} className="ml-5" accept=".txt" />
                                        <textarea className="form-control" rows={10} placeholder="Specific Aim Document Content" value={addDoc.document} onChange={e => setAddDoc({...addDoc, document:e.target.value})}></textarea>
                                        <small className="text-danger">{ errors.document ? errors.document : '' }</small>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary" onClick={saveSpecificAim} disabled={isLoading}>{isLoading ? 'Saving document...' : 'Save Specific Aims Document'}</button>
                                </div>
                            </div>}
                            {!showForm && <table className="table mt-3">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Title</th>
                                        <th>Date Created</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {addsDocs.map(doc => {
                                        return (
                                            <tr>
                                                <td>{ doc.id }</td>
                                                <td>{ doc.title }</td>
                                                <td><Moment date={doc.created_at} format="ddd, MMM Do YYYY h:mm A" /></td>
                                                <td>
                                                    <button onClick={() => viewDoc(doc.id)} className="btn btn-success btn-sm">View</button>
                                                    <button onClick={() => editDoc(doc.id)} className="btn btn-primary btn-sm ml-5">Edit</button>
                                                    <button onClick={() => deleteDoc(doc.id)} className="btn btn-danger btn-sm ml-5">Delete</button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>}
                        </div>}
                        {openDoc && <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>{ openDoc.title }</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button onClick={() => setOpenDoc(null)} className="btn btn-primary btn-sm">Back to All Documents</button>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <p style={{ whiteSpace: 'pre-line' }}>{ openDoc.document }</p>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdditionalDocs;