const ButtonCode = ({ btnCode }) => {
    
    return (
        <div>
            <div className="row mt-3">
                <div className="col-md-12 code-container">
                    {btnCode}
                </div>
            </div>
        </div>
    )
}

export default ButtonCode;