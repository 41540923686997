import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Helpers from '../../Config/Helper';
import axios from 'axios';

const Editor = () => {

    const API_ENDPOINT = 'https://api.openai.com/v1/chat/completions';
    let defaultValue = "";
    const [value, setValue] = useState(defaultValue);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [showLabels, setShowLabels] = useState(true);
    const [sentences, setSentences] = useState([]);
    const [allSentences, setAllSentences] = useState(defaultValue);
    // const sentences = Helpers.sentenceData;
    const colors = Helpers.colors;

    const sentencesArray = [
        { id: 1, sentence: "All cells in...gene expression", colorId: 1 },
        { id: 2, sentence: "The packaging of...cellular contexts", colorId: 2 },
        { id: 3, sentence: "Chromatin architecture is determined...contacts (e.g. enhancers), and histone post-translational modifications", colorId: 2 },
        { id: 4, sentence: "Our understanding of...sequencing (ChIPSeq)", colorId: 2 },
        { id: 5, sentence: "In this technique,...that factor", colorId: 2 },
        { id: 6, sentence: "Though powerful, this...high-quality antibodies", colorId: 3 },
        { id: 7, sentence: "Our understanding of...unbiased manner", colorId: 4 },
        { id: 8, sentence: "My long-term goal...in disease", colorId: 6 },
        { id: 9, sentence: "To do so,...associated factors", colorId: 7 },
        { id: 10, sentence: "The combination of...with biotin", colorId: 2 },
        { id: 11, sentence: "Purification of the...mass spectrometry", colorId: 2 },
        { id: 12, sentence: "The Snyder lab...independent career", colorId: 13 },
        { id: 13, sentence: "In this fellowship...spectrometry proteomics", colorId: 7 },
        { id: 14, sentence: "I will develop...specific aims:", colorId: 7 },
        { id: 15, sentence: "Aim 1 (K99):...enhancer-promoter contact", colorId: 14 },
        { id: 16, sentence: "I will identify...tags (TMTs)", colorId: 16 },
        { id: 17, sentence: "I will use...if necessary", colorId: 16 },
        { id: 18, sentence: "I will test...(>300 kbp)", colorId: 15 },
        { id: 19, sentence: "Aim 2 (K99/R00):...Friederich’s Ataxia", colorId: 14 },
        { id: 20, sentence: "I will purify...trinucleotide repeats", colorId: 16 },
        { id: 21, sentence: "I will test...therapeutic targets", colorId: 15 },
        { id: 22, sentence: "I will use...if necessary", colorId: 16 },
        { id: 23, sentence: "Aim 3 (R00):...proteomics experiments", colorId: 14 },
        { id: 24, sentence: "A major limitation...input volumes", colorId: 3 },
        { id: 25, sentence: "I will reduce...sequencing strategies", colorId: 16 },
        { id: 26, sentence: "As an alternative...input volumes", colorId: 16 },
        { id: 27, sentence: "Longstanding attempts to...sufficient purity", colorId: 2 },
        { id: 28, sentence: "The modular genetic...and phenotype", colorId: 2 },
        { id: 29, sentence: "Developing the necessary...down proteomics", colorId: 7 }
      ];

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ]
    }

    const highlightSentence = (data, sentenceData, colorsData) => {
        let sentence = sentenceData.sentence;
        let color = colorsData[sentenceData.colorId];
        // if (sentenceData.color_id == colorId){
        const [start, end] = sentence.split('...');
        const initailIndex = data.indexOf(start);
        // const endIndex = data.length;
        const endIndex = data.indexOf(end) + end.length;
        // console.log(`Start:: ${start}`);
        // console.log(`End:: ${end}`);
        let extracted = data.slice(initailIndex, endIndex);
        // console.log(extracted);
        let updated = `<span style="background-color: ${color};color:${ Helpers.getTextColor(color) }">${extracted}</span>`
        let updatedString = data.replace(extracted, updated)
        return updatedString;
        // }else{
        //     return data;
        // }
    } 

    const highlightDocument = (data, sentencesData, colorsData) => {
        let document = data;
        // let document = extractFullSentences(data, sentences);
        // console.log(document, document.length);
        sentencesData.forEach((sentenceData, index) => {
            // if(index === 2){
                let updatedDocument = highlightSentence(document, sentenceData, colorsData);
                document = updatedDocument
            // }
        });

        return document;
    }

    const highlight = () => {
        // let document = extractFullSentences(value, sentencesArray);
        // console.log(document, document.length, sentencesArray);
        const highlighted = highlightDocument(value, sentences, colors);
        // const highlighted = highlightDocument(value, sentencesArray, colors);
        setValue(highlighted);
    }

    const highlightColor = (color_id) => {
        const highlighted = highlightDocument(value, sentences, colors, color_id);
        setValue(highlighted);
    }

    const handleDraftUpload = async (e) => {
        const file = e.target.files[0];
        const prompt_three_file_response = await fetch('/assets/docs/prompt_to_fill_markdown_v15.txt');
        let prompt_three = await prompt_three_file_response.text()
        // const prompt_four_file_response = await fetch('/assets/docs/prompt_three.txt');
        // let prompt_four = await prompt_four_file_response.text()
        const formData = new FormData();
        formData.append('docxFile', file);
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}editor/upload`, formData, Helpers.authFileHeaders).then(response => {
            let content = response.data.draft;
            let prompt = "\nSeparate this document into individual sentences. Number EACH sentence and place it on its own line. ";
            prompt += "\nGuidelines: ";
            prompt += "\nDo not include short sentence fragments that are clearly typos. Skip them. ";
            prompt += "\nDo NOT change the original document. If there is a typo, you MUST leave it in the output. ";
            prompt += "\nDo NOT add punctuation in the output if it is missing in the original document. ";
            prompt += "\nDo not include sentence fragments such as 'We will:' ";
            prompt += "\nIf a document begins with 'Specific Aims:', 'Specific Aims', or ' SPECIFIC AIMS' do not include those short phrases in the output. Skip them. ";
            prompt += "\nDocument:\n";
            prompt += content;
            // let prompt = content + "\nSplit this passage into sentences and number them."
            axios.post(API_ENDPOINT, {
                model: "gpt-4-0613",
                messages:[{"role": "user", "content": prompt}], 
                temperature: 0,
                n: 1,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
                }
            }).then(sentence_output => {
                const sentences = sentence_output.data.choices[0].message.content;
                let new_text = Helpers.generateMarkdownTable(sentences);
                let modified_fill_markdown_prompt = prompt_three.replace("<my draft>\ndraft\n</my draft>", `<my draft>\n${content}\n</my draft>`)
                modified_fill_markdown_prompt = modified_fill_markdown_prompt.replace("<table>\nend_output\n</table>", `<table>\n${new_text}\n</table>`)
                // console.log(modified_fill_markdown_prompt);
                // return;
                // prompt_three += "\n\nSENTENCES:\n";
                // prompt_three += new_text;
                // prompt_three += "\n\nTABLE:\n";

                axios.post(API_ENDPOINT, {
                    model: "gpt-4-0613",
                    messages:[{"role": "user", "content": prompt_three}], 
                    temperature: 0,
                    n: 1,
                }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
                    }
                }).then(end_output_response => {
                    console.log(end_output_response.data);
                    let final_data = {
                        "prompt": end_output_response.data,
                        "draft": content,
                    }
                    // const end_output = end_output_response.data.choices[0].message.content;
                    // let modified_prompt_four = prompt_four.replace("<my draft>\ndraft\n</my draft>", `<my draft>\n${content}\n</my draft>`)
                    // modified_prompt_four = modified_prompt_four.replace("<table>\nend_output\n</table>", `<table>\n${JSON.stringify(end_output_response.data)}\n</table>`)
                    axios.post(`${Helpers.apiUrl}editor/final-prompt`, final_data, Helpers.authHeaders).then(prompt_response => {
                        axios.post(API_ENDPOINT, {
                            model: "gpt-4-0613",
                            messages:[{"role": "user", "content": prompt_response.data.prompt}], 
                            temperature: 0,
                            n: 1,
                        }, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
                            }
                        }).then(final_response => {
                            // console.log("Fianl Response:::", final_response)
                            // console.log("Final Output::", final_response.data.choices[0].message.content);
                            const final_output = Helpers.parseInputString(final_response.data.choices[0].message.content);
                            setSentences(final_output);
                            setValue(content);
                            setIsLoading(false);
                            setIsUploaded(true);
                            // console.log(final_output, content);
                        }).catch(final_error => {
                            console.log("Final Error::", final_error);
                            setIsLoading(false);
                        })
                    });

                }).catch(end_output_error => {
                    console.log("Sentence Output Error:::", end_output_error);
                    setIsLoading(false);
                })

            }).catch(sentence_output_error => {
                console.log("Sentence Output Error:::", sentence_output_error)
                setIsLoading(false);
            })
            // setSentences(response.data.data);
            // setValue(response.data.content);
            // setIsLoading(false);
            // setIsUploaded(true);
        }).catch(error => {
            Helpers.toast("error", "Unexpected error")
            console.log(error);
            setIsLoading(false);
        })
        // const file = e.target.files[0];
        // const formData = new FormData();
        // formData.append('docxFile', file);
        // axios.post(`${Helpers.apiUrl}editor/upload`, formData, Helpers.authFileHeaders).then(response => {

        //     axios.post(`${Helpers.apiUrl}editor/output`, response.data, Helpers.authHeaders).then(final_output => {
        //         setSentences(final_output.data.data);
        //         setIsLoading(false);
        //         setValue(final_output.data.content);
        //         setIsUploaded(true);
        //         // console.log(response.data);
        //     }).catch(err => {
        //         console.log(err);
        //         Helpers.toast("error", "Unexpected error occured");
        //         setIsLoading(false);
        //     })
            
        // }).catch(error => {
        //     console.log(error);
        //     Helpers.toast("error", "Unexpected error occured");
        //     setIsLoading(false);
        // });
    }

    // const updateEditor = e => {
    //     let value = e.target.value;
    //     setValue(value);
    //     setOriginal(value);
    // }
    
    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-12 p-4">
                    <div className="card mycard">
                        <div className="card-body">
                            <div className='row mb-3'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <h3 className='mb-3'>GTAI Editor</h3>
                                        </div>
                                        <div className='col-6 text-right'>
                                            <input accept='.docx, .txt' onChange={handleDraftUpload} type='file' id='upload_draft' style={{ display: 'none' }} />
                                            <label for="upload_draft"><span className='btn btn-primary btn-sm'>{isLoading ? 'Uploading in progress' : 'Upload Draft Document'}</span></label>
                                        </div>
                                    </div>
                                </div>
                                {isUploaded && <div className='col-12'>
                                    <button onClick={highlight} className='btn btn-primary btn-sm'>Highlight Complete Document</button>
                                    {!showLabels  && <button onClick={() => setShowLabels(true)} className='btn btn-primary btn-sm ml-5'>Show Labels</button>}
                                    {showLabels && <button onClick={() => setShowLabels(false)} className='btn btn-primary btn-sm ml-5'>Hide Labels</button>}
                                </div>}
                                {showLabels && <div className='col-12 mt-2'>
                                    <div className='row'>
                                        {Object.keys(Helpers.color_labels).map((label, index) => {
                                            return (
                                                <div className='col-md-3 mb-2'>
                                                    <button className='btn btn-sm w-100' style={{ background: Helpers.colors[label], color: Helpers.getTextColor(Helpers.colors[label]) }}>{ Helpers.color_labels[label] }</button>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {/* <table className='table'>
                                        {Object.keys(Helpers.color_labels).map((label, index) => <tr key={index} style={{ backgroundColor: Helpers.colors[label], color: Helpers.getTextColor(Helpers.colors[label]) }}><small>{ Helpers.color_labels[label] }</small></tr>)}
                                    </table> */}
                                </div>}
                                {/* <div className='col-12 mt-2'>
                                    {Object.keys(colors).map((color, index) => (
                                        <button
                                            key={index}
                                            className='btn ml-5 mb-2'
                                            onClick={() => highlightColor(color)}
                                            style={{ backgroundColor: colors[color], color: 'black' }}
                                        >
                                            Highlight
                                        </button>
                                    ))}
                                </div> */}
                            </div>
                            <ReactQuill placeholder='Start writing...' theme="snow" className='editor' modules={modules} value={value} onChange={setValue} />
                        </div>
                    </div>
                </div>
                {/* <div className='col-12 mt-5'>
                    <p>{ value }</p>
                </div> */}
            </div>
        </div>
    );
}

export default Editor;