import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Helpers from "../../Config/Helper";
import ChatMessage from "../../Components/ChatMessage";
import BotTyping from "../../Components/BotTyping";
import ChatAdjust from "../../Components/ChatAdjust";
import AskAnything from "../../Components/AskAnything";
import BotMessage from "../../Components/BotMessage";
import FeatherIcon from "feather-icons-react";
import Modal from "../../Components/Modal";
import { useNavigate } from "react-router-dom";

const ChatScreen = ({ setTokens, getChats, setChatId, chatId, document, userProfile, workbookId }) => {

    const API_ENDPOINT = 'https://api.openai.com/v1/chat/completions';

    let navigate = useNavigate();

    const messagesEndRef = useRef(null)
    const [message, setMessage] = useState("");
    const [chat, setChat] = useState({});
    const [theory, setTheory] = useState("");
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [reviseText, setReviseText] = useState("");
    const [reviseButtons, setReviseButtons] = useState([]);
    const [reviseIndex, setReviseIndex] = useState(0);
    const [inputLimit, setInputLimit] = useState(100);
    const [showInput, setShowInput] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [workbooks, setWorkbooks] = useState([]);
    const [isRun, setIsRun] = useState(false);
    const [intro, setIntro] = useState("");
    const [additional, setAdditional] = useState([]);
    const [showAdditional, setShowAdditional] = useState(false);

    const [defaultQuestions, setDefaultQuestions] = useState([]);
    const [limits, setLimits] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showSelectOutput, setShowSelectOutput] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);

    const getAllWorkbooks = () => {
        axios.get(`${Helpers.apiUrl}workbook/all`, Helpers.authHeaders).then(response => {
            setWorkbooks(response.data.workbooks);
            if(response.data.workbooks.length > 0){
                setShowInput(false);
            }
        });
    }

    const getAdditional = () => {
        axios.get(`${Helpers.apiUrl}additional/all`, Helpers.authHeaders).then(response => {
            setAdditional(response.data.buttons);
        })
    }

    const getReviseButtons = () => {
        axios.get(`${Helpers.apiUrl}documents/button/all`, Helpers.authHeaders).then(response => {
            setReviseButtons(response.data.buttons);
        }).catch(error => {
            Helpers.toast("error", "Unexpected error occured");
        });
    }

    const getMessages = () => {
        if(chatId){
            axios.get(`${Helpers.apiUrl}bot/get-messages/${chatId}`, Helpers.authHeaders).then(response => {
                setMessages(response.data.messages);
                setChat(response.data.chat);
                updateReviseText(response.data.chat.revise_question);
                setShowAdditional(true);
                setTimeout(() => {
                    scrollToBottom();
                }, 500);
            }).catch(error => {
                navigate('/user/dashboard');
            })
        }
    }

    const updateReviseText = (index, chat_id = null) => {
        let cId = 0;
        if (chat_id){
            cId = chat_id;
        }else{
            cId = chatId;
        }
        if(cId){
            setReviseIndex(index);
            let button = reviseButtons[index];
            if(button){
                setReviseText(`Revise Section ${button.name}`);
            }else{
                setReviseText('');
            }
        }
        else{
            setReviseText('');
        }
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", top: messagesEndRef.current.scrollHeight, block:"end" })
    }

    const callAdditional = btnId => {
        setIsLoading(true);
        let data = {
            btn_id: btnId,
            chat_id: chatId,
        }
        setTimeout(() => {
            scrollToBottom();
        }, 500);
        axios.post(`${Helpers.apiUrl}bot/revise-additional-init`, data, Helpers.authHeaders).then(async response => {
            let gpt_response = await gptResponse(response.data.previous_messages, 1, 1);
            if(gpt_response){
                let msg_response = gpt_response.data.choices[0].message.content;
                data.msg_response = msg_response;
                axios.post(`${Helpers.apiUrl}bot/revise-additional`, data, Helpers.authHeaders).then(res => {
                    setMessages(res.data.messages);
                    setChat(res.data.chat);
                    updateReviseText(res.data.chat.revise_question);
                    let user = Helpers.authUser;
                    user.profile.tokens = res.data.tokens
                    localStorage.setItem('user', JSON.stringify(user))
                    setTokens(res.data.tokens);
                    setIsLoading(false);
                    setTimeout(() => {
                        scrollToBottom();
                    }, 500);
                }).catch(error => {
                    if(error.response){
                        Helpers.toast("error", error.response.data.message);
                    }
                    setIsLoading(false);
                })
            }else{
                alert("Error");
                Helpers.toast("error", "Server overloaded. We are waiting a few moments and trying again automatically.");
                callAdditional();
            }
        }).catch(error => {
            if(error.response){
                Helpers.toast("error", error.response.data.message);
            }
            setIsLoading(false);
        });
    }

    const gptResponse = async (messages, temperature, n) => {
        let res = "";
        await axios.post(API_ENDPOINT, {
            model: "gpt-4-0613",
            messages:messages, 
            temperature: temperature,
            n: n,
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
            }
        }).then(response => {
            res = response;
        }).catch(error => {
            res = "error";
        });

        return res;
    }

    const reviseSectionInit = (chat_id = null, try_again = false) => {
        setIsLoading(true);
        let button_id = reviseButtons[reviseIndex].id
        let data = {
            chat_id: chat_id ? chat_id : chatId,
            button_id: button_id,
            try_again:try_again
        }
        setTimeout(() => {
            scrollToBottom();
        }, 500);
        axios.post(`${Helpers.apiUrl}bot/revise-section-init`, data, Helpers.authHeaders).then(async res => {
            let previous_messages = res.data.previous_messages;
            let no_res = res.data.no_res;
            let response = await gptResponse(previous_messages, 1, no_res);
            if(response){
                data.response = response.data.choices;
                axios.post(`${Helpers.apiUrl}bot/revise-section-final`, data, Helpers.authHeaders).then(final_res => {
                    setChatId(final_res.data.chat_id);
                    setMessages(final_res.data.messages);
                    setChat(final_res.data.chat);
                    updateReviseText(final_res.data.chat.revise_question, final_res.data.chat_id);
                    let user = Helpers.authUser;
                    user.profile.tokens = final_res.data.tokens
                    localStorage.setItem('user', JSON.stringify(user))
                    setTokens(final_res.data.tokens);
                    setIsLoading(false);
                    if(no_res > 1){
                        setShowSelectOutput(true);
                    }
                    setTimeout(() => {
                        scrollToBottom();
                    }, 500);
                }).catch(error => {
                    Helpers.toast("error", error.response.data.message);
                    setIsLoading(false);
                })
            }else{
                Helpers.toast("error", "Server overloaded. We are waiting a few moments and trying again automatically.");
                reviseSectionInit();
            }
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setIsLoading(false);
        })
    }

    const afterResponse = response => {
        getChats();
        let new_messages = response.data.messages;
        setMessages(new_messages);
        setChatId(response.data.chat_id);
        setMessage("");
        setCurrentQuestion(currentQuestion + 1);
        let user = Helpers.authUser;
        user.profile.tokens = response.data.tokens
        localStorage.setItem('user', JSON.stringify(user))
        setTokens(response.data.tokens);
        setIsLoading(false);
        scrollToBottom();
        setTimeout(() => {
            scrollToBottom();
        }, 500);
        if(reviseIndex === 0){
            reviseSectionInit(response.data.chat_id);
        }
    }

    const getReply = () => {
        // e.preventDefault();
        if(chatId === 0 && currentQuestion === 0){
            setTheory(message);
            addReply();
            addMessage();
            setMessage("");
            setCurrentQuestion(currentQuestion + 1);
            setInputLimit(limits[currentQuestion + 1]);
            setTimeout(() => {
                scrollToBottom();
            }, 500);
        }else if(chatId === 0 && currentQuestion > 0 && currentQuestion < defaultQuestions.length - 1){
            addReply();
            addMessage();
            setMessage("");
            setCurrentQuestion(currentQuestion + 1);
            setInputLimit(limits[currentQuestion + 1]);
            setTimeout(() => {
                scrollToBottom();
            }, 500);
        }
        else{
            setIsLoading(true);
            setTimeout(() => {
                scrollToBottom();
            }, 500);
            if(currentQuestion === defaultQuestions.length - 1){
                addReply();
                addMessage();
            }
            let data = { message };
            if(currentQuestion === defaultQuestions.length - 1){
                data.theory = theory;
                setTheory("");
            }
            if(chatId){
                data.chat_id = chatId
            }else{
                data.messages = messages;
            }
            setInputLimit(100);
            axios.post(`${Helpers.apiUrl}bot/get-reply`, data, Helpers.authHeaders).then(async response => {
                if (response.data.code === 'gpt'){
                    let gpt_response = await gptResponse(response.data.previous_messages, 1, 1);
                    if(gpt_response){
                        data.botReply = gpt_response.data.choices[0].message.content;
                        axios.post(`${Helpers.apiUrl}bot/get-reply-final`, data, Helpers.authHeaders).then(res => {
                            afterResponse(res);
                        }).catch(error => {
                            Helpers.toast("error",error.response.data.message);
                            setIsLoading(false);
                        })
                    }else{
                        Helpers.toast("error", "Server overloaded. We are waiting a few moments and trying again automatically.");
                        tryAgain();
                    }
                }else{
                    afterResponse(response)
                }
            }).catch(error => {
                Helpers.toast("errors", error.response.data.message)
                setIsLoading(false);
                if(error.response.data.code === 'retry'){
                    getReply();
                }
            })
        }
    }

    

    const tryAgain = () => {
        setIsLoading(true);
        setTimeout(() => {
            scrollToBottom();
        }, 500);
        let data = {
            message: "Try Again",
            chat_id: chatId,
        }
        axios.post(`${Helpers.apiUrl}bot/try-again-init`, data, Helpers.authHeaders).then(async response => {
            let previous_messages = response.data.previous_messages;
            let botReply = await gptResponse(previous_messages, 1, 1);
            if(botReply){
                data.botReply = botReply.data.choices[0].message.content;
                axios.post(`${Helpers.apiUrl}bot/try-again`, data, Helpers.authHeaders).then(res => {
                    getChats();
                    let new_messages = res.data.messages;
                    setMessages(new_messages);
                    setChatId(res.data.chat_id);
                    setMessage("");
                    let user = Helpers.authUser;
                    user.profile.tokens = res.data.tokens
                    localStorage.setItem('user', JSON.stringify(user))
                    setTokens(res.data.tokens);
                    setIsLoading(false);
                    scrollToBottom();
                    setTimeout(() => {
                        scrollToBottom();
                    }, 500);
                }).catch(error => {
                    Helpers.toast("errors", error.response.data.message);    
                    setIsLoading(false);
                })
            }else{
                Helpers.toast("error", "Server overloaded. We are waiting a few moments and trying again automatically.");
                tryAgain();
            }
        }).catch(error => {
            Helpers.toast("errors", error.response.data.message);
            setIsLoading(false);
        })
    }

    const deleteMessage = messageId => {
        // setIsLoading(true);
        axios.get(`${Helpers.apiUrl}bot/delete-message/${messageId}/${chatId}`, Helpers.authHeaders).then(response => {
            setMessages(response.data.messages);
            setChat(response.data.chat);
            updateReviseText(response.data.chat.revise_question);
            setTimeout(() => {
                scrollToBottom();
            }, 500);
        });
    }

    const addMessage = (mymessage = "", is_hidden = false, is_included = true) => {
        let msg = {
            message: mymessage ? mymessage : message,
            is_bot: false,
            is_included: is_included,
            is_hidden: is_hidden,
            is_liked: false,
            is_disliked: false,
            id: Helpers.genRand(8),
            user_id: Helpers.authUser.id,
            chat_id: chatId,
        }
        let new_messages = messages;
        new_messages.push(msg);
        setMessages(new_messages);
        setTimeout(() => {
            scrollToBottom();
        }, 500);
    }
    
    const addReply = (message = "") => {
        let msg = {
            message: message ? message : defaultQuestions[currentQuestion],
            is_bot: true,
            is_included: false,
            is_hidden: false,
            is_liked: false,
            is_disliked: false,
            id: Helpers.genRand(8),
            user_id: Helpers.authUser.id,
            chat_id: chatId,
        }
        let new_messages = messages;
        new_messages.push(msg);
        setMessages(new_messages);
        setTimeout(() => {
            scrollToBottom();
        }, 500);
    }

    const writeOwn = () => {
        setShowInput(true);
    }

    const handleUploadedFile = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileName = file.name;
            const fileType = file.type;
            if (fileType === 'text/plain' || fileName.endsWith('.txt')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const content = e.target.result;
                    setTheory(content);
                    addReply();
                    addMessage(content);
                    setMessage("");
                    setCurrentQuestion(currentQuestion + 1);
                    setInputLimit(1500);
                    setTimeout(() => {
                        scrollToBottom();
                    }, 500);
                };
                reader.readAsText(file);
                setShowInput(true);

            } 
            else {
                setMessage('');
                Helpers.toast("error", "Unsupported file format. Only .txt and .docx files are supported.")
            }
        }
    }

    const openModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const chooseWorkbook = workbook_id => {
        axios.get(`${Helpers.apiUrl}workbook/single/${workbook_id}`, Helpers.authHeaders).then(response => {
            let workbooks_sections = response.data.sections;
            let msg = Helpers.workbook_message(workbooks_sections);
            let code_msg = Helpers.workbook_code(workbooks_sections)
            setTheory(msg);
            addReply();
            addMessage(msg, false, false);
            addMessage(code_msg, true);
            addMessage("");
            setCurrentQuestion(currentQuestion + 1);
            setInputLimit(1500);
            setTimeout(() => {
                scrollToBottom();
            }, 500);
            setShowInput(true);
            closeModal();
        });
    }

    const getIntroText = () => {
        axios.get(`${Helpers.apiUrl}intro/prompt`, Helpers.authHeaders).then(response => {
            if(response.data.data){
                setIntro(response.data.data.intro_text);
            }
        });
    }
    
    const getIntroQuestions = () => {
        axios.get(`${Helpers.apiUrl}intro/question/prompt`, Helpers.authHeaders).then(response => {
            setDefaultQuestions(response.data.questions);
            setLimits(response.data.limits);
            setInputLimit(response.data.limits[0])
        });
    }

    const updateLastOutput = (message_id) => {
        setIsSelecting(true);
        axios.get(`${Helpers.apiUrl}bot/delete-message/${message_id}/${chatId}`, Helpers.authHeaders).then(response => {
            setMessages(response.data.messages);
            setChat(response.data.chat);
            setIsSelecting(false);
            setShowSelectOutput(false);
            setTimeout(() => {
                scrollToBottom();
            }, 500);
        });
    }

    useEffect(() => {
        getMessages();
        if(chatId === 0){
            setInputLimit(limits[currentQuestion]);
        }
    }, [chatId]);

    useEffect(() => {
        getIntroText();
        getIntroQuestions();
        getAdditional();
        if(!isRun){
            if(workbookId){
                chooseWorkbook(workbookId);
            }
            getAllWorkbooks();
            getReviseButtons();
        }
        setIsRun(true);
    }, []);

    return (
        <>
            <ChatAdjust/>
            <div className="tyn-chat-body my-4 px-4" data-simplebar>
                <div className="container px-0">
                    <div className="tyn-qa tyn-qa-bubbly">
                        {intro && <BotMessage message={intro} isHtml={true} />}
                        {
                            messages.map(message => {
                                return (
                                    !message.is_hidden && <ChatMessage key={message.id} deleteMessage={deleteMessage} setMessage={setMessage} id={message.id} message={message.message} isBot={message.is_bot} canCopy={message.can_copy} />
                                );
                            })
                        }
                        {chatId === 0 && currentQuestion < 4 && <BotMessage message={defaultQuestions[currentQuestion]} />}
                        {isLoading && <BotTyping />}
                    </div>
                    <div id="something" ref={messagesEndRef} />
                </div>
            </div>
            {(!showInput && !showSelectOutput) && <div className="row m-5">
                <div className="col-md-6">
                    <span>Just getting started?</span>
                    <button onClick={openModal} className="btn btn-primary w-100 mt-2"><FeatherIcon icon={'check-square'} /> Choose From Workbooks</button>
                </div>
                {/* <div className="col-md-4">
                    <label for="uploadFromDevice" style={{ width: '100%' }}>
                        <span className="btn btn-primary w-100" style={{ width: '100% !important' }}><FeatherIcon icon={'upload'} /> Upload From Your Device</span>
                    </label>
                    <input onChange={handleUploadedFile} accept=".txt" type="file" id="uploadFromDevice" style={{ display: 'none' }} />
                </div> */}
                <div className="col-md-6">
                    <span>Already have a draft?</span>
                    <button onClick={writeOwn} className="btn btn-primary w-100 mt-2"><FeatherIcon icon={'edit-2'} />Write Your Own</button>
                </div>
            </div>}
            {showSelectOutput && <div className="tyn-chat-form border-0 px-4">
                <div className="container px-0">
                    <div className="ps-3 pe-4 py-3 bg-white mb-5 rounded-3">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h6>Choose your preferred output, or press Try Again. You may also press the edit button to make changes to an output. Then, proceed by pressing the next Revise button</h6>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 text-center">
                                <button onClick={() => updateLastOutput(messages[messages.length - 1].id)} disabled={isSelecting || isLoading} className="btn btn-primary btn-sm">Output 1</button>
                                <button onClick={() => updateLastOutput(messages[messages.length - 2].id)} disabled={isSelecting || isLoading} className="btn btn-primary btn-sm ml-5">Output 2</button>
                            </div>
                            {/* <div className="col-6 text-right">
                                <button onClick={() => reviseSectionInit(null, true)} disabled={isSelecting || isLoading} className="btn btn-primary btn-sm">{isLoading ? 'Revising Section Again...' : 'Try Again'}</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>}
            {(showInput && !showSelectOutput) && <AskAnything showAdditional={showAdditional} callAdditional={callAdditional} additional={additional} inputLimit={inputLimit} isLoading={isLoading} tryAgain={tryAgain} showTryAgain={chatId ? true : false} reviseSectionInit={reviseSectionInit} reviseText={reviseText} setMessage={setMessage} getReply={getReply} message={message} />}
            <Modal isOpen={isModalOpen} closeModal={closeModal} modalTitle={"Choose Workbook"}>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table">
                            <tr>
                                <th>ID</th>
                                <th>Workbook Name</th>
                                <th>Prompt</th>
                                <th></th>
                            </tr>
                            {workbooks.map(book => {
                                return (
                                    <tr key={book.id}>
                                        <td>{ book.id }</td>
                                        <td>{ book.name }</td>
                                        <td>{ book.prompt.name }</td>
                                        <td>
                                            <div>
                                                <button className="btn btn-primary btn-sm" onClick={() => chooseWorkbook(book.id)}>Choose</button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ChatScreen;