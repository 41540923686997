import { useEffect, useState } from "react";
import Helpers from "../../Config/Helper";
import axios from "axios";

const InstituteScreen = ({userProfile, setUserProfile}) => {

    const [fellowshipOptions, setFellowshipOptions] = useState([]);
    const [institutes, setInstitutes] = useState([]);
    const [sections, setSections] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    
    const getFellowshipOptions = () => {
        axios.get(`${Helpers.apiUrl}categories/grants/published`, Helpers.authHeaders).then(response => {
            setFellowshipOptions(response.data.grants);
        }).catch(error => {
            Helpers.toast("error", "Unexpected error occured.");
        })
    }

    const getInstitutes = () => {
        axios.get(`${Helpers.apiUrl}categories/institutes`, Helpers.authHeaders).then(response => {
            setInstitutes(response.data.institutes);
        }).catch(error => {
            Helpers.toast("error", "Unexpected error occured.");
        })
    }

    const getSections = grantId => {
        axios.get(`${Helpers.apiUrl}categories/prompts/active/${grantId}`, Helpers.authHeaders).then(response => {
            setSections(response.data.prompts);
        });
    }

    const handleGrantChange = e => {
        let grant = e.target.value;
        getSections(grant);
        setUserProfile({...userProfile, grant:grant})
    }
    
    const handleSaveProfile = () => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}auth/save-grant`, userProfile, Helpers.authHeaders).then(response => {
            setUserProfile(response.data.user.profile);
            Helpers.toast("success", response.data.message);
            Helpers.setItem("user", response.data.user, true);
        }).catch(error => {
            if(error.response){
                Helpers.toast("error", error.response.data.message);
                setErrors(error.response.data.errors);
            }else{
                Helpers.toast("error", "Unexpected error");
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getFellowshipOptions();
        getInstitutes();
        if(userProfile.section > 0){
            getSections(userProfile.grant);
        }
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 p-4">
                    <div className="card mycard">
                        <div className="card-body">
                            <h3>Grant & Institutes</h3>
                            <div className="row mt-3">
                                <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label>What Fellowship, Career Development Award, or Grant are you applying for?</label>
                                        <select className="form-control" value={userProfile.grant} onChange={handleGrantChange}>
                                            <option value={""}>Choose option</option>
                                            {fellowshipOptions.map(option => <option value={option.id} key={option.id}>{option.name}</option>)}
                                        </select>
                                        <small className="text-danger">{errors.grant ? errors.grant : ''}</small>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label>What Section of the application are you applying for?</label>
                                        <select className="form-control" value={userProfile.section} onChange={e => setUserProfile({...userProfile, section:e.target.value})}>
                                            <option value={""}>Choose option</option>
                                            {sections.map(option => <option value={option.id} key={option.id}>{option.name}</option>)}
                                        </select>
                                        <small className="text-danger">{errors.section ? errors.section : ''}</small>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label>What NIH Institute or Center are you applying to?</label>
                                        <select className="form-control" value={userProfile.institute} onChange={e => setUserProfile({...userProfile, institute:e.target.value})}>
                                            <option value={""}>Choose option</option>
                                            {institutes.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
                                        </select>
                                        <small className="text-danger">{errors.institute ? errors.institute : ''}</small>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label>From which University or Institute are you applying?</label>
                                        <input type="text" className="form-control" placeholder="Applying University" value={userProfile.applying_uni} onChange={e => setUserProfile({...userProfile, applying_uni:e.target.value})} />
                                        <small className="text-danger">{errors.applying_uni ? errors.applying_uni : ''}</small>
                                    </div>
                                </div>
                                <div className="col-12 form-group">
                                    <button onClick={handleSaveProfile} disabled={isLoading} className="btn btn-primary">{ isLoading ? 'Please wait...' : 'Save Info'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstituteScreen;