import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import Moment from "react-moment";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

const CouponsScreen = () => {
    const couponInit = {
        code: '',
        limit: '',
        expire_on: '',
    };
    
    const [isEditing, setIsEditing] = useState(false);
    const [coupon, setCoupon] = useState(couponInit);
    const [coupons, setCoupons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    
    const saveCoupon = () => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}admin/user/coupon/save`, coupon, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getCoupons();
            setCoupon(couponInit);
            setIsEditing(false);
            setIsLoading(false);
        }).catch(error => {
            if(error.response){
                Helpers.toast("error", error.response.data.message);
                setErrors(error.response.data.errors);
            }else{
                Helpers.toast("error", "Unexpected error!");
            }
            setIsLoading(false);
        });
    }

    const getCoupons = () => {
        axios.get(`${Helpers.apiUrl}admin/user/coupon/all`, Helpers.authHeaders).then(response => {
            let data = response.data.coupons.reverse();
            setCoupons(data);
        });
    }

    const editCoupon = cpn => {
        let coupon = cpn;
        coupon.expire_on = new Date(coupon.expire_on).toISOString().substring(0, 10);
        setCoupon(coupon);
        setIsEditing(true);
    }

    const deleteCoupon = coupon_id => {
        axios.get(`${Helpers.apiUrl}admin/user/coupon/delete/${coupon_id}`, Helpers.authHeaders).then(response => {
            getCoupons();
            Helpers.toast("success", response.data.message);
        });
    }

    const addCoupon = () => {
        setIsEditing(true);
    }

    const cancelCoupon = () => {
        setIsEditing(false);
    }

    useEffect(() => {
        getCoupons();
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card m-5 mycard">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3>{isEditing ? 'Add New Coupon' : 'All Coupons'}</h3>
                                </div>
                                <div className="col-md-6 text-right">
                                    {!isEditing ? <button onClick={addCoupon} className="btn btn-primary btn-sm">Add New Coupon</button>
                                    : <button onClick={cancelCoupon} className="btn btn-danger btn-sm">Cancel</button>}
                                </div>
                            </div>
                            {isEditing ?
                                <div className="row mt-3">
                                    <div className="form-group col-md-12 mb-3">
                                        <label>Coupon Code</label>
                                        <input type="text" value={coupon.code} className="form-control" placeholder="Coupon Code" onChange={e => setCoupon({ ...coupon, code: e.target.value })} />
                                        <small className="text-danger">{ errors.code ? errors.code : '' }</small>
                                    </div>
                                    <div className="form-group col-md-6 mb-3">
                                        <label>No. of Tokens (Limit)</label>
                                        <input type="text" value={coupon.limit} className="form-control" placeholder="No. of Tokens" onChange={e => setCoupon({ ...coupon, limit: e.target.value })} />
                                        <small className="text-danger">{ errors.limit ? errors.limit : '' }</small>
                                    </div>
                                    <div className="form-group col-md-6 mb-3">
                                        <label>Expiry Date</label>
                                        <input type="date" value={coupon.expire_on} className="form-control" onChange={e => setCoupon({ ...coupon, expire_on: e.target.value })} />
                                        <small className="text-danger">{ errors.expire_on ? errors.expire_on : '' }</small>
                                    </div>
                                    <div className="form-group col-md-12 mb-3">
                                        <button onClick={saveCoupon} disabled={isLoading} className="btn btn-primary">{isLoading ? 'Saving...' : 'Save Coupon'}</button>
                                    </div>
                                </div> : 
                                <table className="table mt-3">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Code</th>
                                            <th>Limit</th>
                                            <th>Created On</th>
                                            <th>Status</th>
                                            <th>Used By</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {coupons.map(cpn => {
                                            return (
                                                <tr>
                                                    <td>{ cpn.id }</td>
                                                    <td>{ cpn.code }</td>
                                                    <td>{ cpn.limit }</td>
                                                    <td><Moment date={cpn.created_at} format="ddd, MMM Do YYYY" /></td>
                                                    <td>
                                                        {cpn.is_expired ? 
                                                        <span className="badge bg-danger">Expired</span> : 
                                                        <span className="badge bg-success">Active</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        {(cpn.used_by > 0) && <span>{ `${cpn.user.first_name} ${cpn.user.last_name} (${cpn.user.username})` }</span>}
                                                    </td>
                                                    <td>
                                                        {!(cpn.is_expired) && <button onClick={() => editCoupon(cpn)} className="btn btn-primary btn-sm"><FeatherIcon icon="edit" /></button>}
                                                        {!(cpn.is_expired) && <button onClick={() => deleteCoupon(cpn.id)} className="btn btn-danger btn-sm" style={{ marginLeft: 5 }}><FeatherIcon icon="trash-2" /></button>}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CouponsScreen;