import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import Moment from "react-moment";

const FeedbacksScreen = () => {

    const [feedbacks, setFeedbacks] = useState([]);

    const getFeedbacks = () => {
        axios.get(`${Helpers.apiUrl}issues/feedback/all`, Helpers.authHeaders).then(response => {
            setFeedbacks(response.data.feedbacks);
        })
    }

    useEffect(() => {
        getFeedbacks()
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card m-3 mycard">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>Feedbacks</h3>
                                </div>
                            </div>
                            <table className="table mt-3">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Feedback</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {feedbacks.map(feedback => {
                                        return (
                                            <tr>
                                                <td>{ feedback.id }</td>
                                                <td>{ `${feedback.user.first_name} ${feedback.user.last_name}` }</td>
                                                <td>{ feedback.feedback }</td>
                                                <td><Moment date={feedback.created_at} format="ddd, MMM Do YYYY" /></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeedbacksScreen;