import axios from "axios";
import { useState } from "react";
import Helpers from "../../../../Config/Helper";
import Input from "../../../../Components/Input";
import StrengthCheck from "../../../Auth/StrengthCheck";

const Password = () => {
    const passwordInit = {
        old_password:'',
        new_password:'',
        confirm_new_password:'',
    }
    let defaultStrengthState = Helpers.checkPasswordStrength(""); 
    const [password, setPassword] = useState(passwordInit);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [strength, setStrength] = useState(defaultStrengthState);

    const savePassword = () => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}auth/update-password`, password, Helpers.authHeaders).then(response => {
            setPassword(passwordInit);
            Helpers.toast("success", response.data.message);
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            if(error.response.data.errors){
                setErrors(error.response.data.errors);
            }else{
                setErrors({})
            }
            setIsLoading(false);
        });
    }
    
    const handleCheckPasword = e => {
        let value = e.target.value;
        setPassword({ ...password, new_password: value});
        let checkStrength = Helpers.checkPasswordStrength(value);
        setStrength(checkStrength);
    }

    return (
        <div className="card mycard">
            <div className="card-body">
                <h3>Update Password</h3>
                <div className="row mt-3">
                    <div className="col-md-12 form-group mb-3">
                        <label>Old Password</label>
                        <input type="password" className="form-control" value={password.old_password} onChange={e => setPassword({...password, old_password: e.target.value})} placeholder="Old Password" />
                        <small className="text-danger">{ errors.old_password }</small>
                    </div>
                    <div className="col-md-12 form-group mb-3">
                        <Input type="password" strength={strength} label={"New Password"} error={errors.new_password ? errors.new_password : ''} value={password.new_password} onChange={handleCheckPasword} />
                    </div>
                    <div className="col-md-12 form-group mb-3">
                        <label>Confirm New Password</label>
                        <input type="password" className="form-control" value={password.confirm_new_password} onChange={e => setPassword({...password, confirm_new_password: e.target.value})} placeholder="Confirm New Password" />
                        <small className="text-danger">{ errors.confirm_new_password }</small>
                    </div>
                    <StrengthCheck passwordStrength={strength} />
                    <div className="col-md-12 form-group mt-3">
                        <button onClick={savePassword} disabled={isLoading} className="btn btn-primary">{isLoading ? 'Saving...' : 'Save Info'}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Password;