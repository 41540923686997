import { useEffect, useState } from "react";
import Input from "../../Components/Input";
import axios, { all } from "axios";
import Helpers from "../../Config/Helper";
import FeatherIcon from "feather-icons-react";
import Moment from "react-moment";

const Tasks = () => {

    const defaultTask = {
        title: "",
        description: "",
        start_date: "",
        due_date: "",
        note: "",
        status: 0,
        priority: 0,
    }

    const [showForm, setShowForm] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [task, setTask] = useState(defaultTask);
    const [showDesction, setShowDescription] = useState(0);
    const [checked, setChecked] = useState([]);
    const [unfinishedTasks, setUnfinishedTasks] = useState(0);

    let Options = [
        { label: "Low", value: "0", color: 'green' },
        { label: "Medium ", value: "1" , color: 'blue' },
        { label: "High", value: "2", color: 'red' },
        { label: "Critical", value: "3",  color: 'yellow' },
    ];
    let status = [
        { label: "Pending", value: "0",color: 'green' },
        { label: "In progress", value: "1" , color: 'blue' },
        { label: "Completed", value: "2" , color: 'red' },
    ];

    const saveTask = () => {
        setIsLoading(true);
        setErrors({});
        axios.post(`${Helpers.apiUrl}tasks/save`, task, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getTasks();
            setTask(defaultTask);
            setShowForm(false);
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setIsLoading(false);
        });
    }

    const getTasks = () => {
        axios.get(`${Helpers.apiUrl}tasks/all`, Helpers.authHeaders).then(response => {
            setTasks(response.data.tasks);
            let data = response.data.tasks;
            let count = 0;
            data.forEach(record => {
                if (record.status < 2){
                    count += 1;
                }
            });
            setUnfinishedTasks(count);
        });
    }

    const editTask = task => {
        setTask(task);
        setShowForm(true);
    }

    const deleteTask = taskId => {
        axios.get(`${Helpers.apiUrl}tasks/delete/${taskId}`, Helpers.authHeaders).then(response => {
            getTasks();
            Helpers.toast("success", response.data.message);
            setChecked([]);
        });
    }

    const deleteMultiple = () => {
        if (checked.length > 0){
            axios.post(`${Helpers.apiUrl}tasks/delete-more`, checked, Helpers.authHeaders).then(response => {
                getTasks();
                Helpers.toast("success", response.data.message);
                setChecked([]);
            });
        }
    }

    const updateMultiple = (status) => {
        if (checked.length > 0){
            let data = {
                tasks: checked, 
                status: status,
            }
            axios.post(`${Helpers.apiUrl}tasks/update-more`, data, Helpers.authHeaders).then(response => {
                getTasks();
                Helpers.toast("success", response.data.message);
            });
        }
    }

    const handleStatusChange = (status, taskId) => {
        let data = {
            task_id: taskId, 
            status: status
        }
        axios.post(`${Helpers.apiUrl}tasks/status/update`, data, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getTasks();
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
        })
    }
    
    const handlePriorityChange = (priority, taskId) => {
        let data = {
            task_id: taskId, 
            priority: priority
        }
        axios.post(`${Helpers.apiUrl}tasks/priority/update`, data, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getTasks();
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
        })
    }

    const handleRowCheck = (taskId) => {

        if (checked.includes(taskId)) {
            setChecked(checked.filter(rowId => rowId !== taskId));
        } else {
            setChecked([...checked, taskId]);
        }
    }

    const handleSelectAll = () => {
        if (checked.length === unfinishedTasks) {
            setChecked([]);
        } else {
            const allIds = []
            tasks.forEach(record => {
                if (record.status < 2) { 
                    allIds.push(record.id);
                }
            });
            setChecked(allIds);
        }
    }

    useEffect(() => {
        getTasks();
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card m-3 mycard">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-9">
                                    <h4>
                                        {showForm ? 'Add New Task':'Tasks to Complete'}
                                        {checked.length > 0 && <button onClick={() => updateMultiple(1)} className={`btn btn-warning btn-sm ml-5`}>Mark as In Progress</button>}
                                        {checked.length > 0 && <button onClick={() => updateMultiple(2)} className="btn btn-success btn-sm ml-5">Mark as Completed</button>}
                                        {checked.length > 0 && <button onClick={deleteMultiple} className="btn btn-danger btn-sm ml-5">Delete Tasks</button>}
                                    </h4>
                                </div>
                                <div className="col-md-3 text-right">
                                    {!showForm ? <button className="btn btn-primary btn-sm" onClick={()=> setShowForm(true)} >Add Task</button> :
                                    <button className="btn btn-danger btn-sm" onClick={()=> setShowForm(false)} >Cancel</button> }
                                </div>
                            </div>
                            { showForm &&
                            <div className="row mt-3">
                                <div className="col-12 mb-2">
                                    <Input label="Title" error={errors.title} value={task.title} onChange={e => setTask({...task, title:e.target.value})} />
                                </div>
                                <div className="col-12 mb-2">
                                    <Input label="Note" value={task.note} onChange={e => setTask({...task, note:e.target.value})} />
                                </div>
                                <div className="form-group col-md-6 mb-2">
                                    <label className="form-label">Start Date</label>
                                    <input type="date" className="form-control" value={task.start_date} onChange={e => setTask({...task, start_date:e.target.value})} />
                                </div>
                                <div className="form-group col-md-6 mb-2">
                                    <label className="form-label">Due Date</label>
                                    <input type="date" className="form-control" value={task.due_date} onChange={e => setTask({...task, due_date:e.target.value})} />
                                </div>
                                <div className="col-6 mb-2">
                                    <label className="form-label">Select Priority</label>
                                    <select value={task.priority} className="form-control" onChange={e => setTask({...task, priority:e.target.value})}>
                                        {Options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
                                    </select>
                                </div>
                                <div className="col-6 mb-2">
                                    <label className="form-label">Select Status</label>
                                    <select value={task.status} className="form-control" onChange={e => setTask({...task, status:e.target.value})}>
                                        {status.map((option) => <option key={option.value} value={option.value} >{option.label}</option>)}
                                    </select>
                                </div>
                                <div className="col-12 mb-2">
                                    <div className="form-group">
                                        <label className="form-label">Description</label>
                                        <textarea className="form-control" rows={2} placeholder="Description" onChange={e => setTask({...task, description:e.target.value})}>{ task.description }</textarea>
                                        <small className="text-danger"></small>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button disabled={isLoading} onClick={saveTask} className="btn btn-primary btn-sm">{isLoading ? 'Saving...' : 'Save Task'}</button>
                                </div>
                            </div>}
                            {!showForm &&
                            <table className="table table-bordered mt-3">
                                <thead>
                                    <tr>
                                        <th>
                                            <input type="checkbox" checked={checked.length == unfinishedTasks} onChange={handleSelectAll} />
                                        </th>
                                        <th>Task</th>
                                        <th>Priority</th>
                                        <th>Started Date</th>
                                        <th>Due Date</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tasks.length === 0 && <tr>
                                        <td colSpan={7}>No tasks to display</td>
                                    </tr>}
                                    {tasks.map((task, index) => {
                                        if(task.status < 2){
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <input type="checkbox" checked={checked.includes(task.id)} onChange={() => handleRowCheck(task.id)} />
                                                    </td>
                                                    <td>
                                                        {showDesction != task.id && <FeatherIcon title="Show Description" onClick={() => setShowDescription(task.id)} className="task-icon" icon={`chevron-right`} />}
                                                        {showDesction == task.id && <FeatherIcon title="Hide Description" onClick={() => setShowDescription(0)} className="task-icon" icon={`chevron-down`} />}
                                                        &nbsp;&nbsp;{ task.title }
                                                        {showDesction == task.id && <div>
                                                            <small>{ task.description }</small>
                                                            <br />
                                                            <small><strong>Note: </strong>{ task.note }</small>
                                                        </div>}
                                                    </td>
                                                    <td className={`text-center middle ${ Helpers.priorityColor(task.priority) }`}>
                                                        <select className="task-select" value={task.priority} onChange={(e) => handlePriorityChange(e.target.value, task.id)}>
                                                            <option value={0}>Low</option>
                                                            <option value={1}>Medium</option>
                                                            <option value={2}>High</option>
                                                            <option value={3}>Critical</option>
                                                        </select>
                                                        {/* <span className="badge">{task.priority == 3 && <FeatherIcon icon={'alert-triangle'} size="12" color={'#ffce09'} />} { Helpers.priority(task.priority) }</span> */}
                                                    </td>
                                                    <td className="text-center"><Moment date={task.start_date} format="MMM DD" /></td>
                                                    <td className="text-center"><Moment date={task.due_date} format="MMM DD" /></td>
                                                    <td className={`text-center middle ${ Helpers.statusColor(task.status) }`}>
                                                        <select className="task-select" value={task.status} onChange={(e) => handleStatusChange(e.target.value, task.id)}>
                                                            <option value={0}>Pending</option>
                                                            <option value={1}>In Progress</option>
                                                            <option value={2}>Completed</option>
                                                        </select>
                                                        {/* <span className={`badge`}>{ Helpers.taskStatus(task.status) }</span> */}
                                                    </td>
                                                    <td className="text-center">
                                                        <div>
                                                            <button onClick={() => editTask(task)} className="btn btn-outline-primary btn-sm"><FeatherIcon icon={'edit'} /></button>
                                                            <button onClick={() => deleteTask(task.id)} className="btn btn-outline-danger btn-sm ml-5"><FeatherIcon icon={'trash'} /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>}

                            {!showForm && <div className="row mt-5">
                                <div className="col-md-12">
                                    <h4>Completed Tasks</h4>
                                    <table className="table table-bordered mt-3">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Task</th>
                                                <th>Priority</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tasks.length === 0 && <tr>
                                                <td colSpan={7}>No tasks to display</td>
                                            </tr>}
                                            {tasks.map((task, index) => {
                                                if (task.status == 2){
                                                    return (
                                                        <tr key={index}>
                                                            <td>{ task.id }</td>
                                                            <td>
                                                                {showDesction != task.id && <FeatherIcon title="Show Description" onClick={() => setShowDescription(task.id)} className="task-icon" icon={`chevron-right`} />}
                                                                {showDesction == task.id && <FeatherIcon title="Hide Description" onClick={() => setShowDescription(0)} className="task-icon" icon={`chevron-down`} />}
                                                                &nbsp;&nbsp;{ task.title }
                                                                {showDesction == task.id && <div>
                                                                    <small>{ task.description }</small>
                                                                    <br />
                                                                    <small><strong>Note: </strong>{ task.note }</small>
                                                                </div>}
                                                            </td>
                                                            <td className={`text-center middle ${ Helpers.priorityColor(task.priority) }`}>
                                                                <span className="badge">{ Helpers.priority(task.priority) }</span>
                                                            </td>
                                                            <td className={`text-center middle ${ Helpers.statusColor(task.status) }`}>
                                                                <span className={`badge`}>{ Helpers.taskStatus(task.status) }</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tasks;