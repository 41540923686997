import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helper";
import axios from "axios";

const QuestionBuilder = () => {

    const defaultBasic = {
        "question": "",
        "no_response": "",
        "yes_response": "",
        "tasks": [],
    }

    const defaultTask = {
        "title": "",
        "description": "",
        "note": "",
        "priority": 0,
    }

    const [grants, setGrants] = useState([]);
    const [grantsLoading, setGrantsLoading] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const [promptsLoading, setPromptsLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [questionsLoading, setQuestionsLoading] = useState(false);

    const [workbookQuestions, setWorkbookQuestions] = useState([]);
    const [allSectionQuestions, setAllSectionQuestions] = useState([]);
    
    const [grant, setGrant] = useState("");
    const [prompt, setPrompt] = useState("");
    const [typePrompt, setTypePrompt] = useState("");
    const [question, setQuestion] = useState(defaultBasic);
    const [qTask, setQtask] = useState(defaultTask);
    const [qTasks, setQTasks] = useState([]);
    const [errors, setErrors] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [addingNew, setAddingNew] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const getSections = () => {
        axios.get(`${Helpers.apiUrl}tasks/question/get/${prompt}`, Helpers.authHeaders).then(response => {
            setQuestions(response.data.sections);
        });
    }

    const getQuestions = () => {
        if(grant && prompt){
            setQuestionsLoading(true);
            let data = {
                grant_id: grant,
                prompt_id: prompt, 
            };
            axios.post(`${Helpers.apiUrl}tasks/question/all`, data, Helpers.authHeaders).then(response => {
                setQuestions(response.data.data);
                setQuestionsLoading(false);
            });
        }
    }
    

    const getGrants = () => {
        setGrantsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/grants`, Helpers.authHeaders).then(response => {
            setGrants(response.data.grants);
            setGrantsLoading(false);
        });
    }


    const getPrompts = grantId => {
        setPromptsLoading(true);
        axios.get(`${Helpers.apiUrl}categories/prompts/${grantId}`, Helpers.authHeaders).then(response => {
            setPrompts(response.data.prompts);
            setPromptsLoading(false);
        });
    }

    const selectFellowship = e => {
        let fellow = e.target.value;
        getPrompts(fellow);
        setGrant(fellow);
    }

    const addNewQuestion = () => {
        setAddingNew(true);
    }

    const cancelButton = () => {
        setAddingNew(false);
        setQuestion(defaultBasic);
    }

    const editQuestion = (queId) => {
        let question = workbookQuestions.find(que => que.id == queId);
        console.log(question);
        setQuestion(question);
        setAddingNew(true);
    }

    const saveQuestion = () => {
        let data = question;
        data["grant_id"] = grant;
        data["prompt_id"] = prompt;
        data.tasks = qTasks;
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}tasks/question/save`, data, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            setQuestion(defaultBasic);
            setAddingNew(false);
            getQuestions();
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const removeQuestion = (queId) => {
        axios.get(`${Helpers.apiUrl}tasks/question/delete/${queId}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getQuestions();
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
        });
    }

    const addNewPrompt = () => {
        if(typePrompt){
            const data = {
                "grant_id" : grant,
                "name" : typePrompt
            }
            axios.post(`${Helpers.apiUrl}categories/prompts/add`, data, Helpers.authHeaders).then(response => {
                setPrompts(response.data.prompts);
                setPrompt(response.data.prompt_id);
            }).catch(error => {
                Helpers.toast("error", "Enexpected error occured")
            })
        }
    }

    const addTask = () => {
        if(qTask.title){
            let tasks = qTasks;
            if(editingIndex !== null){
                tasks[editingIndex] = qTask;
                setEditingIndex(null);
            }else{
                tasks.push(qTask);
            }
            setQTasks(tasks);
            setQtask(defaultTask);
        }
    }

    const editTask = taskIndex => {
        let task = qTasks[taskIndex]
        setQtask(task);
        console.log("Index", taskIndex);
        setEditingIndex(taskIndex);
    }

    const deleteTask = taskIndex => {
        let tasks = [...qTasks];
        tasks.splice(taskIndex, 1);
        setQTasks(tasks);
    }

    useEffect(() => {
        getGrants();
    }, []);
    
    useEffect(() => {
        getQuestions();
    }, [prompt]);

    // useEffect(() => {
    //     if (prompt){
    //         getSections();
    //     }
    // }, [prompt]);

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12" style={{ overflow: 'auto', scrollbarWidth: 'none', height: '100vh' }}>
                        <div className="card mycard" style={{ marginTop: 15, marginBottom: 15 }}>
                            <div className="card-body">
                                <h3>
                                    Workbook Builder Playground
                                </h3>
                                <div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <h6>What Fellowship (F), Career Development Award (K), or Grant (R) are you building a prompt for?</h6>
                                            <select value={grant} className="form-control" disabled={grantsLoading} onChange={selectFellowship}>
                                                {grantsLoading ? <option value={''}>Loading...</option> : <option value={''}>Choose Fellowship / Grant / Award</option>}
                                                {grants.map((grant, index) => <option key={index} value={grant.id}>{grant.name}</option>)}
                                            </select>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <h6>What section of the application are you preparing a prompt for?</h6>
                                            <select className="form-control" value={prompt} disabled={promptsLoading} onChange={e => setPrompt(e.target.value)}>
                                                {promptsLoading ? <option value={''}>Loading sections </option> : <option value={''}>Choose Section </option>}
                                                {prompts.map((prompt, index) => <option key={index} value={prompt.id}>{prompt.name}</option>)}
                                                {(!promptsLoading && prompts.length > 0) && <option value={'type'}>Let me type</option>}
                                            </select>
                                        </div>
                                        {prompt === 'type' && <div className="col-md-12 mt-3">
                                            <h6>Type the section of application are you preparing a prompt for?</h6>
                                            <input className="form-control" onBlur={addNewPrompt} placeholder="Type section here.." value={typePrompt} onChange={e => setTypePrompt(e.target.value)} />
                                        </div>}
                                    </div>
                                    {(grant && prompt) && <div className="row mt-3">
                                        <div className="col-md-6">
                                            {addingNew ? <h6>Add New Question</h6> :
                                            <button onClick={addNewQuestion} className="btn btn-primary btn-sm">Add New Question</button>}
                                        </div>
                                        {addingNew && <div className="col-md-6 text-right">
                                            <button onClick={saveQuestion} disabled={isLoading} className="btn btn-primary btn-sm">{ isLoading ? "Saving..." : "Save Question" }</button>
                                            <button onClick={cancelButton} className="btn btn-danger btn-sm ml-5">Cancel</button>
                                        </div>}
                                    </div>}
                                    {(grant && prompt && !addingNew) && <div className="row">
                                        {questions.map(ques => {
                                            return (
                                                <div className="col-md-12 mt-3" key={ques.id}>
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <h5>{ ques.question }</h5>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <button onClick={() => removeQuestion(ques.id)} className="btn btn-danger btn-sm">Delete Question</button>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        <small><strong>Yes Response: </strong>{ ques.yes_response }</small>
                                                    </p>
                                                    <p>
                                                        <small><strong>No Response: </strong>{ ques.no_response }</small>
                                                    </p>
                                                    <h6>Tasks for users</h6>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Task</th>
                                                                <th>Priority</th>
                                                                <th>Note</th>
                                                            </tr>
                                                        </thead>
                                                    {ques.tasks.length > 0 && ques.tasks.map(task => {
                                                        return (
                                                            <tr key={task.id}>
                                                                <td>
                                                                    <div>
                                                                        { task.title }
                                                                        <br />
                                                                        <small>{ task.description }</small>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className={`badge bg-${ Helpers.priorityColor(task.priority) }`}>{ Helpers.priority(task.priority) }</span>
                                                                </td>
                                                                <td>{ task.note }</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </table>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    {(grant && prompt && addingNew) && <div className="row mt-3">
                                        <div className="col-md-12">
                                            <small>Question</small>
                                            <input className="form-control" value={question.question} onChange={e => setQuestion({...question, question: e.target.value})} placeholder="Write Question" />
                                            <small className="text-danger">{ errors.question ? errors.question : '' }</small>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <small>"Yes" Response</small>
                                            <input className="form-control" value={question.yes_response} onChange={e => setQuestion({...question, yes_response:e.target.value})} placeholder="Message for user if response is 'Yes'" />
                                            <small className="text-danger">{ errors.yes_response ? errors.yes_response : '' }</small>
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <small>"No" Response</small>
                                            <input className="form-control" value={question.no_response} onChange={e => setQuestion({...question, no_response:e.target.value})} placeholder="Message for user if response is 'No'" />
                                            <small className="text-danger">{ errors.no_response ? errors.no_response : '' }</small>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12 mt-3">
                                                    <h4>Add Tasks for User</h4>
                                                    <div className="row">
                                                        <div className="col-md-12 form-group mt-2">
                                                            <small>Task Title</small>
                                                            <input className="form-control" value={qTask.title} onChange={e => setQtask({...qTask, title: e.target.value})} placeholder="Task Title" />
                                                        </div>
                                                        <div className="col-md-6 form-group mt-2">
                                                            <small>Note</small>
                                                            <input className="form-control" value={qTask.note} onChange={e => setQtask({...qTask, note: e.target.value})} placeholder="Note" />
                                                        </div>
                                                        <div className="col-md-6 form-group mt-2">
                                                            <small>Priority</small>
                                                            <select className="form-control" value={qTask.priority} onChange={e => setQtask({...qTask, priority: e.target.value})}>
                                                                <option value={0}>Low</option>
                                                                <option value={1}>Medium</option>
                                                                <option value={2}>High</option>
                                                                <option value={3}>Critical</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-12 form-group mt-2">
                                                            <small>Short Description</small>
                                                            <textarea className="form-control" value={qTask.description} onChange={e => setQtask({...qTask, description: e.target.value})} placeholder="Description (optional)"></textarea>
                                                        </div>
                                                        <div className="col-md-12 form-group mt-2">
                                                            <button onClick={addTask} className="btn btn-primary btn-sm">Save Task</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <h4>Tasks Related to Question</h4>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Task</th>
                                                                <th>Priority</th>
                                                                <th>Note</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {qTasks.length === 0 && <tr><td colSpan={3}>No tasks added</td></tr>}
                                                            {qTasks.map((task, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div>
                                                                                { task.title }
                                                                                <br />
                                                                                <small>{ task.description }</small>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span className={`badge bg-${ Helpers.priorityColor(task.priority) }`}>{ Helpers.priority(task.priority) }</span>
                                                                        </td>
                                                                        <td>{ task.note }</td>
                                                                        <td>
                                                                            <div>
                                                                                <button onClick={() => editTask(index)} className="btn btn-primary btn-sm">Edit</button>
                                                                                <button onClick={() => deleteTask(index)} className="btn btn-danger btn-sm ml-5">Delete</button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QuestionBuilder;