import { useEffect, useState } from "react";
import StatCard from "../../../Components/StatCard";
import axios from "axios";
import Helpers from "../../../Config/Helper";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import Chart from 'react-apexcharts'

const AdminDashboard = () => {

    const chartOptions = {
        chart:{
            
        },
        grid: {
            show: true,
            borderColor: '#c5c5c5',
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },        
        colors: ["#2463ea", "#0fb981"],
        xaxis: {
            categories: [],
            labels: {
                style: {
                    fontSize: '8px'
                }
            },
            title: {
                text: 'Weekly Stats',
            }
        },
        yaxis:{
            min: 0, 
            max: 10,
            tickAmount:10,
            title:{
                text: 'No. of Users'
            }
        }
    }
    
    const [data, setData] = useState({});
    const [options, setOptions] = useState(chartOptions);
    const [series, setSeries] = useState([]);

    const getStats = () => {
        axios.get(`${Helpers.apiUrl}admin/stats/all`, Helpers.authHeaders).then(response => {
            setData(response.data);
            let xaxis_labels = Object.keys(response.data.chat_users_week_dict);
            let daily_users = Object.values(response.data.chat_users_week_dict);
            let daily_subs = Object.values(response.data.plan_users_week_dict);
            let max_value = parseInt(Math.max(...daily_users, ...daily_subs) + 2);
            setOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: xaxis_labels
                },
                yaxis:{
                    ...prevOptions.yaxis,
                    max: max_value,
                    tickAmount: max_value
                }
            }));
            setSeries([
                {
                    name: 'Weekly Users',
                    data: daily_users
                },
                {
                    name: 'Weekly Subscriptions',
                    data: daily_subs
                }
            ]);
        }).catch(error => {
            if(error.response.data.code === "token_not_valid"){
                localStorage.clear();
                window.location.reload();
            }
        });
    }

    useEffect(() => {
        getStats()
    }, []);

    return (
        <div className="container-fluid p-4" style={{ overflowY: 'auto' }}>
            <div className="row align-center">
                <div className="col-6">
                    <div>
                        <small>Welcome Back,</small>
                    </div>
                    <h2>{ `${Helpers.authUser.fields.first_name.toUpperCase()} ${Helpers.authUser.fields.last_name.toUpperCase()}` }</h2>
                </div>
                <div className="col-6 text-right">
                    <Link to={'/admin/feedbacks'} className="btn btn-primary btn-sm"><FeatherIcon icon={'star'} /> Feebacks</Link>
                    <Link to={'/admin/issues'} className="btn btn-primary btn-sm ml-5"><FeatherIcon icon={"alert-circle"} /> Issues</Link>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">
                    <Chart options={options} series={series} type="area" width={'100%'} height={350} />
                </div>
            </div>
            <div className="row mt-3">
                <StatCard title={"Users Registered This Week"} value={data.users_this_week} icon={"users"} columns={4} />
                <StatCard title={"Subscriptions This Week"} value={data.users_with_plan_this_week} icon={"dollar-sign"} columns={4} />
                <StatCard title={"User With Chats This Week"} value={data.chat_users_week} icon={"users"} columns={4} />
                <StatCard title={"Chats This Week"} value={data.chats_this_week} icon={"message-square"} columns={4} />
                <StatCard title={"Total Users"} value={data.total_users} icon={"users"} columns={4} />
                <StatCard title={"Total Chats"} value={data.total_chats} icon={"message-square"} columns={4} />
                <StatCard title={"Users Messages"} value={data.total_user_messages} icon={"send"} columns={4} />
                <StatCard title={"Bot Replies"} value={data.total_bot_replies} icon={"message-circle"} columns={4} />
                <StatCard title={"Total Coupons"} value={data.total_coupons} icon={"percentage"} columns={4} />
                {/* <StatCard title={"Active Coupons"} value={data.active_coupons} icon={"activity"} columns={4} />
                <StatCard title={"Expired Coupons"} value={data.expired_coupons} icon={"alert-circle"} columns={4} /> */}
            </div>
        </div>
    )
}

export default AdminDashboard;